import React, {useContext, useEffect, useRef, useState} from "react";

//Leaflet Maps
import {Marker, useMapEvents} from "react-leaflet";
import L, {LatLng, Map} from "leaflet";


//Map Components - These components are pulled from the livemap page
import MapContextMenu from "../map/components/MapContextMenu";
import MapTileLayer, {DEFAULT_MAP_LAYER} from "../../components/map/MapTileLayer";

import {Asset, AssetEvent, AssetType} from "../../hooks/assets/dto/Asset";
import {SvgMarkerIcon} from "../../components/svgmarkericon/SvgMarkerIcon";
import ReactDOMServer from "react-dom/server";
import EventMarkerPopup from "./components/leftpanel/EventMarkerPopup";
import {useAssetApi} from "../../hooks/assets/Assets";
import MapView from "../../layouts/MapView";
import {useNavigate, useParams} from "react-router-dom";
import {isWarningEvent} from "../../utils/AssetEventUtils";
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapButtonControl from "../map/components/MapButtonControl";
import MapLayerControl from "../map/components/MapLayerControl";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {useCongestionZonesApi} from "../../hooks/zones/CongestionZones";
import {useGeofencesApi} from "../../hooks/geofences/Geofences";
import CongestionZoneLayer from "../../components/map/CongestionZoneLayer";
import GeofenceLayer from "../../components/map/GeofenceLayer";
import {Journey} from "../../hooks/assets/dto/Journey";
import dayjs, {Dayjs} from "dayjs";
import RouteReplayJourneySelect from "./components/leftpanel/RouteReplayJourneySelect";
import RouteReplayJourneyDetail from "./components/leftpanel/RouteReplayJourneyDetail";
import {TimelineBlock} from "../../hooks/assets/dto/TimelineBlock";
import {InteractiveTimeline} from "../../components/timeline/InteractiveTimeline";

interface RouteReplayPageProps {
    events: Array<AssetEvent>,
    asset: Asset | undefined
}

function EventMarkers(props: Readonly<RouteReplayPageProps>) {
    return (
        <React.Fragment>
            {props.events.map((event) => {
                if(!props.asset){
                    return <></>
                }

                const svg = SvgMarkerIcon('black', AssetType.CAR, event.status, event.heading,
                    isWarningEvent(event.speed, event.speedLimit, event.type))

                const customMarkerIcon = L.divIcon({
                    html: ReactDOMServer.renderToString(svg),
                    iconSize: [40, 40],
                    iconAnchor: [20, 20],
                    popupAnchor: [0, -20],
                    className: "none"
                });
                return (<Marker key={event.time.toISOString()} icon={customMarkerIcon}
                                position={new LatLng(event.location.snappedCoordinates.latitude, event.location.snappedCoordinates.longitude)}>
                    <EventMarkerPopup
                        event={event}
                        asset={props.asset}
                    />
                </Marker>)
            })}
        </React.Fragment>
    );
}


function RouteReplayAssetPage() {
    const mapRef = useRef<Map>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    const {getAsset, getAssetJourneysOnDay, getAssetTimelineBlocksForDate} = useAssetApi({shouldLoadAll: false, shouldPoll: false});
    const [asset, setAsset] = useState<Asset | undefined>(undefined);
    const [journeys, setJourneys] = useState<Journey[] | undefined>(undefined);
    const [selectedJourney, setSelectedJourney] = useState<Journey | undefined>(undefined);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs().startOf('date'));

    useEffect(() => {
        if (id) {
            getAsset(id).then((data) => {
                setAsset(data);
            });
        }
    }, [id]);

    async function updateJourneys(journeyDate: Date | null) {
        if (asset && journeyDate != null) {

            let fetchedJourneys = await getAssetJourneysOnDay(asset.id, journeyDate);
            if (!fetchedJourneys) {
                fetchedJourneys = [];
            }
            setJourneys(fetchedJourneys as Array<Journey>);
            let blocks = await getAssetTimelineBlocksForDate(asset.id, selectedDate.toDate());
            blocks = blocks || [];
            setTimelineBlocks(blocks);
        }
    }

    React.useEffect(() => {
        updateJourneys(selectedDate.toDate());
    }, [asset, selectedDate]);

    const [selectedLayer] = useState(DEFAULT_MAP_LAYER);
    const [markers, setMarkers] = useState<AssetEvent[]>([]);
    const {zones} = useCongestionZonesApi(true);
    const {geofences} = useGeofencesApi(true);
    const {mapType, setMapType, labelsEnabled,} = useContext(UserPreferencesContext);
    const [congestionZonesEnabled, setCongestionZonesEnabled] = useState(false);
    const [geofenceEnabled, setGeofenceEnabled] = useState(false);
    const [timelineBlocks, setTimelineBlocks] = React.useState<TimelineBlock[] | undefined>(undefined);

    const [mapLayerControllerOpen, setMapLayerControllerOpen] = useState(false);

    const mapLayers = <>
        <MapTileLayer mapLayer={selectedLayer}/>

        <MapContextMenu/>

        <EventMarkers events={markers} asset={asset} />
    </>

    const handleClickOperation = () => {
        setMapLayerControllerOpen(!mapLayerControllerOpen);
    };

    const selectJourneyAtDateTime = (date: Date) => {
        if (asset) {
            const journey = journeys?.find(j => j.startTime <= date && (!j.endTime || j.endTime >= date ));
            if (journey) {
                setSelectedJourney(journey);
            }
        }
    }

    let leftPanel;
    let timeline = <></>

    if(asset && selectedJourney){
        leftPanel = <RouteReplayJourneyDetail
            mapref={mapRef}
            setMarkers={setMarkers}
            asset={asset}
            journey={selectedJourney}
            onBackPressed={() => { setSelectedJourney(undefined) }}
        />
        const blocksDuringJourney = timelineBlocks?.filter(block => block.startTime >= selectedJourney.startTime && (!selectedJourney.endTime || block.endTime <= selectedJourney.endTime));
        timeline = <InteractiveTimeline blocks={blocksDuringJourney || []} />

    } else if (asset) {
        leftPanel = <RouteReplayJourneySelect
            asset={asset}
            onBackPressed={() => { navigate(-1) }}
            selectedDate={selectedDate}
            journeys={journeys}
            setSelectedDate={setSelectedDate}
            setJourney={setSelectedJourney} />

        timeline = <InteractiveTimeline selectJourneyAtDateTime={selectJourneyAtDateTime} blocks={timelineBlocks || []} />
    } else {
        leftPanel = <></>
    }

    const controls = (
        <>
            {/* Map Tile Layer - Selected by the MapLayerControl */}
            <MapTileLayer mapLayer={mapType}/>

            <MapEventHandler setMapLayerControllerOpen={setMapLayerControllerOpen}/>

            <MapButtonControl placement="topleft" tooltip="Toggle Congestion Zones" toggled={congestionZonesEnabled}
                              icon={<HexagonOutlinedIcon/>} onClick={() => setCongestionZonesEnabled(!congestionZonesEnabled)}/>
            <MapButtonControl placement="topleft" tooltip="Toggle Geofences" toggled={geofenceEnabled}
                              icon={<LocationOnOutlinedIcon/>} onClick={() => setGeofenceEnabled(!geofenceEnabled)}/>
            <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType} open={mapLayerControllerOpen}
                             handleClickOperation={handleClickOperation}/>

            {/* Congestion Zones */}
            {zones && zones.length > 0 ?
                (<CongestionZoneLayer zones={zones || []} congestionZonesEnabled={congestionZonesEnabled}/>) :
                (<></>)}
            {/* Geofences */}
            {geofences && geofences.length > 0 ?
                (<GeofenceLayer geofences={geofences} labelsEnabled={labelsEnabled} geofenceEnabled={geofenceEnabled}/>) :
                (<></>)}
        </>
    );

    return (<MapView leftPanel={leftPanel} mapLayers={mapLayers} mapref={mapRef} controls={controls} timeline={timeline}/>
    );
}

const MapEventHandler = ({setMapLayerControllerOpen}: {setMapLayerControllerOpen: (open: boolean) => void}) => {
    useMapEvents({
        click: (event) => {
            setMapLayerControllerOpen(false);
        }
    })

    return <></>
}

export default RouteReplayAssetPage;
