import {TablePagination} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import {ChangeEvent, MouseEvent, ReactNode, useEffect, useState} from 'react';
import {CreateButton} from './TableButtons';
import LoadingSpinner from "../../loadingspinner/LoadingSpinner";
import {t} from "i18next";

export type TableColumn = {
    id: string
    content: string | JSX.Element;
    collapsible?: boolean;
}

interface RamTableProps {
    id: string;
    label: string;
    columns: TableColumn[];
    rows: JSX.Element[];
    loading: boolean;
    createAction?: () => void;
    emptyLabel?: string;
    disableCreateButton?: boolean;
    disablePagination?: boolean;
}

const columnPaddingClasses = "px-2 md:px-4";

export function Table(props: Readonly<RamTableProps>) {
    useEffect(() => {
        setPage(0);
    }, [props.rows]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div id={`${props.id}-table-container`} className="mt-2 h-full relative overflow-x-auto shadow-md sm:rounded-lg">
            <table aria-label={props.label} className="w-full h-full text-sm text-left text-baseContent">
                <thead className="text-xs text-baseContent uppercase bg-base-300">
                <tr>
                    {props.columns.map((column) => (<th key={`${props.id}-table-${column.id}`} scope="col"
                                                        className={`${columnPaddingClasses} py-3 ${column.collapsible ? 'max-xl:hidden' : ''}`}>{column.content}</th>))}
                </tr>
                </thead>
                <tbody>
                {props.loading &&
                    <tr className="h-full">
                        <td colSpan={props.columns.length} className="px-6 py-4">
                            <LoadingSpinner/>
                        </td>
                    </tr>
                }
                {!props.loading && props.rows.length === 0 &&
                    <tr className="h-full">
                        <td colSpan={props.columns.length} className="w-full text-center px-6 py-4">
                            {props.emptyLabel ?? t("alert_table.no_data")}
                        </td>
                    </tr>
                }
                {!props.loading && props.rows.slice(startIndex, endIndex).map((row) => (row))}
                <tr className="h-full"></tr>
                </tbody>
                <tfoot>
                <tr>
                    {!props.disableCreateButton && <td className="px-6 py-4">
                        <CreateButton action={props.createAction as () => void}/>
                    </td>}
                    {!props.disablePagination && (
                    <TablePagination
                        count={props.rows.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        labelRowsPerPage={t("alert_table.rows_per_page")}
                        labelDisplayedRows={({ from, to, count }) =>
                            t("alert_table.displayed_rows", { from, to, count })
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    )}
                </tr>
                </tfoot>
            </table>
        </div>
    );
}

export function TableRow(props: Readonly<{ id?: string, children: ReactNode, className?: string }>) {
    const baseClasses = "odd:bg-base-100 even:bg-base-200 hover:bg-base-300";
    return (
        <tr id={props.id} className={`${props.className} ${baseClasses}`}>
            {props.children}
        </tr>
    );
}

interface TableCellProps {
    id?: string;
    collapsible?: boolean;
    maxWidth?: string;
    children: ReactNode;
    className?: string;
}

export function TableCell(props: Readonly<TableCellProps>) {
    const baseClasses = `${columnPaddingClasses} py-2 text-sm`;
    const contentClasses = 'block truncate';
    const maxWidthClass = props.maxWidth ?? 'max-w-xs';

    const processContent = () => {
        if (typeof props.children === 'string') {
            return (
                <div className={`${contentClasses} ${maxWidthClass}`} title={props.children}>
                    {props.children}
                </div>
            );
        }
        return props.children;
    };

    return (
        <td id={props.id} className={`${props.className} ${baseClasses}`}>
            {processContent()}
        </td>
    );
}
