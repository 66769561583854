import ReactLeafletRightClick, { useLeafletRightClick } from "react-leaflet-rightclick";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {NearestAssetContext} from "../../../providers/NearestAssetProvider";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useNavigate} from "react-router-dom";

interface MapContextMenuProps {
    disableCreateGeofence?: boolean
}

function MapContextMenu(props: MapContextMenuProps) {
    const eventContextMenu = useLeafletRightClick();
    const {setNearestAssets} = useContext(NearestAssetContext);
    const {getNearestAssets} = useAssetApi();
    const {t} = useTranslation();
    const navigate = useNavigate()


    const handleFindNearestClick = async () => {
        if (eventContextMenu?.latlng) {
            const res = await getNearestAssets(eventContextMenu.latlng.lat, eventContextMenu.latlng.lng);
            if (res) {
                setNearestAssets(res);
            }
        }
    }

    return (
        <ReactLeafletRightClick
            customComponent={
                <div
                    className={"bg-base-100 rounded-lg w-[120px]"}
                >
                    <button
                        className={"w-full p-2 text-left text-link hover:underline"}
                        onClick={() => {
                            window.open('http://maps.google.com/maps?q=&layer=c&cbll=' + eventContextMenu?.latlng.lat + ',' + eventContextMenu?.latlng.lng + '', 'name', 'width=800,height=600')
                            console.log(eventContextMenu)
                        }}>
                        {t('asset_card.btn_street_view')}
                    </button>
                    <button
                        className={"w-full p-2 text-left text-link hover:underline"}
                        onClick={handleFindNearestClick}
                    >
                        {t('asset_card.btn_nearest')}
                    </button>
                    <button
                        className={"w-full p-2 text-left text-link hover:underline"}
                        onClick={() => {
                            window.open('https://www.google.com/maps/dir/?api=1&destination=' + eventContextMenu?.latlng.lat + ',' + eventContextMenu?.latlng.lng + '', 'name', 'width=800,height=600')
                            console.log(eventContextMenu)
                        }}>
                        {t('asset_card.btn_directions')}
                    </button>
                    {!props.disableCreateGeofence && (
                        <button
                            className={"w-full p-2 text-left text-link hover:underline"}
                            onClick={() => {
                                navigate('/geofences', {state: {latlng: eventContextMenu?.latlng}});
                            }}>
                            {t('asset_card.btn_geofence')}
                        </button>
                    )}
                </div>
            }
        />
    );
}

export default MapContextMenu;
