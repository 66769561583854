import React, {RefObject, useCallback, useContext, useEffect, useState} from "react";
import "./GeofencesMapContainer.css";
import drawLocales, {Language} from 'leaflet-draw-locales'

//Map Components
import {AttributionControl, Circle, FeatureGroup, MapContainer, Polygon, Tooltip, useMapEvents} from "react-leaflet";
import L, {LatLngExpression, Map} from "leaflet";
import MapContextMenu from "../../../map/components/MapContextMenu";
import MapTileLayer from "../../../../components/map/MapTileLayer";
import ZoomMapControl from "../../../map/components/ZoomMapControl";
import MapLayerControl from "../../../map/components/MapLayerControl";

import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";
import GeofenceLayer from "../../../../components/map/GeofenceLayer";
import {convertPositionArrayToLatLngArray, resizeMap} from "../../../../utils/MapUtils";
import {GeofenceWizardContext} from "../../../../providers/GeofenceWizardProvider";
import {EditControl} from "react-leaflet-draw";
import {Feature} from 'geojson';
import {Geofence} from "../../../../hooks/geofences/dto/Geofence";

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 48px)",
    margin: "0 auto",
};

interface GeofencesMapContainerProps {
    mapref: React.RefObject<Map>;
    geofences: Geofence[];
    keepGeofencesInBounds: boolean;
}

const GeofencesMapContainer = (props: GeofencesMapContainerProps) => {
    const {loading, newGeofence, id, setEditingFeature, name, colour, feature, setFeature} = useContext(GeofenceWizardContext);
    const {mapType, setMapType, languageCode} = useContext(UserPreferencesContext);
    const featureGroupRef = React.useRef<L.FeatureGroup>(null);
    const createdFeatureGroupRef = React.useRef<L.FeatureGroup>(null);
    const [mapLayerControllerOpen, setMapLayerControllerOpen] = useState(false);
    const [keepGeofencesInBounds, setKeepGeofencesInBounds] = useState<boolean>(props.keepGeofencesInBounds);

    React.useEffect(() => {
        if (!feature) {
            featureGroupRef.current?.clearLayers();
            createdFeatureGroupRef.current?.clearLayers();
        }

        if (featureGroupRef.current?.getLayers().length && featureGroupRef.current?.getLayers().length > 0) {
            featureGroupRef.current?.clearLayers();
        }

        if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0) {
            createdFeatureGroupRef.current?.clearLayers();
        }

        if (featureGroupRef.current?.getLayers().length === 0 && feature) {
            drawFeatureToFeatureGroup(feature, featureGroupRef)
        } else if (featureGroupRef.current?.getLayers().length && featureGroupRef.current?.getLayers().length > 0 && !feature) {
            featureGroupRef.current?.clearLayers();
        }

        if (createdFeatureGroupRef.current?.getLayers().length === 0 && feature) {
            drawFeatureToFeatureGroup(feature, createdFeatureGroupRef)
        } else if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0 && !feature) {
            createdFeatureGroupRef.current?.clearLayers();
        }

    }, [feature, id, colour, name]);

    useEffect(() => {
        if(keepGeofencesInBounds) {
            zoomToAllGeofences();
        }
    }, [props.geofences]);

    const zoomToAllGeofences = useCallback(() => {
        if (props.geofences === undefined || (props.geofences && props.geofences.length === 0)) {
            return;
        }

        const geofenceBounds = props.geofences.map((geofence) => {
            if (geofence.feature.geometry.type === "Point") {
                return [geofence.feature.geometry.coordinates[1], geofence.feature.geometry.coordinates[0]] as LatLngExpression;
            } else if (geofence.feature.geometry.type === "Polygon") {
                return convertPositionArrayToLatLngArray(geofence.feature.geometry.coordinates[0]);
            }
        });
        const rawBounds = new L.LatLngBounds(geofenceBounds as L.LatLngBoundsLiteral);
        const paddedBounds = addPaddingToBounds(rawBounds);
        props.mapref.current?.fitBounds(paddedBounds);
    }, [props.geofences, props.mapref]);

    function addPaddingToBounds(bounds: L.LatLngBounds) {
        const padding = 0.130;
        return new L.LatLngBounds(
            [bounds.getSouth() - padding, bounds.getWest() - padding],
            [bounds.getNorth() + padding, bounds.getEast() + padding]
        );
    }

    function drawFeatureToFeatureGroup(feature: Feature, featureGroupRef: RefObject<L.FeatureGroup>) {
        L.geoJSON(feature).eachLayer((layer) => {
            if (
                layer instanceof L.Polyline ||
                layer instanceof L.Polygon ||
                layer instanceof L.Marker
            ) {
                let tooltip = new L.Tooltip({direction: "center"})
                tooltip.setContent(name)

                if (layer?.feature?.properties.radius && featureGroupRef.current) {
                    let circle = new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
                        radius: layer.feature?.properties.radius,
                    })
                    circle.setStyle({color: colour})
                    circle.bindTooltip(tooltip)
                    circle.addTo(featureGroupRef.current);
                } else {
                    layer.bindTooltip(tooltip)
                    if (layer instanceof L.Polygon) {
                        layer.setStyle({color: colour})
                    }
                    featureGroupRef.current?.addLayer(layer);
                }
            }
        });
    }

    function handleEditSaved(e: any) {
        let layers = e.layers.getLayers()
        if (layers.length === 1) {
            let layer = layers[0]
            if (layer instanceof L.Circle) {
                const circleFeature = layer.toGeoJSON();
                circleFeature.properties.radius = layer.getRadius();
                setFeature(circleFeature)
            } else {
                const polygonFeature = layer.toGeoJSON();
                setFeature(polygonFeature)
            }
        }
    };

    function handlePolygonCreated(e: any) {
        const polygonFeature = e.layer.toGeoJSON();
        setFeature(polygonFeature)
    };

    function handleStartDrawing() {
        if (createdFeatureGroupRef.current?.getLayers().length && createdFeatureGroupRef.current?.getLayers().length > 0) {
            createdFeatureGroupRef.current?.clearLayers();
        }
        setEditingFeature(true)
    }

    function handleStopDrawing(e: any) {
        setEditingFeature(false)
    }

    const handleClickOperation = () => {
        setMapLayerControllerOpen(!mapLayerControllerOpen);
    };

    function MapEventHandler() {
        useMapEvents({
            click: (event) => {
                setMapLayerControllerOpen(false);
            }
        })

        return <></>
    }

    useEffect(() => {
        drawLocales(languageCode as unknown as Language);
    }, [languageCode]);

    return (<div
        style={containerStyle}
    >
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"/>
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"/>

        <MapContainer
            ref={props.mapref}
            attributionControl={false}
            style={containerStyle}
            center={[53.801277, -1.548567]}
            zoom={7}
            scrollWheelZoom={true}
            zoomControl={false}
            id="leaflet-container"
            whenReady={() => {
                resizeMap(props.mapref!)
            }}
        >
            <AttributionControl position="bottomright" prefix=""/>

            {/* Map Tile Layer - Selected by the MapLayerControl */}
            <MapTileLayer mapLayer={mapType}/>

            <MapEventHandler/>

            {/* Context Menu - Currently only used for street view */}
            <MapContextMenu disableCreateGeofence={true} />

            {/* Map Controls */}
            <ZoomMapControl onZoomInClick={() => {
                props.mapref?.current?.zoomIn()
            }} onZoomOutClick={() => {
                props.mapref?.current?.zoomOut()
            }}/>
            <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType} open={mapLayerControllerOpen}
                             handleClickOperation={handleClickOperation}/>

            {/* Edit Tools */}

            {!loading && id !== undefined && (
                <FeatureGroup ref={featureGroupRef}>
                    <EditControl
                        position='topleft'
                        onEditStart={handleStartDrawing}
                        onEdited={handleEditSaved}
                        onEditStop={handleStopDrawing}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            polygon: false,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                        }}
                        edit={{
                            edit: true,
                            remove: false,
                        }}
                    />
                </FeatureGroup>
            )}


            {!loading && newGeofence && (
                <FeatureGroup
                    ref={createdFeatureGroupRef}
                >
                    <EditControl
                        position='topleft'
                        onDrawStart={handleStartDrawing}
                        onDrawStop={handleStopDrawing}
                        onCreated={handlePolygonCreated}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            polygon: true,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                        }}
                        edit={{
                            edit: false,
                            remove: false,
                        }}
                    />
                </FeatureGroup>
            )}

            {!loading && (props.geofences.length > 0) && (id === undefined) && (!newGeofence) ?
                (<GeofenceLayer geofences={props.geofences} labelsEnabled={true} geofenceEnabled={true}/>) :
                (<></>)}

        </MapContainer>
    </div>)
}

export default GeofencesMapContainer;
