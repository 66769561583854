import {
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    Typography
} from "@mui/material";
import LoadingSpinner from "../../../loadingspinner/LoadingSpinner";
import ListItemIcon from "@mui/material/ListItemIcon";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import React from "react";
import {SearchResults} from "../../../../hooks/search/Search";
import {AssetSearchResult, GeofenceSearchResult, LocationSearchResult} from "./SearchResult";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";

interface SearchResultsPanelProps {
    open: boolean;
    anchorEl: HTMLElement | undefined;
    handleClose: () => void;
    searchEmpty: boolean;
    updateSearchInput: (searchInput: string) => void;
    loading: boolean;
    results: SearchResults | undefined;
}

export default function SearchResultsPanel(props: Readonly<SearchResultsPanelProps>) {

    const {t} = useTranslation();

    const noSearchContent = (
        <List>
            <ListItem>
                <ListItemIcon style={{minWidth: '40px'}}>
                    <KeyboardIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={<Typography variant="smallTitle">{t('search_bar.pre_search_message')}</Typography>}
                />
            </ListItem>
        </List>);

    const loadingContent = (
        <LoadingSpinner/>
    );

  return (
      <Popper id="search-results-popper" open={props.open} anchorEl={props.anchorEl} disablePortal={true}>
          <ClickAwayListener onClickAway={props.handleClose}>
              <Paper
                  sx={{
                      paddingX: '5px',
                      alignItems: 'center',
                      width: 400,
                      marginTop: '5px',
                      maxHeight: '400px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      overflow: 'auto',
                  }}>
                  {props.searchEmpty && noSearchContent}
                  {!props.searchEmpty && props.loading && loadingContent}

                  {!props.searchEmpty && !props.loading &&
                      <>
                          <>
                          <ResultsSectionHeader title={t('search_bar.assets.title')}/>
                          <List dense={true}>
                              {(!props.results || props.results.assets.length === 0) && <NoResultsMessage message={t('search_bar.assets.no_results')}/>}
                              {props.results?.assets.map((result, index) => (
                                  <AssetSearchResult
                                      key={"asset-result-" + index}
                                      onClick={() => {

                                        ReactGA.event("select_item",  {item_name: result.alias || result.identificationMark});
                                          props.updateSearchInput(result.alias || result.identificationMark)
                                          props.handleClose()
                                      }}
                                      asset={result}
                                  />
                              ))}
                          </List>
                          </>

                          <>
                              <ResultsSectionHeader title={t('search_bar.postcodes.title')}/>
                              <List dense={true}>
                                  {(!props.results || props.results.postcodes.length === 0) && <NoResultsMessage message={t('search_bar.postcodes.no_results')} />}
                                  {props.results?.postcodes.map((result, index) => (
                                      <LocationSearchResult
                                          key={"location-result-" + index}
                                          onClick={() => {
                                              props.updateSearchInput(result.displayName)
                                              props.handleClose()
                                          }}
                                          location={result}
                                      />
                                  ))}
                              </List>
                          </>

                          <>
                              <ResultsSectionHeader title={t('search_bar.geofences.title')}/>
                              <List dense={true}>
                                  {(!props.results || props.results.geofences.length === 0) && <NoResultsMessage message={t('search_bar.geofences.no_results')} />}
                                  {props.results?.geofences.map((result, index) => (
                                      <GeofenceSearchResult
                                          key={"location-result-" + index}
                                          onClick={() => {
                                              props.updateSearchInput(result.name)
                                              props.handleClose()
                                          }}
                                          geofence={result}
                                      />
                                  ))}
                              </List>
                          </>
                      </>}
              </Paper>
          </ClickAwayListener>
      </Popper>
  );
}

function NoResultsMessage(props: Readonly<{ message: string }>) {
    return (
        <ListItem>
            <ListItemText disableTypography primary={<Typography variant="smallTitle">{props.message}</Typography>}/>
        </ListItem>
    )
}


function ResultsSectionHeader(props: Readonly<{ title: string }>) {
    return (
        <Typography variant="subtitle" sx={{paddingX: '5px', paddingBottom: '0px'}}>
            {props.title}
        </Typography>
    )
}