import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {resizeMap} from "../../../utils/MapUtils";
import {AttributionControl, MapContainer} from "react-leaflet";
import L, {Map} from "leaflet";
import MapTileLayer from '../../../components/map/MapTileLayer';
import {UserPreferencesContext} from '../../../providers/UserPreferencesProvider';
import {Asset} from '../../../hooks/assets/dto/Asset';
import AssetMarkerLayer from '../../map/components/AssetMarkerLayer';
import {useNavigate} from 'react-router-dom';
import MapButtonControl from "../../map/components/MapButtonControl";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {LabelLayerType} from "../../../components/map/BaseMap";
import {useDebounce} from "use-debounce";

interface MapWidgetProps {
    assets: Asset[];
    labelLayer: LabelLayerType;
}

const MapWidget: React.FC<MapWidgetProps> = (props: MapWidgetProps) => {
    const mapRef = useRef<Map>(null);
    const navigate = useNavigate();
    const [debouncedAssets] = useDebounce(props.assets, 1000);

    const zoomToAllAssets = useCallback(() => {
        if (debouncedAssets) {
            const assetBounds = filterActiveAssets(debouncedAssets)
                .filter(asset => asset.latestEvent !== undefined)
                .map(asset => asset.latestEvent!.location.rawCoordinates)
                .map(coordinate => [coordinate.latitude, coordinate.longitude]);

            if (assetBounds.length !== 0) {
                const rawBounds = new L.LatLngBounds(assetBounds as L.LatLngBoundsLiteral);
                const paddedBounds = addPaddingToBounds(rawBounds);
                mapRef.current?.fitBounds(paddedBounds);
            }
        }
    }, [debouncedAssets]);

    useEffect(() => {
        zoomToAllAssets();
    }, [debouncedAssets, zoomToAllAssets]);

    const {clusterEnabled, mapType} = useContext(UserPreferencesContext);
    const containerStyle = {
        width: "100%",
        height: "100%",
        minHeight: "300px",
        margin: "0 auto",
        borderRadius: '5px',
        overflow: 'hidden'
    };

    const filterActiveAssets = (assets: Asset[]) => {
        return assets.filter(asset => asset.status !== "INACTIVE");
    };



    function addPaddingToBounds(bounds: L.LatLngBounds) {
        const padding = 0.130;
        const paddingBounds = new L.LatLngBounds(
            [bounds.getSouth() - padding, bounds.getWest() - padding],
            [bounds.getNorth() + padding, bounds.getEast() + padding]
        );
        return paddingBounds;
    }

    return (
        <div
            style={containerStyle}
            onClick={() => {
                navigate("/live-map")
            }}
        >
            <MapContainer
                ref={mapRef}
                attributionControl={false}
                style={containerStyle}
                center={[35.0,-40.0]}
                zoom={2}
                touchZoom={false}
                doubleClickZoom={false}
                dragging={false}
                keyboard={false}
                scrollWheelZoom={false}
                zoomControl={false}
                id="leaflet-container"
                whenReady={() => {
                    resizeMap(mapRef)
                }}
            >
                <AttributionControl position="bottomright" prefix=""/>
                <MapTileLayer mapLayer={mapType}/>

                {/* Asset Markers */}
                {props.assets && props.assets.length > 0 ?
                    (<AssetMarkerLayer
                        assets={filterActiveAssets(props.assets)}
                        clusteringEnabled={clusterEnabled}
                        labelsEnabled={false}
                        selectedAsset={undefined}
                        setSelectedAsset={() => {}}
                        disableClick={true}
                        labelLayer={props.labelLayer}
                    />) :
                    (<></>)}
                <MapButtonControl placement="bottomright" tooltip="Full View" toggled={false} tooltipPlacement="left"
                                  icon={<OpenInFullIcon/>} onClick={() => {
                }}/>
            </MapContainer>
        </div>
    );
};


export default MapWidget;
