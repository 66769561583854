import { Box, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Asset } from "../../hooks/assets/dto/Asset";
import { TimelineBlock } from "../../hooks/assets/dto/TimelineBlock";
import TimelineAddAsset from "./components/TimelineAddAsset";
import TimelineAssetLabel from "./components/TimelineAssetLabel";
import TimelineChart from "./components/TimelineChart";
import TimelineChartHeader from "./components/TimelineChartHeader";
import TimelineToolbar from "./components/TimelineToolbar";
import dayjs, { Dayjs } from 'dayjs';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { colors } from "../../themes/AppTheme";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import { useAssetApi } from "../../hooks/assets/Assets";
import ReactGA from "react-ga4";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";

interface TimelinePageProps {
}

interface AssetTimelinePair {
    asset: Asset;
    timeline?: TimelineBlock[];
}


function TimelinePage(props: TimelinePageProps) {

    const { assets, getAssetTimelineBlocksForDate, loading } = useAssetApi({shouldLoadAll:true});

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs().startOf('date'));

    const [timelineAssetPairs, setTimelineAssetPairs] = useState<AssetTimelinePair[]>([]);

    async function addAssetPair(asset: Asset) {
        let newAssetPairs = [...timelineAssetPairs];
        let timelineBlocks = await getTimelineBlocks(asset);
        newAssetPairs.push({ asset: asset, timeline: timelineBlocks });
        setTimelineAssetPairs(newAssetPairs);
    }

    async function updateAllAssetPairTimelinesOnDateChanged(date: Dayjs) {
        let newAssetPairs = [...timelineAssetPairs];
        for (const element of newAssetPairs) {
            element.timeline = await getTimelineBlocks(element.asset, date);
        }
        setTimelineAssetPairs(newAssetPairs);
    }

    async function removeAssetPair(asset: Asset) {
        let newAssetPairs = [...timelineAssetPairs];
        newAssetPairs = newAssetPairs.filter((assetPair) => assetPair.asset.id !== asset.id);
        setTimelineAssetPairs(newAssetPairs);
    }

    async function getTimelineBlocks(asset: Asset, date?: Dayjs) {
        try {
            let blocks = await getAssetTimelineBlocksForDate(asset.id, date ? date.toDate() : selectedDate.toDate());
            if (!blocks) {
                blocks = []
            }
            return blocks as Array<TimelineBlock>
        } catch (error) {
            console.log("Failed to load timeline: " + error)
            return [];
        }
    }

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            component="main"
            sx={{
                height: 'calc(100vh - 43px)',
                flexGrow: 1,
                p: 1,
                backgroundColor: theme.palette.contentPage.background,
                display: 'flex', // Added to make the main Box a flex container
                flexDirection: 'column', // Added to make the main Box's children stack vertically
            }}
        >
            <Grid container padding="10px" spacing={2.5} sx={{ zIndex: '10', }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                        <Box sx={{

                            background: theme.palette.contentPage.backgroundPanel,
                            padding: '5px',
                            borderRadius: '5px',
                            boxShadow: 3,
                            width: '100%',
                        }}>
                            <TimelineToolbar selectedDate={selectedDate} setSelectedDate={(date: Dayjs) => {
                                setSelectedDate(date);
                                updateAllAssetPairTimelinesOnDateChanged(date);
                            }} />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sx={{ flex: '1 1 auto', overflow: 'scroll' }} padding="10px" spacing={2.5}>
                <Grid item xs={12} md={3} lg={3}>
                    <Box sx={{
                        background: theme.palette.contentPage.backgroundPanel,
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        borderRadius: '5px',
                        boxShadow: 3,
                        width: '100%',
                        height: '100%'
                    }}>
                        <TimelineAddAsset assets={assets || []} onAssetSelected={(asset) => {ReactGA.event("RT2-Timeline-AddAsset"); addAssetPair(asset)}} selectedTimelineAssets={timelineAssetPairs.map((pair) => pair.asset.id)} />
                        <Divider />
                        {timelineAssetPairs.length !== 0 ? <></> :
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                padding: '10px',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                                <AirportShuttleIcon sx={{ color: colors.ram_purple, paddingRight: '2px' }} fontSize="large" />
                                <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>

                                    <Typography variant="caption">{t('timeline.no_assets_selected')}</Typography>
                                </Box>
                            </Box>}
                        {/* Details*/}
                        {timelineAssetPairs.map((pair) => (<TimelineAssetLabel key={pair.asset.id} assetLabel={pair.asset.alias ? pair.asset.alias : pair.asset.identificationMark} onDeleteClicked={() => removeAssetPair(pair.asset)} />))}

                    </Box>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>

                    <Box sx={{
                        background: theme.palette.contentPage.backgroundPanel,
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        borderRadius: '5px',
                        boxShadow: 3,
                        width: '100%',
                        height: '100%'
                    }}>

                        <TimelineChartHeader />
                        {timelineAssetPairs.length !== 0 ? <></> :
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',

                                width: '100%',
                                height: '100%',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                                <ViewTimelineIcon sx={{ color: colors.ram_purple, paddingRight: '2px' }} fontSize="large" />
                                <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', marginRight: '5px' }}>

                                    <Typography variant="caption">{t('timeline.no_assets_timeline')}</Typography>
                                </Box>
                            </Box>}
                        {loading && <Box sx={{ textAlign: "center", padding: "20px" }}><LoadingSpinner /></Box>}
                        {!loading && timelineAssetPairs.map((pair) => (<TimelineChart key={pair.asset.id} data={pair.timeline} />))}
                    </Box>
                </Grid>
            </Grid>
        </Box >);
}

export default TimelinePage;
