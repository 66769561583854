import { Box, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface TimelineAssetLabelProps {
    assetLabel: string;
    onDeleteClicked: () => void;
}

export default function TimelineAssetLabel(props: Readonly<TimelineAssetLabelProps>) {

    return (<>
        <Box sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            height: '50px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        }}>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'

            }}>
                <Typography variant="smallTitle">
                    {props.assetLabel}
                </Typography>
            </Box>
            <IconButton
                size='small'
                disableRipple
                onClick={(event) => { props.onDeleteClicked() }}
                sx={{
                    color: 'gray',
                    alighSelf: 'right',
                    '&:hover': {
                        color: 'black',
                    },
                }}
            ><CloseIcon fontSize="small" /></IconButton>
        </Box >
        <Divider />
    </>
    );
}