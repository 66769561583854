import {Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme} from '@mui/material';
import React, {ReactNode, useContext} from 'react';

import AddCommentIcon from '@mui/icons-material/AddComment';
import VideocamIcon from '@mui/icons-material/Videocam';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Person2Icon from '@mui/icons-material/Person2';
import HelpIcon from '@mui/icons-material/Help';
import BlindIcon from '@mui/icons-material/Blind';
import {Link} from "react-router-dom";
import {useIntercom} from "react-use-intercom";
import ReactGA from "react-ga4";
import {t} from "i18next";
import {UserPreferencesContext} from "../../../providers/UserPreferencesProvider";

interface QuickActionsWidgetProps {
}

const QuickActionsWidget: React.FC<QuickActionsWidgetProps> = (props: QuickActionsWidgetProps) => {
    const theme = useTheme();
    const {startSurvey} = useIntercom();
    const {languageCode} = useContext(UserPreferencesContext);

    return (
        <Box
            id="feature-list-panel"
            display="flex"
            flexDirection="column"
            alignItems="Left"
            sx={{
                background: theme.palette.contentPage.backgroundPanel,
                height: '100%',
                padding: '30px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
                boxShadow: 3,
            }}>
            <Typography variant="header">
                {t('quick_access.title')}
            </Typography>
            <List dense={true}>

                <NonFrenchQuickAccessButton languageCode={languageCode}>
                    <QuickAccessButton
                        id="feedback-button"
                        text={t('quick_access.feedback_button')}
                        icon={<AddCommentIcon/>}
                        onClick={() => {
                            ReactGA.event("RT2-QAW-FeedbackClicked");
                            startSurvey(34076676);
                        }}
                    />
                </NonFrenchQuickAccessButton>

                <NonFrenchQuickAccessButton languageCode={languageCode}>
                    <QuickAccessButton
                        id="ram-live-button"
                        text={t('quick_access.ram_live_button')}
                        icon={<VideocamIcon/>} onClick={() => {
                        ReactGA.event("RT2-QAW-Link-RAMLive");
                        window.open("https://go.ramtracking.com/l/28952/2022-07-06/9mfpjc", "_blank", "noopener");
                    }}/>
                </NonFrenchQuickAccessButton>

                <NonFrenchQuickAccessButton languageCode={languageCode}>
                    <QuickAccessButton
                        id="marketplace-button"
                        text={t('quick_access.marketplace_button')}
                        icon={<StorefrontIcon/>}
                        onClick={() => {
                            ReactGA.event("RT2-QAW-Link-MarketPlace");
                            window.open("https://go.ramtracking.com/l/28952/2022-07-06/9mfpjg", "_blank", "noopener");
                        }}/>
                </NonFrenchQuickAccessButton>

                <QuickAccessButton id="edit-profile-button" text={t('quick_access.edit_profile_button')} icon={<Person2Icon/>} to={"/profile"}/>
                <QuickAccessButton id="customise-accessibility-button" text={t('quick_access.customise_accessibility_button')}
                                   to={"/profile/accessibility"} icon={<BlindIcon/>}/>
                <QuickAccessButton id="help-button" className="account_callsupport_link" text={t('quick_access.help_button')}
                                   icon={<HelpIcon/>}/>
            </List>
        </Box>
    );
};

function NonFrenchQuickAccessButton(props: {
    languageCode: string;
    children: React.ReactNode;
}) {
    return props.languageCode.toLowerCase() !== "fr" ? <>{props.children}</> : null;
}

function QuickAccessButton(props: {
    id?: string,
    className?: string,
    text: string,
    icon: ReactNode,
    href?: string,
    to?: string,
    onClick?: () => void
}) {
    return (
        <ListItemButton
            id={props.id}
            className={props.className}
            component={props.to ? Link : (props.href ? "a" : "div")}
            to={props.to}
            href={props.href}
            onClick={() => props.onClick && props.onClick()}>
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text}/>
        </ListItemButton>
    );
}


export default QuickActionsWidget;
