//hooks
import * as React from "react";
import {useContext, useState} from "react";

//THIS DASHBOARD COMPONENT IS A SAMPLE TO DEMO WHAT RAM TRACKING COULD LOOK LIKE.
//MUI Components
import Box from "@mui/material/Box";
import AppDrawer from "../../components/appdrawer/AppDrawer";
//Content Pages
import HomePage from "../home/HomePage";


//Import Props
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import TimelinePage from "../timeline/TimelinePage";
import AlertsPage from "../alerts/AlertsPage";
import ReportsPage from "../reports/ReportsPage";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {UserAccountContext} from "../../providers/UserAccountProvider";
import {UserPreferences} from "../../hooks/user/dto/UserPreferences";
import LoadingPage from "../loading/LoadingPage";
import ProfilePage from "../profile/ProfilePage";
import UserInformationPage from "../profile/components/content/UserInformationPage";
import SetupWizard from "../profilewizard/SetupWizard";
import {useTranslation} from "react-i18next";
import GeofencesPage from "../geofences/GeofencesPage";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import AccessibilityPage from "../profile/components/content/AccessibilityPage";
import GeofenceAlertsPage from "../alerts/geofence/GeofenceAlertsPage";
import EventAlertsPage from "../alerts/event/EventAlertsPage";
import useAccountApi, {Account} from "../../hooks/account/Account";
import useUserApi from "../../hooks/user/User";
import ImpersonatePage from "../impersonate/ImpersonatePage";
import ImpersonateUserInformationPage from "../impersonate/content/ImpersonateUserInformationPage";
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";
import LivesharePage, {LiveshareListPage} from "../liveshare/LivesharePage";
import DistanceAlertsPage from "../alerts/distance/DistanceAlertsPage";
import {Feature} from "../../utils/FeatureRestrictionUtils";
import {PackageRestrictedContent} from "./PackageRestrictedContent";
import DistanceAlertsEditorPage from "../alerts/distance/DistanceAlertsEditorPage";
import CreateLivesharePage from "../liveshare/CreateLivesharePage";
import UpdateLivesharePage from "../liveshare/UpdateLivesharePage";
import DataAttributionPage from "../dataattribution/DataAttributionPage";
import ReactGA from "react-ga4";
import ScheduledVehicleReportsPage from "../reports/scheduled/ScheduledVehicleReportsPage";
import FeatureRestrictionPage from "../restrictions/FeatureRestrictionPage";
import ScheduledVehicleReportsEditorPage from "../reports/scheduled/ScheduledVehicleReportsEditorPage";
import FuelAlertsPage from "../alerts/fuel/FuelAlertsPage";
import PropertiesLoader from "../../properties/PropertiesLoader";
import AssetsPage from "../assets/AssetsPage";
import WasteAlertsPage from "../alerts/waste/WasteAlertsPage";
import UpdateAssetPage from "../assets/UpdateAssetsPage";
import AssetGroupsPage from "../assetgroups/AssetGroupsPage";
import UpdateAssetGroupPage from "../assetgroups/UpdateAssetGroupsPage";
import CreateAssetGroupPage from "../assetgroups/CreateAssetGroupsPage";
import ViewGeneratedReportsPage from "../reports/scheduled/ViewGeneratedReportsPage";
import ChangeRegistrationAssetPage from "../assets/ChangeRegistrationAssetPage";
import WebhooksPage from "../webhooks/WebhooksPage";
import WebhooksPageEditor from "../webhooks/WebhooksPageEditor";
import ApiKeyPage from "../apikeys/ApiKeyPage";
import ServicesPage from "../servicing/ServicesPage";
import ServiceRecordsPage from "../servicing/servicerecords/ServiceRecordsPage";
import ServiceRecordsEditorPage from "../servicing/servicerecords/ServiceRecordsEditorPage";
import EngineHoursAlertsPage from "../alerts/engineHours/EngineHoursAlertsPage";
import EngineHoursAlertsEditorPage from "../alerts/engineHours/EngineHoursAlertsEditorPage";
import GeofenceAlertsEditorPage from "../alerts/geofence/GeofenceAlertsEditorPage";
import EventAlertsEditorPage from "../alerts/event/EventAlertsEditorPage";
import FuelAlertsEditorPage from "../alerts/fuel/FuelAlertsEditorPage";
import WasteAlertsEditorPage from "../alerts/waste/WasteAlertsEditorPage";
import AccountSettingsPage from "../profile/components/content/AccountSettingsPage";
import MapPage from "../map/MapPage";
import AssetPage from "../asset/AssetPage";
import RouteReplayAssetPage from "../routereplay/RouteReplayAssetPage";
import TemperatureAlertsPage from "../alerts/temperature/TemperatureAlertsPage";
import TemperatureAlertsEditorPage from "../alerts/temperature/TemperatureAlertsEditorPage";
import {SsoUser} from "../../hooks/user/dto/SsoUser";
import ComponentTestPage from "../playground/ComponentTestPage";

const properties = PropertiesLoader();
export default function Dashboard() {

    const {getAccount ,account, loading: loadingAccount} = useAccountApi()
    const [loadingUserData, setLoadingUserData] = useState<boolean>(true);
    const {getUserProfile, getUserPreferences, getActivationKey} = useUserApi();

    const auth: IAuthContext = useContext(AuthContext)
    const {
        preferencesLoaded,
        setLargeText,
        setColorPalette,
        setOpenDrawer,
        setLanguageCode,
        setPreferencesLoaded,
        setClusterEnabled,
        setLabelsEnabled,
        setMapType,
        setTwentyFourHourEnabled
    } = useContext(UserPreferencesContext)
    const {setUser} = useContext(UserAccountContext)
    const curLoc = useLocation();
    const {t} = useTranslation();
    const {impersonateId} = useContext(ImpersonateUserContext)
    const pageTitleMap = (pathname: string) => {
        let title = t("RAM Tracking - ");

        //check if the path is assets or route-replay and remove the id from the path
        const basePath = pathname.split("/")[1];
        const idBasedPath = basePath.includes("route-replay") || basePath.includes("assets");
        if (idBasedPath)  {
            pathname = `/${basePath}`;
        }

        title += t("pagetitles." + pathname);
        return title;

    };

    React.useEffect(() => {
        //Load all user data
        async function loadUserData() {
            try {
                let user = await getUserProfile();
                if (!user) {
                    auth.logOut();
                }
                let preferences: UserPreferences = await getUserPreferences() as UserPreferences;
                if (!preferences) {
                    setPreferencesLoaded(false)
                } else {
                    setOpenDrawer(preferences.openDrawer)
                    setLanguageCode(preferences.languageCode)
                    setClusterEnabled(preferences.clusterEnabled)
                    setLabelsEnabled(preferences.labelsEnabled)
                    setMapType(preferences.mapType)
                    setLargeText(preferences.largeText)
                    setColorPalette(preferences.colorPalette)
                    setTwentyFourHourEnabled(preferences.twentyFourHourEnabled)
                    setPreferencesLoaded(true)
                }
                setUser(user)
                setLoadingUserData(false)
            } catch (error) {
                console.error(
                    "Failed to fetch current user profile. Logging out. Error: " + error
                );
                auth.logOut();
            }
        }

        loadUserData();

        // Clean up the interval when the component unmounts
        // return () => clearInterval(intervalId);
    }, [impersonateId]);

    React.useEffect(() => {
        const curTitle = pageTitleMap(curLoc.pathname);

        if (curTitle && document.title !== curTitle) {

            document.title = curTitle;
            ReactGA.send({hitType: "pageview", page: curLoc.pathname, title: curTitle});

        }
    }, [curLoc.pathname])

    if (loadingUserData || loadingAccount) {
        return <LoadingPage/>
    }

    if (!preferencesLoaded) {
        return <SetupWizard/>
    }

    const expiredContent = <FeatureRestrictionPage
        title={t('expired_page.title')}
        description={t('expired_page.description')}
    />

    return (
        <Routes>
            <Route
                path="/*"
                element={
                    account?.featurePackage === 'EXPIRED' ? (
                        <Navigate to="/expired" replace/>
                    ) : (
                        ProtectedRoutes({account})
                    )
                }
            />
            <Route path="/expired" element={expiredContent}/>
        </Routes>
    );
}

interface ProtectedRoutesProps {
    account?: Account;
}

export function ProtectedRoutes(props: Readonly<ProtectedRoutesProps>) {
    const routeReplayAssetContent = <PackageRestrictedContent
        feature={Feature.ROUTE_REPLAY}
        featurePackage={props.account!.featurePackage}
        page={<RouteReplayAssetPage></RouteReplayAssetPage>}
        restrictionPageTitleKey={'feature_restriction.route_replay.title'}
        restrictionPageDescriptionKey={'feature_restriction.route_replay.description'}
    />

    const timelineContent = <PackageRestrictedContent
        feature={Feature.TIMELINE}
        featurePackage={props.account!.featurePackage}
        page={<TimelinePage></TimelinePage>}
        restrictionPageTitleKey={'feature_restriction.timeline.title'}
        restrictionPageDescriptionKey={'feature_restriction.timeline.description'}
    />

    const alertsContent = <PackageRestrictedContent
        feature={Feature.ALERTS}
        featurePackage={props.account!.featurePackage}
        page={<AlertsPage></AlertsPage>}
        restrictionPageTitleKey={'feature_restriction.alerts.title'}
        restrictionPageDescriptionKey={'feature_restriction.alerts.description'}
    />

    const reportsContent = <PackageRestrictedContent
        feature={Feature.REPORTS}
        featurePackage={props.account!.featurePackage}
        page={<ReportsPage></ReportsPage>}
        restrictionPageTitleKey={'feature_restriction.reports.title'}
        restrictionPageDescriptionKey={'feature_restriction.reports.description'}
    />

    const servicesContent = <PackageRestrictedContent
        feature={Feature.SERVICES}
        featurePackage={props.account!.featurePackage}
        page={<ServicesPage></ServicesPage>}
        restrictionPageTitleKey={'feature_restriction.services.title'}
        restrictionPageDescriptionKey={'feature_restriction.services.description'}
    />

    const liveshareContent = <PackageRestrictedContent
        feature={Feature.LIVESHARE}
        featurePackage={props.account!.featurePackage}
        page={<LivesharePage></LivesharePage>}
        restrictionPageTitleKey={'feature_restriction.liveshare.title'}
        restrictionPageDescriptionKey={'feature_restriction.liveshare.description'}
    />

    const ServiceRecordRoute = () => {
        return (
            <ServiceRecordsPage/>
        );
    };

    const ServiceRecordEditorRoute = () => {
        return (
            <ServiceRecordsEditorPage/>
        );
    }

    const GeofenceAlertRoute = () => {
        return (
            <GeofenceAlertsPage/>
        );
    };

    const EventAlertRoute = () => {
        return (
            <EventAlertsPage/>
        );
    };

    const DistanceAlertEditorRoute = () => {
        return (
            <DistanceAlertsEditorPage/>
        );
    }

    const DistanceAlertRoute = () => {
        return (
            <DistanceAlertsPage/>
        );
    };

    const FuelAlertRoute = () => {
        return (
            <FuelAlertsPage/>
        );
    };

    const FuelAlertsEditorRoute = () => {
        return (
            <FuelAlertsEditorPage/>
        );
    };

    const WasteAlertRoute = () => {
        return (
            <WasteAlertsPage/>
        );
    };

    const WasteAlertsEditorRoute = () => {
        return (
            <WasteAlertsEditorPage/>
        );
    };

    const EngineHourAlertRoute = () => {
        return (
            <EngineHoursAlertsPage/>
        );
    };

    const EventAlertsEditorRoute = () => {
        return (
            <EventAlertsEditorPage/>
        );
    };

    const GeofenceAlertsEditorRoute = () => {
        return (
            <GeofenceAlertsEditorPage/>
        );
    };
    const CreateScheduledVehicleReportsRoute = () => {
        return (
            <ScheduledVehicleReportsEditorPage/>
        );
    };
    const ViewGeneratedReportsRoute = () => {
        return (
            <ViewGeneratedReportsPage/>
        );
    };
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Box sx={{display: 'flex'}}>
                        <AppDrawer/>
                        <Outlet/>
                    </Box>
                }
            >
                <Route index element={<HomePage/>}/>
                <Route path="live-map" element={<MapPage/>}/>
                {properties.featureFlags.assetManagement && <Route path="assets" element={<AssetsPage/>}/>}
                <Route path="route-replay/:id" element={routeReplayAssetContent}/>
                <Route path="timeline" element={timelineContent}/>
                <Route path="geofences" element={<GeofencesPage/>}/>
                <Route path="assets" element={<AssetsPage/>}/>
                <Route path="assets/:id" element={<AssetPage/>}/>
                <Route path="update-assets" element={<UpdateAssetPage/>}/>
                <Route path="change-registration" element={<ChangeRegistrationAssetPage/>}/>
                <Route path="asset-groups" element={<AssetGroupsPage/>}/>
                <Route path="create-asset-groups" element={<CreateAssetGroupPage/>}/>
                <Route path="update-asset-groups" element={<UpdateAssetGroupPage/>}/>
                <Route path="webhooks" element={<WebhooksPage/>}/>
                <Route path="create-webhooks" element={<WebhooksPageEditor/>}/>
                <Route path="update-webhooks" element={<WebhooksPageEditor/>}/>
                <Route path="api-keys" element={<ApiKeyPage/>}/>
                <Route path="alerts" element={alertsContent}>
                    <Route path="" element={<GeofenceAlertRoute/>}/>
                    <Route path="geofence-alerts" element={<GeofenceAlertRoute/>}/>
                    <Route path="create-geofence-alerts" element={<GeofenceAlertsEditorRoute/>}/>
                    <Route path="update-geofence-alerts" element={<GeofenceAlertsEditorRoute/>}/>
                    <Route path="distance-alerts" element={<DistanceAlertRoute/>}/>
                    <Route path="create-distance-alerts" element={<DistanceAlertEditorRoute/>}/>
                    <Route path="update-distance-alerts" element={<DistanceAlertEditorRoute/>}/>
                    <Route path="event-alerts" element={<EventAlertRoute/>}/>
                    <Route path="create-event-alerts" element={<EventAlertsEditorRoute/>}/>
                    <Route path="update-event-alerts" element={<EventAlertsEditorRoute/>}/>
                    <Route path="fuel-alerts" element={<FuelAlertRoute/>}/>
                    <Route path="create-fuel-alerts" element={<FuelAlertsEditorRoute/>}/>
                    <Route path="update-fuel-alerts" element={<FuelAlertsEditorRoute/>}/>
                    <Route path="waste-alerts" element={<WasteAlertRoute/>}/>
                    <Route path="create-waste-alerts" element={<WasteAlertsEditorRoute/>}/>
                    <Route path="update-waste-alerts" element={<WasteAlertsEditorRoute/>}/>
                    <Route path="engine-hour-alerts" element={<EngineHourAlertRoute/>}/>
                    <Route path="create-engine-hour-alerts" element={<EngineHoursAlertsEditorPage/>}/>
                    <Route path="update-engine-hour-alerts" element={<EngineHoursAlertsEditorPage/>}/>
                    <Route path="temperature-alerts" element={<TemperatureAlertsPage/>}/>
                    <Route path="create-temperature-alerts" element={<TemperatureAlertsEditorPage/>}/>
                    <Route path="update-temperature-alerts" element={<TemperatureAlertsEditorPage/>}/>

                </Route>
                <Route path="reports" element={reportsContent}>
                    <Route path="" element={<ReportsPage/>}/>
                    <Route path="vehicle-reports" element={<ReportsPage/>}/>
                    <Route path="scheduled-reports" element={<ScheduledVehicleReportsPage/>}/>
                    <Route path="generator-reports" element={<ReportsPage/>}/>
                </Route>
                <Route path="servicing" element={servicesContent}>
                    <Route path="" element={<ServiceRecordRoute/>}/>
                    <Route path="service-records" element={<ServiceRecordRoute/>}/>
                    <Route path="create-service-record" element={<ServiceRecordEditorRoute/>}/>
                    <Route path="update-service-record" element={<ServiceRecordEditorRoute/>}/>
                    <Route path="view-service-record" element={<ServiceRecordEditorRoute/>}/>

                </Route>
                <Route path="view-generated-reports" element={<ViewGeneratedReportsRoute/>}/>
                <Route path="scheduled-reports-editor" element={<CreateScheduledVehicleReportsRoute/>}/>
                <Route path="profile" element={<ProfilePage/>}>
                    <Route path="" element={<UserInformationPage/>}/>
                    <Route path="user-info" element={<UserInformationPage/>}/>
                    <Route path="accessibility" element={<AccessibilityPage/>}/>
                    <Route path="account-settings" element={<AccountSettingsPage/>}/>
                </Route>

                <Route path="dataattribution" element={<DataAttributionPage/>}></Route>

                <Route path="impersonate" element={<ImpersonatePage/>}>
                    <Route path="" element={<ImpersonateUserInformationPage/>}/>
                </Route>
                <Route path="liveshare" element={liveshareContent}>
                    <Route path="" element={<LiveshareListPage/>}/>
                    <Route path="create-liveshare" element={<CreateLivesharePage/>}/>
                    <Route path="update-liveshare" element={<UpdateLivesharePage/>}/>
                </Route>
                <Route path="/developer" element={<ComponentTestPage/>}/>
                <Route path="*" element={<></>}/>
            </Route>
        </Routes>
    );
}
