import {Grid, useTheme} from "@mui/material";
import FeatureListPanel from "./widgets/FeatureListPanel";

import {useAssetWebSocket} from "../../hooks/assets/Assets";
import MapWidget from "./widgets/MapWidget";
import QuickActionsWidget from "./widgets/QuickActionsWidget";
import FeaturedProductWidget from "./widgets/FeaturedProductWidget";
import FleetOverviewWidget from "./widgets/AssetOverviewWidget";
import {useContext, useEffect, useState} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";
import useAccountApi, {Account} from "../../hooks/account/Account";
import * as React from "react";
import useUserApi from "../../hooks/user/User";
import {SsoUser} from "../../hooks/user/dto/SsoUser";
import PropertiesLoader from "../../properties/PropertiesLoader";


const properties = PropertiesLoader();
interface HomePageProps {
}

const ramAssistWidget = <FeaturedProductWidget
    id='ram_assist_panel'
    nameKey='ram_assist_panel.title'
    descriptionKey='ram_assist_panel.description'
    background='/images/products/assistbg.png'
    image='/images/products/assist_white.png'
    link='https://ramassist.com/portal'
/>

const ramAppWidget = <FeaturedProductWidget
    id='ram_app_panel'
    nameKey='ram_app_panel.title'
    descriptionKey='ram_app_panel.description'
    background='/images/products/app_bg.png'
    image='/images/products/app.png'
    link='https://www.ramtracking.com/vehicle-tracking/app/'
/>

const ramJobAssistWidget = <FeaturedProductWidget
    id='ram_job_assist_panel'
    nameKey='ram_job_assist_panel.title'
    descriptionKey='ram_job_assist_panel.description'
    background='/images/products/job_assist_bg.png'
    image='/images/products/job_assist_white.png'
    link='https://www.ramtracking.com/job-management/'
/>

function HomePage(props: HomePageProps) {
    const theme = useTheme();
    const {assets} = useAssetWebSocket();
    const {labelType} = useContext(UserPreferencesContext);
    const {getAccount ,account, loading} = useAccountApi()
    const {getUserProfile, getActivationKey} = useUserApi();

    const [showSunsetAlert, setShowSunsetAlert] = React.useState(false);
    const [sunsetRedirectUrl, setSunsetRedirectUrl] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        const redirectToCaSunsetIfRequired = async (account: Account | undefined, user: SsoUser) => {
            const isCaAccount = account?.country === 'CA';
            const caSunsetStatus = account?.caSunsetStatus;
            const isUserAdmin = user?.roleList.some(role => role.name === 'Customer Admin' || role.name === 'Customer Super Admin');

            const key = await getActivationKey();

            if (isCaAccount && isUserAdmin) {
                switch (caSunsetStatus) {
                    case 'None Forced':
                        setSunsetRedirectUrl(`${properties.activationUrl}/api/v1/sunset/start?key=${key}`)
                        setShowSunsetAlert(true);
                        break;
                    case 'Forced':
                        window.location.replace(`${properties.activationUrl}/api/v1/sunset/start?key=${key}`)
                        break;
                    default:
                        break;
                }
            }
        }

        async function loadData() {
            const account = await getAccount();
            const user = await getUserProfile();
            await redirectToCaSunsetIfRequired(account, user);
        }

        loadData();
    }, []);

    if (loading) {
        return <div
            className={"flex flex-col justify-center items-center h-[calc(100vh-48px)] w-full text-center"}>
            <LoadingSpinner/>
        </div>
    }

    return (
        <div
            id="home-page"
            style={{
                width: '100%',
                height: '100%',
                minHeight: 'calc(100vh - 48px)',
                backgroundColor: theme.palette.contentPage.background,
                padding: '10px'
            }}>
            {showSunsetAlert && <AlertLinkPanel
                title={"Devices at risk"}
                description={"One or more devices you are tracking are at risk of being disconnected due to the Canada network shutdown and need to be replaced.."}
                buttonText={"Order free replacement devices"}
                link={sunsetRedirectUrl}
            />}
            <Grid container padding="10px" spacing={2.5}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} lg={3}>
                            <QuickActionsWidget/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={account?.hasAccessPackageUpgrade ? 6 : 9}>
                            <MapWidget assets={Object.keys(assets).map(key => assets[key]) || []} labelLayer={labelType}/>
                        </Grid>
                        {account?.hasAccessPackageUpgrade && (
                            <Grid item xs={12} md={12} lg={3}>
                                <FeatureListPanel color={theme.palette.contentPage.backgroundPanel}/>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <FleetOverviewWidget assets={Object.keys(assets).map(key => assets[key]) || []} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramAssistWidget}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramAppWidget}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {ramJobAssistWidget}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

function AlertLinkPanel({title, description, buttonText, link}: {title: string, description: string, buttonText: string, link: string | undefined}) {
    return (<div className="p-4 m-3 text-error border border-error rounded-lg bg-errorLight" role="alert">
        <div className="flex items-center">
            <svg className="shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <h3 className="text-lg font-medium">{title}</h3>
        </div>
        <div className="mt-2 mb-4 text-sm">
            {description}
        </div>
        <div className="flex">
            {link && <a href={link} className="text-errorLight bg-error hover:bg-error/80 focus:ring-4 focus:outline-none focus:ring-base-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center">
                {buttonText}
            </a>}
        </div>
    </div>);
}

export default HomePage;
