import {createContext, Dispatch, SetStateAction, useState} from "react";
import {AssetDistance} from "../hooks/assets/dto/Asset";

interface NearestAssetContextProps {
    nearestAssets: AssetDistance[] | null;
    setNearestAssets: Dispatch<SetStateAction<AssetDistance[] | null>>;
}

const NearestAssetContext = createContext<NearestAssetContextProps>({
    nearestAssets: null,
    setNearestAssets: () => {},
});

const NearestAssetProvider = ({children}: {children: React.ReactNode}) => {
    const [nearestAssets, setNearestAssets] = useState<AssetDistance[] | null>(null);
    const contextValue: NearestAssetContextProps = {
        nearestAssets,
        setNearestAssets,
    }

    return (
        <NearestAssetContext.Provider value={contextValue}>
            {children}
        </NearestAssetContext.Provider>
    );
}

export { NearestAssetContext, NearestAssetProvider };