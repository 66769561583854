import React, {createContext, useEffect, useState} from 'react';
import {SsoUser} from '../hooks/user/dto/SsoUser';
import i18n from "i18next";
import {AccountGeneralSettings} from "../hooks/account/dto/AccountGeneralSettings";
import useAccountApi from "../hooks/account/Account";
import {UserPreferences} from "../hooks/user/dto/UserPreferences";

interface GeneralSettingsContextProps {
    generalSettingsLoaded: boolean;
    setGeneralSettingsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    generalSettings?: AccountGeneralSettings
    setGeneralSettings: React.Dispatch<React.SetStateAction<AccountGeneralSettings | undefined>>;
    reloadGeneralSettings: () => void;
}

export const GeneralSettingsContext = createContext<GeneralSettingsContextProps>({
    generalSettingsLoaded: false,
    setGeneralSettingsLoaded: () => { },
    generalSettings: undefined,
    setGeneralSettings: () => {},
    reloadGeneralSettings: () => {},
});

interface Props {
    children: React.ReactNode;
}

export const GeneralSettingsProvider = ({children}: Props) => {
    const [generalSettingsLoaded, setGeneralSettingsLoaded] = useState<boolean>(false);
    const [generalSettings, setGeneralSettings] = useState<AccountGeneralSettings | undefined>(undefined);
    const { getAccountGeneralSettings } = useAccountApi();
    const [reloadFlag, setReloadFlag] = useState<boolean>(false);

    const reloadGeneralSettings = () => {
        setReloadFlag(prev => !prev);
    };

    useEffect(() => {
        async function fetchGeneralSettings() {
            const settings = await getAccountGeneralSettings();
            if (settings) {
                setGeneralSettings(settings);
                setGeneralSettingsLoaded(true);
            }
        }

        fetchGeneralSettings();
    }, [reloadFlag]);


    return (
        <GeneralSettingsContext.Provider value={{generalSettingsLoaded, setGeneralSettingsLoaded, generalSettings, setGeneralSettings, reloadGeneralSettings}}>
            {children}
        </GeneralSettingsContext.Provider>
    );
};
