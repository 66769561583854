import { Box, Grid, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

interface SplitViewProps {
    scondaryNavigation: ReactNode;
    content: ReactNode;
}

export default function SplitView(props: Readonly<SplitViewProps>) {
    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 1, backgroundColor: theme.palette.contentPage.background }}>
            <Grid container sx={{ minHeight: 'calc(100vh - 43px)', }} padding="10px" spacing={2.5}>
                <Grid item xs={12} md={3} lg={3}>
                    {props.scondaryNavigation}
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    {props.content}
                </Grid>
            </Grid>
        </Box>
    );
}

