import { Box, Divider, ListItemButton, Typography } from "@mui/material";
import { AssetCardIconText } from "../../../map/components/asset-list/AssetCardIconText";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Geofence } from "../../../../hooks/geofences/dto/Geofence";
import { useTranslation } from "react-i18next";

interface GeofencesListItemProps {
    geofence: Geofence;
    onClick: Function;

}

export function GeofencesListItem(props: GeofencesListItemProps) {

    const { t } = useTranslation();

    return (
        <>
            <ListItemButton alignItems="flex-start" onClick={(event) => props.onClick(props.geofence)}>
                <Box>
                    <Typography
                        sx={{ display: "block", width: "100%" }}
                        component="div"
                        variant="cardtitle"
                        color="text.primary"
                    >
                        {props.geofence.name}
                    </Typography>
                    <AssetCardIconText icon={<WorkspacesIcon fontSize="inherit" />} value={props.geofence.category?.name ?? t('geofences_page.card.no_category_set') as string} />
                </Box>
            </ListItemButton>
            <Divider component="li" />
        </>
    )
}