import { Box, Button, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TextInput from '../../../../components/form/TextInput';
import Timezones from '../../../../utils/Timezones';
import SelectInput from '../../../../components/form/SelectInput';
import { UserAccountContext } from '../../../../providers/UserAccountProvider';
import isEmailValid from '../../../../utils/EmailValidator';
import { SsoUser } from '../../../../hooks/user/dto/SsoUser';
import StepHeader from '../shared/StepHeader';
import StepContainer from '../shared/StepContainer';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import useUserApi from '../../../../hooks/user/User';

interface PersonalInformationStepProps {
    onContinue: () => void;
    onBack: () => void;
}

export default function PersonalInformationStep(props: PersonalInformationStepProps) {
    const { t } = useTranslation();
    const auth: IAuthContext = useContext(AuthContext)
    const { updateUserProfile } = useUserApi();
    const { user, setUser } = useContext(UserAccountContext)

    async function submitChanges() {
        if (firstName !== '' && lastName !== '' && email !== '' && isEmailValid(email)) {
            try {
                let user = await updateUserProfile({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    timezone: timezone,
                    languageCode: languageCode,
                })

                if (!user) {
                    auth.logOut();
                }

                setUser(user as SsoUser)
                props.onContinue()
            } catch (error) {
                console.error(
                    "Failed to fetch update user profile. Logging out. Error: " + error
                );
                auth.logOut();
            }
        }
    }

    const timezones = Timezones.map((string) => (<MenuItem key={"tz-" + string} value={string}>{string}</MenuItem>));

    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');
    const [email, setEmail] = useState(user?.email || '');
    const [timezone, setTimezone] = useState(user?.timezone || 'Europe/London');
    const [languageCode] = useState(user?.languageCode || 'en');

    return (
        <StepContainer>
                <StepHeader
                    icon={<PersonOutlineOutlinedIcon />}
                    title={t('setup_wizard.personal_information_step.title')}
                    description={t('setup_wizard.personal_information_step.description')}
                />

                <Box
                    sx={{ textAlign: "left" }}
                >
                    <TextInput label={t('setup_wizard.personal_information_step.first_name')} required={true} isEmail={false} editMode={true} placeholder={t('setup_wizard.personal_information_step.first_name')} value={firstName} onChange={setFirstName} />
                    <TextInput label={t('setup_wizard.personal_information_step.last_name')} required={true} isEmail={false} editMode={true} placeholder={t('setup_wizard.personal_information_step.last_name')} value={lastName} onChange={setLastName} />
                    <TextInput label={t('setup_wizard.personal_information_step.email')} required={true} isEmail={true} editMode={true} placeholder={t('setup_wizard.personal_information_step.email')} value={email} onChange={setEmail} />
                    <SelectInput key="timezone-select" editMode={true} label={t('setup_wizard.personal_information_step.timezone')} required={true} items={timezones} value={timezone} onChange={setTimezone} />
                </Box>
                <Box>
                    <Button color='primary' sx={{ marginBottom: "5px" }} fullWidth variant="contained" onClick={submitChanges}>{t('button.continue')}</Button>
                    <Button color='secondary' fullWidth variant="contained" onClick={props.onBack}>{t('button.back')}</Button>
                </Box>
        </StepContainer>
    );
};





