import { Box } from "@mui/material";

import {ReactComponentElement, useEffect, useRef} from "react";
import { Map } from "leaflet";
import GeofencesPanel from "./components/panel/GeofencesPanel";
import { GeofenceWizardProvider } from "../../providers/GeofenceWizardProvider";
import GeofencesMapContainer from "./components/map/GeofencesMapContainer";
import { useGeofencesApi } from "../../hooks/geofences/Geofences";
import {useLocation} from "react-router-dom";

interface GeofencesPageProps {
  subview?: ReactComponentElement<any>;
}

function GeofencesPage(this: any, props: GeofencesPageProps) {
  const location = useLocation();
  const mapRef = useRef<Map>(null);
  const { geofences, getAllGeofences, loading } = useGeofencesApi(false);
  const latlng = location.state?.latlng ?? undefined;

  useEffect(() => {
    if (latlng && mapRef.current) {
      mapRef.current?.setView([latlng.lat, latlng.lng], 15);
    }
  }, [latlng, mapRef.current]);

  return (
    <>
      <GeofenceWizardProvider>
        <Box sx={{ minWidth: "350px", padding: "0" }}>
          <GeofencesPanel mapref={mapRef} loading={loading} geofences={geofences || []} updateGeofences={getAllGeofences} />
        </Box>
        <GeofencesMapContainer mapref={mapRef} geofences={geofences || []}   keepGeofencesInBounds={!latlng}/>
      </GeofenceWizardProvider>
    </>
  );
}

export default GeofencesPage;
