import {mapToReportResponse, mapToReportTypeArray} from "./ReportMapper";
import {ReportRequest} from "./dto/ReportRequest";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext} from "react";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

export function useReportsApi() {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk} = usePlatformApiFetch();

    const getAvailableReports = async () => {
        return new Promise((resolve, reject) => {
            platformApiFetch("report/v1/available-reports", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToReportTypeArray(json))
                .then(reportTypes => resolve(reportTypes))
                .catch((error:Error) => {
                    console.error("Failed to get available: " + error)
                    reject(error)
                });
        })
    }

    const generateReport = async (reportRequest: ReportRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("report/v1/generate-report?async=false", 'post', auth.token, JSON.stringify(reportRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToReportResponse(json))
                .then(reportResponse => resolve(reportResponse))
                .catch((error:Error) => {
                    console.error("Failed to generate report: " + error)
                    reject("error")
                });
        })
    }

    return {getAvailableReports, generateReport}
}
