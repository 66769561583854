import { Box, Typography } from '@mui/material';

interface StepHeaderProps {
    icon: JSX.Element;
    title: string;
    description: string;
}



export default function StepHeader(props: Readonly<StepHeaderProps>) {
    return (
        <Box>
            {props.icon}
            <Box
                p={2}>
                <Typography
                    sx={{
                        display: "block",
                        width: "100%",
                        paddingBottom: "0px"
                    }}
                    component="div"
                    variant="header"
                >
                    {props.title}
                </Typography>

                <Typography
                    sx={{
                        display: "block",
                        width: "100%",
                        paddingBottom: "0px"
                    }}
                    component="div"
                    variant="body"
                >
                    {props.description}
                </Typography>
            </Box>
        </Box>
    );
};





