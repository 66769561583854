import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorLabel } from "./SelectorLabel";

interface SummariseAMPMSelectorProps {
    selectedSummariseAMPM: boolean;
    setSelectedSummariseAMPM: (selectedSummariseAMPM: boolean) => void;
}

export function SummariseAMPMSelector(props: Readonly<SummariseAMPMSelectorProps>) {

    const { t } = useTranslation();
    const [internalSelectedSummariseAMPM, setInternalSelectedSummariseAMPM] = useState<boolean>(props.selectedSummariseAMPM);

    const handleSummariseAMPMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setInternalSelectedSummariseAMPM(checked);
        props.setSelectedSummariseAMPM(checked);
    };

    return <Grid container>
    <SelectorLabel
        title={t("report.summarise_by")}
        subtitle={t("report.select_summarise_by")}
        />
                
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <Grid container spacing={2}>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={internalSelectedSummariseAMPM}
                        onChange={handleSummariseAMPMChange}
                        color="primary"
                    />} label={t("scheduled_report.summarise_AMPM")}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}