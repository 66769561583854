import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorLabel } from "./SelectorLabel";

interface SummariseBySelectorProps {
    selectedByWeek: boolean;
    setSelectedByWeek: (selectedByWeek: boolean) => void;
    selectedByMonth: boolean;
    setSelectedByMonth: (selectedByMonth: boolean) => void;
    selectedByVehicle: boolean;
    setSelectedByVehicle: (selectedByVehicle: boolean) => void;
    selectedByDriver: boolean;
    setSelectedByDriver: (selectedByDriver: boolean) => void;
}

export function SummariseBySelector(props: Readonly<SummariseBySelectorProps>) {

    const { t } = useTranslation();
    const [internalSelectedByWeek, setInternalSelectedByWeek] = useState<boolean>(props.selectedByWeek);
    const [internalSelectedByMonth, setInternalSelectedByMonth] = useState<boolean>(props.selectedByMonth);

    const handleByWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setInternalSelectedByWeek(checked);
        if (checked) {
            setInternalSelectedByWeek(true);
            props.setSelectedByWeek(true);
            setInternalSelectedByMonth(false);
            props.setSelectedByMonth(false);
        }
    };

    const handleByMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setInternalSelectedByMonth(checked);
        if (checked) {
            setInternalSelectedByMonth(true);
            props.setSelectedByMonth(true);
            setInternalSelectedByWeek(false);
            props.setSelectedByWeek(false);
        }
    };

    const handleByVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setSelectedByVehicle(event.target.checked);
    };

    const handleByDriverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setSelectedByDriver(event.target.checked);
    };

    return <Grid container>
    <SelectorLabel
        title={t("report.summarise_by")}
        subtitle={t("report.select_summarise_by")}
        />
                
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <Grid container spacing={2}>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={internalSelectedByWeek}
                        onChange={handleByWeekChange}
                        color="primary"
                    />} label={t("report.by_week")}/>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={internalSelectedByMonth}
                        onChange={handleByMonthChange}
                        color="primary"
                    />} label={t("report.by_month")}/>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={props.selectedByVehicle}
                        onChange={handleByVehicleChange}
                        color="primary"
                    />} label={t("report.by_vehicle")}/>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        checked={props.selectedByDriver}
                        onChange={handleByDriverChange}
                        color="primary"
                    />} label={t("report.by_driver")}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}