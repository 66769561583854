import {Box, Button, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../../utils/AssetArraySorting";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, {Dayjs} from "dayjs";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import useAccountApi, {AccountContact} from "../../../hooks/account/Account";
import GeofenceAlertDayTable from "./components/GeofenceAlertDayTable";
import {AlertPeriod, GeofenceAlertRequest, useGeofenceAlertsApi} from "../../../hooks/alerts/GeofenceAlerts";
import GeofenceAlertGeofenceNamesTable from "./components/GeofenceAlertGeofenceNamesTable";
import {useGeofencesApi} from "../../../hooks/geofences/Geofences";
import {Geofence} from "../../../hooks/geofences/dto/Geofence";
import {filterAssetsBySearch} from "../../../utils/AssetArrayFiltering";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";
import SelectRecipientTable from "../../../components/table/shared/SelectRecipientTable";
import {extractTime} from "../../../utils/TimeUtils";

export default function GeofenceAlertsEditorPage() {
    const theme = useTheme();
    const {t} = useTranslation();
    const [recipients, setRecipients] = useState<AccountContact[]>([]);
    const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);

    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedAlert !== null && location.state.selectedAlert !== undefined;
    const selectedAlert = isEdit ? location.state.selectedAlert : undefined;

    const [selectedAlertPeriod, setSelectedAlertPeriod] = useState<AlertPeriod>(isEdit ? selectedAlert?.alertPeriod : AlertPeriod.ALL);

    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const [recipientFilter, setRecipientFilter] = React.useState<string | undefined>(undefined);
    const [geofenceNamesFilter, setGeofenceNamesFilter] = React.useState<string | undefined>(undefined);
    const {sort} = useContext(FilterSortContext);

    const {getCurrentAccountContacts} = useAccountApi();
    const {geofences, getAllGeofences, loading: loadingGeofences} = useGeofencesApi();
    const {createGeofenceAlert, updateGeofenceAlert} = useGeofenceAlertsApi()

    const {assets, getAllAssets, loading: loadingAssets} = useAssetApi();
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState<number[]>(isEdit ? [selectedAlert?.assetId] : []);
    const [selectedRecipientsRows, setSelectedRecipientsRows] = useState<string[]>(isEdit ? [selectedAlert?.email] : []);
    const [selectedGeofenceRows, setSelectedGeofenceRows] = useState<number[]>(isEdit ? [selectedAlert?.geofenceId] : []);


    const [selectedStartTime, setSelectedStartTime] = useState<Dayjs>(isEdit && selectedAlert ? extractTime(selectedAlert.startTime) : dayjs().startOf('date'));
    const [selectedEndTime, setSelectedEndTime] = useState<Dayjs>(isEdit && selectedAlert ? extractTime(selectedAlert.endTime) : dayjs().startOf('date'));

    const [alertOnEntry, setAlertOnEntry] = useState(isEdit ? selectedAlert?.alertOnEntry : false);
    const [alertOnExit, setAlertOnExit] = useState(isEdit ? selectedAlert?.alertOnExit : false);

    const handleAlertOnEntryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertOnEntry(event.target.checked);
    };

    const handleAlertOnExitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertOnExit(event.target.checked);
    };

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }
    const onRecipientSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setRecipientFilter(undefined);
        } else {
            setRecipientFilter(search);
        }
    }

    const onGeofenceNamesSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setGeofenceNamesFilter(undefined);
        } else {
            setGeofenceNamesFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }

    const filterRecipients = (recipients: Array<AccountContact>) => {
        let filteredRecipients = recipients

        if (recipientFilter) {
            let lowerCaseFilter = recipientFilter.toLowerCase();
            filteredRecipients = recipients.filter(recipient => {
                if (recipient) {
                    // Check for recipient properties and filter accordingly

                    if (recipient.email.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false; // Return false for recipient that don't match the filter
            });
        }

        return filteredRecipients;
    }

    const filterGeofences = (geofences: Array<Geofence> | null) => {
        let filteredGeofences = geofences

        if (geofences == null) {
            return [];
        } else {
            if (geofenceNamesFilter) {
                let lowerCaseFilter = geofenceNamesFilter.toLowerCase();
                filteredGeofences = geofences.filter(geofence => {
                    if (geofence.name) {
                        // Check for geofenceName properties and filter accordingly

                        if (geofence.name.toLowerCase().includes(lowerCaseFilter)) {
                            return true;
                        }
                    }
                    return false; // Return false for geofenceName that don't match the filter
                });
            }
        }

        return filteredGeofences;
    }

    const handleAlertCreateClick = async () => {

        const startTime = selectedStartTime.format("HH:mm");
        const endTime = selectedEndTime.format("HH:mm");

        if (startTime >= endTime) {
            // Show warning message to the user
            alert("Start time must be before end time");
            return; // Exit function if the condition is not met
        }

        await createGeofenceAlert(new GeofenceAlertRequest({
            id: 1,
            geofenceId: selectedGeofenceRows[0],
            alertPeriod: selectedAlertPeriod,
            startTime: startTime,
            endTime: endTime,
            alertOnEntry: alertOnEntry,
            alertOnExit: alertOnExit,
            isAlertForAllVehicles: setSelectedRows.length === assets?.length,
            recipientIds: recipients.filter(recipient => selectedRecipientsRows.includes(recipient.email)).map(recipient => recipient.id),
            vehicleIds: selectedRows,
        }))
            .then((geofenceAlerts) => {
                navigate('/alerts/geofence-alerts')
            })
            .catch((error) => {
                alert(t('alert_creation.failed'))
            })
    }

    const handleAlertUpdateClick = async () => {

        const startTime = selectedStartTime.format("HH:mm");
        const endTime = selectedEndTime.format("HH:mm");

        if (startTime >= endTime) {
            // Show warning message to the user
            alert("Start time must be before end time");
            return; // Exit function if the condition is not met
        }

        await updateGeofenceAlert(new GeofenceAlertRequest({
            id: selectedAlert.id,
            geofenceId: selectedGeofenceRows[0],
            alertPeriod: selectedAlertPeriod,
            startTime: startTime,
            endTime: endTime,
            alertOnEntry: alertOnEntry,
            alertOnExit: alertOnExit,
            isAlertForAllVehicles: setSelectedRows.length === assets?.length,
            recipientIds: recipients.filter(recipient => selectedRecipientsRows.includes(recipient.email)).map(recipient => recipient.id),
            vehicleIds: selectedRows,
        }))
            .then((geofenceAlerts) => {
                navigate('/alerts/geofence-alerts')
            })
            .catch((error) => {
                alert(t('alert_update.failed'))
            })
    }

    const handleCancel = () => {
        navigate('/alerts/geofence-alerts')
    }

    React.useEffect(() => {
        async function getAccountRecipients() {
            setLoadingRecipients(true)
            let contacts = await getCurrentAccountContacts() as AccountContact[];
            if (!contacts) {
                setRecipients([])
            } else {
                let selectedRecipients = contacts
                    .filter(value => {
                        return selectedAlert?.recipients.includes(value.email);
                    })
                    .map(value => value.email);

                setSelectedRecipientsRows(selectedRecipients)
                setRecipients(contacts)
            }
            setLoadingRecipients(false)
        }

        getAccountRecipients()

        async function getAssets() {

            let assetList = await getAllAssets() as Asset[];

            if (assetList) {
                let selectedRowsList = assetList.filter(asset => {
                    return selectedAlert?.vehicles.includes(asset.identificationMark);
                }).map(asset => asset.id);

                setSelectedRows(selectedRowsList ? selectedRowsList : [])
            }
        }

        getAssets()


        async function getGeofences() {

            let geofenceList = await getAllGeofences() as Geofence[];

            if (geofenceList) {
                let selectedGeofence = geofenceList.filter(geofence => {
                    return selectedAlert?.geofenceDto.id === geofence.id;
                }).map(geofence => geofence.id);

                setSelectedGeofenceRows(selectedGeofence ? selectedGeofence : [])
            }
        }

        getGeofences()
    }, []);

    return (
        <Box
            component="main"
            sx={{
                // padding: '10px',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("event.asset_selection")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.asset_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.asset_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={assetFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectAssetTable
                                multiselect={true}
                                loading={loadingAssets}
                                assets={filterAssets(assets || [])}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Days Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.event_days")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_days")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid>
                            <GeofenceAlertDayTable alertPeriod={selectedAlertPeriod} setAlertPeriod={setSelectedAlertPeriod}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Start & End Time Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.time_range")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_start_end_time")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("event.start_time")}
                                    value={selectedStartTime}
                                    ampm={false}
                                    onChange={(newStartTime) => {
                                        if (newStartTime != null) {
                                            setSelectedStartTime(newStartTime)
                                        } else {
                                            setSelectedStartTime(dayjs().startOf('date'))
                                        }
                                    }}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("event.end_time")}
                                    value={selectedEndTime}
                                    ampm={false}
                                    onChange={(newEndTime) => {
                                        if (newEndTime != null) {
                                            setSelectedEndTime(newEndTime)
                                        } else {
                                            setSelectedEndTime(dayjs().endOf('date'))
                                        }
                                    }}/>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Geofences Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.geofence_name")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_geofence_name")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.geofence_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={geofenceNamesFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onGeofenceNamesSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <GeofenceAlertGeofenceNamesTable loading={loadingGeofences} geofences={filterGeofences(geofences)}
                                                             selectedRows={selectedGeofenceRows}
                                                             setSelectedRows={setSelectedGeofenceRows}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Alert On Entry / Exit Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.entry_exit_alerting")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.entry_exit_alerting_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControlLabel control={<Checkbox
                                    checked={alertOnEntry}
                                    onChange={handleAlertOnEntryChange}
                                    color="primary"
                                />} label={t("event.alert_on_entry")}/>
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Checkbox
                                    checked={alertOnExit}
                                    onChange={handleAlertOnExitChange}
                                    color="primary"
                                />} label={t("event.alert_on_exit")}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Recipients Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.recipients")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_recipients")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.recipient_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={recipientFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onRecipientSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectRecipientTable
                                multiselect={true}
                                loading={loadingRecipients}
                                recipients={filterRecipients(recipients).map(recipient => recipient.email)}
                                selectedRows={selectedRecipientsRows}
                                setSelectedRows={setSelectedRecipientsRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedRows.length === 0 || selectedRecipientsRows.length === 0 || selectedGeofenceRows.length === 0 || (!alertOnEntry && !alertOnExit)}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? handleAlertUpdateClick : handleAlertCreateClick}
                            >
                                {isEdit ? t("button.save") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
