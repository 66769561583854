import {Box, Button, Grid, MenuItem, Paper, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";

import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FullView from "../../layouts/FullView";
import TextInput from "../../components/form/TextInput";
import {AssetClass, AssetType} from "../../hooks/assets/dto/Asset";
import SelectInput from "../../components/form/SelectInput";
import {isVehicle} from "../../utils/AssetUtils";
import ToggleInput from "../../components/form/ToggleInput";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs, {Dayjs} from "dayjs";
import {extractTime} from "../../utils/TimeUtils";
import {AssetGroup} from "../../hooks/assetGroups/dto/AssetGroup";
import {useAssetGroupApi} from "../../hooks/assetGroups/AssetGroups";
import {AssetUpdateRequest, useAssetApi} from "../../hooks/assets/Assets";
import useUserApi from "../../hooks/user/User";
import {SsoUser} from "../../hooks/user/dto/SsoUser";


export default function UpdateAssetPage() {
    return (
        <FullView content={<UpdateAssetPageContent/>}/>
    );
}

function UpdateAssetPageContent() {
    const location = useLocation();
    const selectedAsset = location.state.selectedAsset;

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();
    const {getAllAssetGroups} = useAssetGroupApi();
    const {updateAsset} = useAssetApi();
    const {getUserProfile} = useUserApi();
    const [user, setUser] = useState<SsoUser | undefined>(undefined);
    const [alias, setAlias] = useState<string>(selectedAsset.alias)
    const [notes, setNotes] = useState<string>(selectedAsset.notes)
    const [registration, setRegistration] = useState<string>(selectedAsset.identificationMark || '')
    const [groupName, setGroupName] = useState<string>(selectedAsset.groupName || '')
    const [assetType, setAssetType] = useState<string>(selectedAsset.type || '')
    const [assetClass, setAssetClass] = useState<string>(selectedAsset.vehicleClass || '')
    const [driver, setDriver] = useState<string>(selectedAsset.assetSettings?.driver || '')
    const [co2Output, setCo2Output] = useState<number>(selectedAsset.assetSettings?.co2Output)
    const [manHour1, setManHour1] = useState<number>(selectedAsset.assetSettings?.manHour1)
    const [manHour2, setManHour2] = useState<number>(selectedAsset.assetSettings?.manHour2)
    const [runningCost, setRunningCost] = useState<number>(selectedAsset.assetSettings?.runningCost)
    const [privateCost, setPrivateCost] = useState<number>(selectedAsset.assetSettings?.privateCost)
    const [customWorkingHours, setCustomWorkingHours] = useState<boolean>(selectedAsset.assetSettings?.customWorkingHours)
    const [weekdayStartTime, setWeekdayStartTime] = useState<Dayjs>(selectedAsset.assetSettings?.weekdayStart ? extractTime(selectedAsset.assetSettings?.weekdayStart) : dayjs().hour(0).minute(0).second(0));
    const [weekdayEndTime, setWeekdayEndTime] = useState<Dayjs>(selectedAsset.assetSettings?.weekdayStart ? extractTime(selectedAsset.assetSettings?.weekdayEnd) : dayjs().hour(23).minute(59).second(59));
    const [saturdayStartTime, setSaturdayStartTime] = useState<Dayjs>(selectedAsset.assetSettings?.saturdayStart ? extractTime(selectedAsset.assetSettings?.saturdayStart) : dayjs().hour(0).minute(0).second(0));
    const [saturdayEndTime, setSaturdayEndTime] = useState<Dayjs>(selectedAsset.assetSettings?.saturdayEnd ? extractTime(selectedAsset.assetSettings?.saturdayEnd) : dayjs().hour(23).minute(59).second(59));
    const [sundayStartTime, setSundayStartTime] = useState<Dayjs>(selectedAsset.assetSettings?.sundayStart ? extractTime(selectedAsset.assetSettings?.sundayStart) : dayjs().hour(0).minute(0).second(0));
    const [sundayEndTime, setSundayEndTime] = useState<Dayjs>(selectedAsset.assetSettings?.sundayEnd ? extractTime(selectedAsset.assetSettings?.sundayEnd) : dayjs().hour(23).minute(59).second(59));

    const assetTypeElements = Object.values(AssetType)
        .map((string) => ({
            key: `at-${string}`,
            value: string,
            label: t(`asset_types.${string.toLowerCase()}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Sort by translated string
        .map(({ key, value, label }) => (
            <MenuItem key={key} value={value}>
                {label}
            </MenuItem>
        ));

    const assetClassElements = Object.values(AssetClass)
        .map((string) => ({
            key: `ac-${string}`,
            value: string,
            label: t(`asset_classes.${string.toLowerCase()}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Sort by translated string
        .map(({ key, value, label }) => (
            <MenuItem key={key} value={value}>
                {label}
            </MenuItem>
        ));

    const [groupNameElements, setGroupNameElements] = useState<JSX.Element[]>([<MenuItem key={"gr-"} value={""}></MenuItem>]);

    const handleUpdateAsset = async () => {

        const selectedWeekdayStartTime = weekdayStartTime.format("HH:mm");
        const selectedWeekdayEndTime = weekdayEndTime.format("HH:mm");
        const selectedSaturdayStartTime = saturdayStartTime.format("HH:mm");
        const selectedSaturdayEndTime = saturdayEndTime.format("HH:mm");
        const selectedSundayStartTime = sundayStartTime.format("HH:mm");
        const selectedSundayEndTime = sundayEndTime.format("HH:mm");

        if (customWorkingHours) {
            if (selectedWeekdayStartTime >= selectedWeekdayEndTime) {
                alert(t("asset_messages.weekday_start_time_validation"));
                return;
            }
            if (selectedSaturdayStartTime >= selectedSaturdayEndTime) {
                alert(t("asset_messages.saturday_start_time_validation"));
                return;
            }

            if (selectedSundayStartTime >= selectedSundayEndTime) {
                alert(t("asset_messages.sunday_start_time_validation"));
                return;
            }
        }

        await updateAsset(new AssetUpdateRequest({
            alias: alias,
            label: groupName,
            driver: driver,
            type: assetType,
            vehicleClass: assetClass,
            co2Output: co2Output,
            manHour1: manHour1,
            manHour2: manHour2,
            runningCost: runningCost,
            privateCost: privateCost,
            customWorkingHours: customWorkingHours,
            weekdayStart: selectedWeekdayStartTime,
            weekdayEnd: selectedWeekdayEndTime,
            saturdayStart: selectedSaturdayStartTime,
            saturdayEnd: selectedSaturdayEndTime,
            sundayStart: selectedSundayStartTime,
            sundayEnd: selectedSundayEndTime,
            notes: notes
        }), selectedAsset.id)
            .then((asset) => {
                navigate('/assets')
            })
            .catch((error) => {
                alert(t('asset_messages.asset_update_failed'))
            })
    }

    React.useEffect(() => {
        async function getAssetGroups() {

            let user = await getUserProfile();
            setUser(user)

            let assetGroups = await getAllAssetGroups() as AssetGroup[];
            if (assetGroups) {
                setGroupNameElements(assetGroups.map(assetGroup => (
                    <MenuItem key={"gr-" + assetGroup.labelText} value={assetGroup.labelText}>{assetGroup.labelText}</MenuItem>)));

                const label = assetGroups.find(assetGroup => assetGroup.labelText === selectedAsset.groupName || '')?.labelText ?? '';
                setGroupName(label);
            }
        }

        getAssetGroups()
    }, []);

    const handleCo2OutputNumberChange = (value: string) => {
        const numberValue = parseFloat(value);
        setCo2Output(numberValue);
    };

    const handleManHour1NumberChange = (value: string) => {
        const numberValue = parseFloat(value);
        setManHour1(numberValue);
    };

    const handleManHour2NumberChange = (value: string) => {
        const numberValue = parseFloat(value);
        setManHour2(numberValue);
    };

    const handleRunningCostNumberChange = (value: string) => {
        const numberValue = parseFloat(value);
        setRunningCost(numberValue);
    };

    const handlePrivateCostNumberChange = (value: string) => {
        const numberValue = parseFloat(value);
        setPrivateCost(numberValue);
    };

    const handleCancel = () => {
        navigate('/assets')
    }

    return (

        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Registration */}
                {isVehicle(selectedAsset.type) &&
                    (
                        <Grid container>
                            <Grid item xs={12} md={4} lg={2}>
                                <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                            variant="title">{t("asset_page.asset_table.registration")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                                <Box sx={{justify: 'center', display: 'flex', alignItems: 'center', gap: '10px'}}>
                                    <TextInput
                                        editMode={false}
                                        required={false}
                                        label={''}
                                        placeholder={''}
                                        value={registration}
                                        onChange={setRegistration}
                                    />
                                    {selectedAsset.type !== AssetType.TRAILER &&
                                        selectedAsset.type !== AssetType.ASSET &&
                                        user?.roleList.some(role => role.name === "Customer Super Admin" || role.name === "Customer Admin") && (
                                            <Button

                                                variant="contained"
                                                color="primary"
                                                onClick={() => navigate('/change-registration', {state: {selectedAsset}})}
                                                sx={{
                                                    marginTop: '-50px',
                                                    height: '40px',
                                                    padding: '20px',
                                                    fontSize: '14px',
                                                    lineHeight: '0px',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                {t('button.change_reg')}
                                            </Button>
                                        )}
                                </Box>
                            </Grid>
                        </Grid>
                    )
                }

                {/* Alias */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.alias")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} required={false} label={''} placeholder={''}
                                   value={alias} onChange={setAlias}/>
                    </Grid>
                </Grid>

                {/* Notes */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.notes")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} required={false} label={''} placeholder={''}
                                   value={notes} onChange={setNotes}/>
                    </Grid>
                </Grid>


                {/* Group Name */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.group")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <SelectInput editMode={true} required={false} label={''}
                                     value={groupName?.toString()} items={groupNameElements}
                                     onChange={setGroupName}/>
                    </Grid>
                </Grid>

                {/* Assignee/Driver */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        {isVehicle(selectedAsset.type) ?
                            <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                        variant="title">{t("asset_page.asset_table.driver")}</Typography> :
                            <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                        variant="title">{t("asset_page.asset_table.assignee")}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} required={false} label={''} placeholder={''}
                                   value={driver} onChange={setDriver}/>
                    </Grid>
                </Grid>


                {/* Asset Type */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.type")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <SelectInput editMode={true} required={false} label={''}
                                     value={assetType?.toString() || ""} items={assetTypeElements}
                                     onChange={(newAssetType) => setAssetType(newAssetType)}/>
                    </Grid>
                </Grid>

                {/* Asset Class */}
                {isVehicle(selectedAsset.type) &&
                    (
                        <Grid container>
                            <Grid item xs={12} md={4} lg={2}>
                                <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                            variant="title">{t("asset_page.asset_table.class")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                                <SelectInput editMode={true} required={false} label={''}
                                             value={assetClass?.toString() || ""} items={assetClassElements}
                                             onChange={(newAssetClass) => setAssetClass(newAssetClass)}/>
                            </Grid>
                        </Grid>
                    )
                }

                {/* CO2 Output */}
                {isVehicle(selectedAsset.type) &&
                    (
                        <Grid container>
                            <Grid item xs={12} md={4} lg={2}>
                                <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                            variant="title">{t("asset_page.asset_table.co2_output")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={10} padding="20px">
                                <TextInput editMode={true} isNumber={true} required={false} label={''} placeholder={''}
                                           value={co2Output !== null ? co2Output?.toString() : ''} onChange={handleCo2OutputNumberChange}/>
                            </Grid>
                        </Grid>
                    )
                }

                {/* Man Hour 1 */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.man_hour_1")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true} required={false} label={''} placeholder={''}
                                   value={manHour1 !== null ? manHour1?.toString() : ''} onChange={handleManHour1NumberChange}/>
                    </Grid>
                </Grid>

                {/* Man Hour 2 */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.man_hour_2")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true} required={false} label={''} placeholder={''}
                                   value={manHour2 !== null ? manHour2?.toString() : ''} onChange={handleManHour2NumberChange}/>
                    </Grid>
                </Grid>

                {/* Running Cost */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.running_cost")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true} required={false} label={''} placeholder={''}
                                   value={runningCost !== null ? runningCost?.toString() : ''} onChange={handleRunningCostNumberChange}/>
                    </Grid>
                </Grid>

                {/* Private Cost */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.private_cost")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true} required={false} label={''} placeholder={''}
                                   value={privateCost !== null ? privateCost?.toString() : ''} onChange={handlePrivateCostNumberChange}/>
                    </Grid>
                </Grid>

                {/* Custom Working Hours */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_page.asset_table.custom_working_hours")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={1} padding="20px">
                        <ToggleInput mainText={''} selected={customWorkingHours}
                                     onToggle={() => {
                                         setCustomWorkingHours(!customWorkingHours)
                                     }}/>
                    </Grid>
                </Grid>

                {/* Weekday Working Hours */}
                {customWorkingHours &&
                    (
                        <Grid container>
                            <Grid item xs={12} md={4} lg={2}>
                                <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                            variant="title">{t("asset_page.asset_table.weekday_working_hours")}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={5} padding="20px">
                                <TimePicker label={t("asset_page.asset_table.weekday_start")} value={weekdayStartTime}
                                            ampm={false}
                                            onChange={(newStartTime) => {
                                                if (newStartTime != null) {
                                                    setWeekdayStartTime(newStartTime)
                                                } else {
                                                    setWeekdayStartTime(dayjs().startOf('date'))
                                                }
                                            }}/>
                            </Grid>
                            <Grid item xs={12} md={4} lg={5} padding="20px">
                                <TimePicker label={t("asset_page.asset_table.weekday_end")} value={weekdayEndTime}
                                            ampm={false}
                                            onChange={(newEndTime) => {
                                                if (newEndTime != null) {
                                                    setWeekdayEndTime(newEndTime)
                                                } else {
                                                    setWeekdayEndTime(dayjs().endOf('date'))
                                                }
                                            }}/>
                            </Grid>
                        </Grid>
                    )
                }

                {/* Saturday Working Hours */}
                {customWorkingHours &&
                    (
                        (
                            <Grid container>
                                <Grid item xs={12} md={4} lg={2}>
                                    <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                                variant="title">{t("asset_page.asset_table.saturday_working_hours")}</Typography>
                                </Grid>
                                <Grid item xs={12} md={4} lg={5} padding="20px">
                                    <TimePicker label={t("asset_page.asset_table.saturday_start")} value={saturdayStartTime}
                                                ampm={false}
                                                onChange={(newStartTime) => {
                                                    if (newStartTime != null) {
                                                        setSaturdayStartTime(newStartTime)
                                                    } else {
                                                        setSaturdayStartTime(dayjs().startOf('date'))
                                                    }
                                                }}/>
                                </Grid>
                                <Grid item xs={12} md={4} lg={5} padding="20px">
                                    <TimePicker label={t("asset_page.asset_table.saturday_end")} value={saturdayEndTime}
                                                ampm={false}
                                                onChange={(newEndTime) => {
                                                    if (newEndTime != null) {
                                                        setSaturdayEndTime(newEndTime)
                                                    } else {
                                                        setSaturdayEndTime(dayjs().endOf('date'))
                                                    }
                                                }}/>
                                </Grid>
                            </Grid>
                        )
                    )
                }

                {/* Sunday Working Hours */}
                {customWorkingHours &&
                    (
                        (
                            <Grid container>
                                <Grid item xs={12} md={4} lg={2}>
                                    <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                                variant="title">{t("asset_page.asset_table.sunday_working_hours")}</Typography>
                                </Grid>
                                <Grid item xs={12} md={4} lg={5} padding="20px">
                                    <TimePicker label={t("asset_page.asset_table.sunday_start")} value={sundayStartTime}
                                                ampm={false}
                                                onChange={(newStartTime) => {
                                                    if (newStartTime != null) {
                                                        setSundayStartTime(newStartTime)
                                                    } else {
                                                        setSundayStartTime(dayjs().startOf('date'))
                                                    }
                                                }}/>
                                </Grid>
                                <Grid item xs={12} md={4} lg={5} padding="20px">
                                    <TimePicker label={t("asset_page.asset_table.sunday_end")} value={sundayEndTime}
                                                ampm={false}
                                                onChange={(newEndTime) => {
                                                    if (newEndTime != null) {
                                                        setSundayEndTime(newEndTime)
                                                    } else {
                                                        setSundayEndTime(dayjs().endOf('date'))
                                                    }
                                                }}/>
                                </Grid>
                            </Grid>
                        )
                    )
                }

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={handleUpdateAsset}
                            >
                                {t("button.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
