import React, { createContext, useState, ReactNode, useContext } from 'react';
import {Asset, AssetStatus} from '../hooks/assets/dto/Asset';


interface FilterAssetContextProps {
  filteredAssets?: Asset[];
  setFilteredAssets: (assets: Asset[]) => void;
}

// Create the context
export const FilterAssetContext = createContext<FilterAssetContextProps>({
    filteredAssets: undefined,
    setFilteredAssets: () => { },
});

// Create the provider component
export const FilterAssetProvider = ({ children }: { children: ReactNode }) => {
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);

  const setFilteredAssetsWithoutInactive = (assets: Asset[]) => {
    const filtered = assets.filter(asset => asset.status !== AssetStatus.INACTIVE);
    setFilteredAssets(filtered);
  };

  return (
    <FilterAssetContext.Provider value={{ filteredAssets, setFilteredAssets: setFilteredAssetsWithoutInactive }}>
      {children}
    </FilterAssetContext.Provider>
  );
};

// Custom hook for consuming the context
export const useFilterAsset = () => {
  const context = useContext(FilterAssetContext);
  if (!context) {
    throw new Error('useFilterAsset must be used within a FilterAssetProvider');
  }
  return context;
};
