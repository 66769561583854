import {Box} from "@mui/material";
import React, {useContext, useEffect, useRef, useState,} from "react";
import {Map} from "leaflet";
import {useAssetWebSocket} from "../../hooks/assets/Assets";
import {useGeofencesApi} from "../../hooks/geofences/Geofences";
import {useSearchParams} from "react-router-dom";
import {Geofence} from "../../hooks/geofences/dto/Geofence";
import {FilterAssetContext} from "../../providers/FilterAssetProvider";
import {Asset} from "../../hooks/assets/dto/Asset";
import AssetsList from "./components/asset-list/AssetsList";
import BaseMap from "../../components/map/BaseMap";

export enum LayerControlType {
    MAP = 'MAP',
    LABEL = 'LABEL',
}

function MapPage(this: any) {
    const mapRef = useRef<Map>(null);
    const {assets: rawAssets} = useAssetWebSocket();
    const [assets, setAssets] = useState<Asset[]>([]);
    const {getGeofenceById} = useGeofencesApi(true);

    const [focusGeofence, setFocusGeofence] = useState<Geofence | undefined>(undefined);

    const [searchParams, setSearchParams] = useSearchParams();

    const {filteredAssets, setFilteredAssets} = useContext(FilterAssetContext);

    useEffect(() => {
        if (rawAssets) {
            const convertedAssets = Object.keys(rawAssets).map(key => rawAssets[key]);
            setAssets(convertedAssets);
        }
    }, [rawAssets]);

    useEffect(() => {
        if (assets?.length) {
            setFilteredAssets(assets);
        }
    }, [assets]);

    useEffect(() => {
        let locationName = searchParams.get("locationName");
        let locationLatitude = searchParams.get("locationLatitude");
        let locationLongitude = searchParams.get("locationLongitude");
        let selectedGeofenceId = searchParams.get("selectedGeofence");

        if (mapRef.current === null) {
            return;
        }

        if (locationName !== null && locationLatitude !== null && locationLongitude !== null) {
            const lat = parseFloat(locationLatitude);
            const lng = parseFloat(locationLongitude);
            mapRef.current?.setView([lat, lng], 15);
            searchParams.delete("locationName");
            searchParams.delete("locationLatitude");
            searchParams.delete("locationLongitude");
            setSearchParams(searchParams);
        } else if (selectedGeofenceId !== null) {
            getGeofenceById(selectedGeofenceId)
                .then((geofence: Geofence) => {
                    setFocusGeofence(geofence);
                });
            searchParams.delete("selectedGeofence");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams, getGeofenceById]);

    return (
        <>
            <Box sx={{minWidth: "350px", padding: "0"}}>
                <AssetsList
                    mapref={mapRef}
                    assets={assets}
                />
            </Box>
            <BaseMap
                mapRef={mapRef}
                focusGeofence={focusGeofence}
                controls={{
                    keepInView: true,
                    labels: true,
                    clustering: true,
                    congestionZones:true,
                    geofences:true
                }}
                assets={filteredAssets}
            />
        </>
    );
}

export default MapPage;
