class EnvironmentApplicationProperties {
    scanEnabled: boolean;
    holdingPage: boolean
    authProviderEndpoint: string;
    authProviderLogoutEndpoint: string;
    changePasswordEndpoint: string;
    authClientId: string;
    authRedirect: string;
    platformApiEndpoint: string;
    googleMapsApiKey: string;
    ga4Id: string;
    activationUrl: string;
    featureFlags: FeatureFlags;

    constructor(props: {
        scanEnabled: boolean;
        holdingPage: boolean
        authProviderEndpoint: string;
        authProviderLogoutEndpoint: string;
        changePasswordEndpoint: string
        authClientId: string;
        authRedirect: string;
        platformApiEndpoint: string;
        googleMapsApiKey: string;
        ga4Id: string;
        activationUrl: string;
        featureFlags: FeatureFlags;
    }) {
        this.scanEnabled = props.scanEnabled;
        this.holdingPage = props.holdingPage;
        this.authProviderEndpoint = props.authProviderEndpoint;
        this.authProviderLogoutEndpoint = props.authProviderLogoutEndpoint;
        this.changePasswordEndpoint = props.changePasswordEndpoint;
        this.authClientId = props.authClientId;
        this.authRedirect = props.authRedirect;
        this.platformApiEndpoint = props.platformApiEndpoint;
        this.googleMapsApiKey = props.googleMapsApiKey;
        this.ga4Id = props.ga4Id;
        this.activationUrl = props.activationUrl;
        this.featureFlags = props.featureFlags;
    }
}

type FeatureFlags = {
    assetManagement: boolean;
    wasteAlerts: boolean;
    fuelAlerts: boolean;
    generatorReport: boolean;
    temperatureAlerts: boolean;
}

class Configurations {
    local: EnvironmentApplicationProperties;
    local_staging: EnvironmentApplicationProperties;
    staging: EnvironmentApplicationProperties;
    uat: EnvironmentApplicationProperties;
    prod: EnvironmentApplicationProperties;

    constructor(props: {
        local: EnvironmentApplicationProperties;
        local_staging: EnvironmentApplicationProperties;
        staging: EnvironmentApplicationProperties;
        uat: EnvironmentApplicationProperties;
        prod: EnvironmentApplicationProperties;
    }) {
        this.local = props.local;
        this.local_staging = props.local_staging;
        this.staging = props.staging;
        this.uat = props.uat;
        this.prod = props.prod;
    }
}

class ApplicationProperties {
    profile: string;
    configurations: Configurations;

    constructor(props: { profile: string; configurations: Configurations }) {
        this.profile = props.profile;
        this.configurations = props.configurations;
    }
}

export default function PropertiesLoader () {
    const props = require("./properties.json") as ApplicationProperties;
    switch (props.profile) {
        case "staging":
            return props.configurations.staging;
        case "uat":
            return props.configurations.uat;
        case "prod":
            return props.configurations.prod;
        case "local_staging":
            return props.configurations.local_staging;
        default:
            return props.configurations.local;
    }
}
