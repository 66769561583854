import {useContext, useEffect, useState} from 'react';
import {AccountGeneralSettingsUpdate} from "./dto/AccountGeneralSettingsUpdate";
import {AccountGeneralSettings} from './dto/AccountGeneralSettings';
import dayjs from "dayjs";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

export enum FeaturePackage {
    LITE = "LITE",
    CORE = "CORE",
    PLUS = "PLUS",
    LEGACY_LITE = "LEGACY_LITE",
    LEGACY_FULL = "LEGACY_FULL",
    EXPIRED = "EXPIRED"
}
export class Account {
    id: number;
    name: string;
    salesforceId: string;
    featurePackage: FeaturePackage;
    country: string;
    hasAccessPackageUpgrade: boolean;
    generalSettings: AccountGeneralSettings;
    caSunsetStatus?: string;

    constructor(props: {
        id: number;
        name: string;
        salesforceId: string;
        featurePackage: FeaturePackage;
        country: string;
        hasAccessPackageUpgrade: boolean;
        generalSettings: AccountGeneralSettings;
        caSunsetStatus?: string;

    }) {
        this.id = props.id;
        this.name = props.name;
        this.salesforceId = props.salesforceId;
        this.featurePackage = props.featurePackage;
        this.country = props.country;
        this.hasAccessPackageUpgrade = props.hasAccessPackageUpgrade;
        this.generalSettings = props.generalSettings;
        this.caSunsetStatus = props.caSunsetStatus;
    }
}

export class AccountContact {
    id: number;
    email: string;
    username: string;
    constructor(props: {
        id: number;
        email: string;
        username: string;
    }) {
        this.id = props.id;
        this.email = props.email;
        this.username = props.username;
    }
}

const useAccountApi = (shouldPoll?: boolean, pollFrequency?: number) => {
    const {platformApiFetch, checkOk} = usePlatformApiFetch();
    const [account, setAccount] = useState<Account | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [accountContacts, setAccountContacts] = useState<AccountContact[] | null>(null);

    const auth: IAuthContext = useContext(AuthContext)

    useEffect(() => {
        refreshAccount();
        getCurrentAccountContacts()

        if (shouldPoll) {
            const interval = setInterval(() => refreshAccount(true), pollFrequency ?? 30000);
            return () => clearInterval(interval);
        }
    }, [auth.token]);

    const refreshAccount = async (disableLoading?: boolean) => {
        if(!auth.token) {
            return;
        }
        try {
            setLoading(!disableLoading);
            const refreshedAccount = await getAccount();
            if(refreshedAccount) {
                setAccount(refreshedAccount);
            }
        } catch (err) {
            setError("Error fetching account");
        } finally {
            setLoading(false);
        }
    }

    const getAccount = async (): Promise<Account | undefined> => {
        if (!auth.token) {
            return undefined;
        }
        try {
            const response = await platformApiFetch('account/v1/accounts/me', 'get', auth.token);
            if (response.status !== 200) {
                console.error("Error fetching account: " + response.status);
                return undefined;
            }
            return await response.json() as Account;
        } catch (err) {
            setError("Error fetching account");
            return undefined;
        }
    }

    const getCurrentAccountContacts = async (): Promise<AccountContact[]> => {
        if(!auth.token) {
            return [];
        }
        const response = await platformApiFetch('account/v1/accounts/contacts', 'get', auth.token);
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const result = await response.json() as AccountContact[];
        setAccountContacts(result);
        return result;
    }

    const getAccountContacts = async (id: number) => {
        const response = await platformApiFetch(`account/v1/accounts/${id}/contacts`, 'get', auth.token);
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const result = await response.json() as AccountContact[];
        return result;
    }

    const getAccounts = async () => {
        const response = await platformApiFetch('account/v1/accounts/', 'get', auth.token);
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        return await response.json() as Account[];
    }

    const getPaginatedAccounts = async (page = 0, size = 10, search = '') => {
        const response = await platformApiFetch(`account/v1/accounts/paginated?page=${page}&pageSize=${size}&search=${search}`, 'get', auth.token);
        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }
        const responseData = await response.json();
        return {
            accounts: responseData.content as Account[],
            totalPages: responseData.totalPages as number,
            totalElements: responseData.totalElements as number,
            size: responseData.size as number,
            number: responseData.number as number
        };
    }

    const getAccountGeneralSettings = async (): Promise<AccountGeneralSettings | undefined> => {
        if(!auth.token) {
            return undefined;
        }
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/general-settings/', "get", auth.token)
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => {
                    return new AccountGeneralSettings({
                        hoursMfStart: data.hoursMfFrom ? dayjs(data.hoursMfFrom, 'HH:mm:ss') : null,
                        hoursMfEnd: data.hoursMfTo ? dayjs(data.hoursMfTo, 'HH:mm:ss') : null,
                        hoursSatStart: data.hoursSatFrom ? dayjs(data.hoursSatFrom, 'HH:mm:ss') : null,
                        hoursSatEnd: data.hoursSatTo ? dayjs(data.hoursSatTo, 'HH:mm:ss') : null,
                        hoursSunStart: data.hoursSunFrom ? dayjs(data.hoursSunFrom, 'HH:mm:ss') : null,
                        hoursSunEnd: data.hoursSunTo ? dayjs(data.hoursSunTo, 'HH:mm:ss') : null,
                        useMetric: data.useMetric !== false
                    });
                })
                .then((generalSettings) => resolve(generalSettings))
                .catch((error) => resolve(undefined));
        });
    };
    const updateAccountGeneralSettings = async (updateValues: AccountGeneralSettingsUpdate) => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/general-settings/', "put", auth.token, JSON.stringify(updateValues))
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => data as AccountGeneralSettings)
                .then((generalSettings) => resolve(generalSettings))
                .catch((error: Error) => reject(error));
        });
    }

    return { account, accountContacts,error, loading, getAccount, refreshAccount, getCurrentAccountContacts, getAccountContacts, getAccounts, getPaginatedAccounts, getAccountGeneralSettings,  updateAccountGeneralSettings };
};
export default useAccountApi;
