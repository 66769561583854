import {ArrowRight, Circle, Clock} from "lucide-react"

import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import {TimelineBlock} from "../../hooks/assets/dto/TimelineBlock";
import {useContext} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {getStatusColor} from "../../utils/ColorUtils";
import {useTranslation} from "react-i18next";
import {AssetStatus} from "../../hooks/assets/dto/Asset";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

interface TimelineProps {
    blocks: TimelineBlock[] | undefined
    selectJourneyAtDateTime?: (dateTime: Date) => void
    className?: string
}

function getPercentage(date: Date): number {
    return (date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()) / 864
}

export function InteractiveTimeline({blocks, selectJourneyAtDateTime, className}: Readonly<TimelineProps>) {

    const { colorPalette } = useContext(UserPreferencesContext);
    const {t} = useTranslation();

    // Get unique states from blocks for legend
    const uniqueStatuses = Array.from(new Set(blocks?.map((block) => block.status)))

    return (
        <div className={cn("h-[100px] flex flex-col gap-1 p-2", className)}>
            {/* Legend */}
            <div className="flex flex-wrap gap-2">
                {uniqueStatuses.map((status) => (
                    <div key={status} className="flex items-center gap-2">
                        <Circle className={`h-3 w-3 ${getStatusColor(status, colorPalette, "fill")}`}/>
                        <span className="text-sm text-baseContentAlt">{t(`asset_status.${status.toLowerCase()}`)}</span>
                    </div>
                ))}
            </div>

            {/* Timeline header */}
            <div className="flex items-center justify-between text-sm text-muted-foreground">
                <span>00:00</span>
                <span>23:59</span>
            </div>

            {/* Timeline bar */}
            <div className="relative h-6 rounded-md bg-base-300">
                {blocks === undefined && (
                    <div className="h-full w-full bg-base-200 text-baseContent">
                        <p className={"w-full text-center text-sm"}>
                            {t(`route_replay.loading`)}
                        </p>
                    </div>
                )}
                {blocks?.length === 0 && (
                    <div className="h-full w-full bg-base-200 text-baseContent">
                        <p className={"w-full text-center text-sm"}>
                            {t(`route_replay.no_data`)}
                        </p>
                    </div>
                )}


                {blocks?.map((block, index) => (
                    <TimelineUiBlock
                        key={block.startTime.toLocaleTimeString()}
                        block={block}
                        selectJourneyAtDateTime={selectJourneyAtDateTime}
                        className={`${index === 0 && "rounded-l-md"} ${index === blocks.length - 1 && "rounded-r-md"}`}
                    />
                ))}
            </div>
        </div>
    )
}

function TimelineUiBlock({block, selectJourneyAtDateTime, className}: Readonly<{
    block: TimelineBlock,
    selectJourneyAtDateTime?: (dateTime: Date) => void
    className?: string,
}>) {
    const { colorPalette } = useContext(UserPreferencesContext);
    return (
        <div
            className={`has-tooltip absolute h-full ${getStatusColor(block.status, colorPalette, "bg")} ${className}`}
            style={{
                left: `${getPercentage(block.startTime)}%`,
                width: `${getPercentage(block.endTime) - getPercentage(block.startTime)}%`,
            }}
        >
            {selectJourneyAtDateTime && <TimelineTooltip block={block} selectJourneyAtDateTime={selectJourneyAtDateTime}/>}
        </div>
    )
}

function TimelineTooltip({block, selectJourneyAtDateTime}: Readonly<{
    block: TimelineBlock,
    selectJourneyAtDateTime: (dateTime: Date) => void
}>) {
    const {t} = useTranslation();
    return (
        <span className='tooltip w-64 rounded p-1 bg-base-200 shadow-2xl bottom-full'>
                            <div className="p-3 text-xs text-baseContent">
                                <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1">
                                    <div className="font-semibold text-primary">{t(`route_replay.status`)}</div>
                                    <div>{t(`asset_status.${block.status.toLowerCase()}`)}</div>
                                    <div className="font-semibold text-primary">{t(`route_replay.from`)}</div>
                                    <div
                                        className="line-clamp-2 text-ellipsis hover:line-clamp-none">{block.startLocation}</div>
                                    <div className="font-semibold text-primary">{t(`route_replay.to`)}</div>
                                    <div
                                        className="line-clamp-2 text-ellipsis hover:line-clamp-none">{block.endLocation}</div>
                                    <div className="col-span-2 mt-2 flex items-center text-xs text-gray-500">
                                        <Clock className="mr-1 h-3 w-3"/>
                                        <span>{block.startTime.toLocaleTimeString()}</span>
                                        <ArrowRight className="mx-1 h-3 w-3"/>
                                        <span>{block.endTime.toLocaleTimeString()}</span>
                                    </div>
                                    {block.status !== AssetStatus.STOPPED && <button onClick={() => selectJourneyAtDateTime(block.startTime)}
                                            className={"text-sm text-red-500 underline"}>{t(`route_replay.view_journey`)}</button>}
                                </div>
                            </div>
                        </span>
    )
}

