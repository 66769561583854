import {Box, List, Typography} from "@mui/material";
import {Asset} from "../../../../hooks/assets/dto/Asset";
//Icons
import ListSectionHeading from "../../../../components/leftpanel/ListSectionHeading";

import React, {useContext} from "react";
import {Journey} from "../../../../hooks/assets/dto/Journey";
import RouteReplayJourneyCard from "./RouteReplayJourneyCard";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import LeftPanelButtonBar from "../../../../components/leftpanel/LeftPanelButtonBar";
import {useTranslation} from "react-i18next";
import {colors} from "../../../../themes/AppTheme";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import ReactGA from "react-ga4";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";

interface RouteReplayJourneySelectProps {
    asset: Asset;
    onBackPressed: Function;
    journeys: Journey[] | undefined;
    setJourney: React.Dispatch<React.SetStateAction<Journey | undefined>>;
    selectedDate: Dayjs;
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}


function NoJourneysMessage() {
    const {t} = useTranslation();

    return (
        <Typography
            sx={{display: "block", width: "100%"}}
            component="span"
            variant="cardbody"
            color="text.primary"
            padding={"10px"}
        >
            {t("route_replay.no_journeys_found")}
        </Typography>
    )
}

export default function RouteReplayJourneySelect(props: Readonly<RouteReplayJourneySelectProps>) {
    const {t} = useTranslation();
    const {languageCode} = useContext(UserPreferencesContext)

    function handleBackPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        ReactGA.event("RT2-RouteReplay-BacktoVehicles");
        props.setSelectedDate(dayjs().startOf('date'));
        props.onBackPressed();
    }

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-48px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>
            <List
                dense={true}
                sx={{width: "100%", maxWidth: 360, bgcolor: "background.paper"}}
            >
                <LeftPanelButtonBar onBackPressed={handleBackPress}/>
                <Box sx={{width: '100%'}}>
                    <Typography
                        sx={{display: "block", width: "100%"}}
                        component="div"
                        variant="header"
                        padding={"10px"}
                    >
                        {props.asset.alias || props.asset.identificationMark}
                    </Typography>
                </Box>


                <Box sx={{width: '100%'}}>
                    <ListSectionHeading title={t("route_replay.journeys")}/>
                    <Box sx={{width: '100%', padding: '10px'}}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={languageCode}
                        >
                            <DatePicker
                                sx={{

                                    width: '100%',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: colors.ram_purple,
                                }}

                                disableFuture
                                label={t('timeline.datepicker_label')}
                                value={props.selectedDate}
                                format="DD/MM/YYYY"
                                slotProps={{textField: {size: 'small'}}}
                                onChange={(newDate) => {
                                    if (newDate != null) {
                                        ReactGA.event("RT2-RouteReplay-SelectDate");
                                        props.setSelectedDate(newDate)
                                    } else {
                                        props.setSelectedDate(dayjs().startOf('date'))
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    {!props.journeys ? (<LoadingSpinner/>) : (<></>)}

                    {props.journeys?.length === 0 ? <NoJourneysMessage/>
                        : props.journeys?.map((journey) => {
                                return (<RouteReplayJourneyCard key={journey.id} journey={journey}
                                                                clickedJourney={(journey: Journey) => {
                                                                    props.setJourney(journey)
                                                                }}/>)
                            }
                        )}
                </Box>
            </List>
        </Box>

    );
}
