import {AssetEvent, Speed} from "../hooks/assets/dto/Asset";
import {mapToVehicleEventTranslation} from "./TranslationMapper";

export function getTranslatedEventName(event: AssetEvent, t: (key: string) => string) {
    if(event.customEventName) {
        return event.customEventName;
    }

    const eventTranslationKey = mapToVehicleEventTranslation(event.type.toString());
    return eventTranslationKey ? t('vehicle_event.' + eventTranslationKey) : event.type;
}


export function isWarningEvent(speed: Speed | undefined, speedLimit: Speed | undefined, eventType: string | undefined) {
    return isSpeeding(speed, speedLimit) || isHarshEvent(eventType);
}

function isSpeeding(speed: Speed | undefined, speedLimit: Speed | undefined) {
    return speed && speedLimit && speedLimit.value !== 0 && speed.value > speedLimit.value;
}

function isHarshEvent(eventType: string | undefined) {
    return eventType?.includes("HARSH");
}
