import {useContext, useEffect, useState} from "react";
import { Polygon } from 'geojson';
import usePlatformApiFetch from "../shared/PlatformApiFetch";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

export class Zone {
    id: string;
    name: string;
    polygon: Polygon;

    constructor(props: {
        id: string;
        name: string;
        polygon: Polygon;
    }) {
        this.id = props.id;
        this.name = props.name;
        this.polygon = props.polygon;
    }
}

function mapToZoneArray(json: any) {
    return json.map((zone: any) => {
        return new Zone({
            id: zone.id,
            name: zone.name,
            polygon: zone.polygon as Polygon
        })
    })
}

export function useCongestionZonesApi(shouldPoll?: boolean, pollFrequency?: number) {
    const auth: IAuthContext = useContext(AuthContext);
    const { platformApiFetch, checkOk } = usePlatformApiFetch();

    const [zones, setZones] = useState<Zone[] | null>(null);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllCongestionZones = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/congestionzones/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToZoneArray(json))
                .then(loadedZones => {
                    setZones(loadedZones)
                    setLoading(false)
                    resolve(loadedZones)
                })
                .catch((error:Error) => {
                    console.error("Error getting congestion zones: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }


    useEffect(() => {
        getAllCongestionZones();
        if (shouldPoll) {
            const interval = setInterval(getAllCongestionZones, pollFrequency ?? 30000);
            return () => clearInterval(interval);
        }
    }, [auth.token]);

    return { loading, error, zones, getAllCongestionZones }
}
