import { scan } from "react-scan";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import SecuredApp from "./SecuredApp";
import PropertiesLoader from "./properties/PropertiesLoader";
import Holding from "./holding/Holding";

import ReactGA from "react-ga4";

const properties = PropertiesLoader();

scan({
  enabled: properties.scanEnabled,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const search = window.location.search;
const params = new URLSearchParams(search);
const bypass = params.get('bypass');



root.render(
  <React.StrictMode>
    {(properties.holdingPage && !bypass) ?
      (<Holding />)
      :
      (<SecuredApp />)}
  </React.StrictMode>
);

ReactGA.initialize(properties.ga4Id);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
