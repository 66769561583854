import {Box, Button, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../../utils/AssetArraySorting";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import useAccountApi, {AccountContact} from "../../../hooks/account/Account";
import {filterAssetsBySearch} from "../../../utils/AssetArrayFiltering";
import {AlertPeriod, TemperatureAlertRequest, useTemperatureAlertsApi} from "../../../hooks/alerts/TemperatureAlerts";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";
import SelectRecipientTable from "../../../components/table/shared/SelectRecipientTable";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs, {Dayjs} from "dayjs";
import {extractTime} from "../../../utils/TimeUtils";
import TemperatureAlertDayTable from "./components/TemperatureAlertDayTable";

export default function TemperatureAlertsEditorPage() {
    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedAlert !== null && location.state.selectedAlert !== undefined;
    const selectedAlert = isEdit ? location.state.selectedAlert : undefined;

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();

    const [minimumTemperature, setMinimumTemperature] = useState<number | undefined>(selectedAlert?.minimumTemperature ?? undefined);
    const [maximumTemperature, setMaximumTemperature] = useState<number | undefined>(selectedAlert?.maximumTemperature ?? undefined);
    const [temperatureSensor, setTemperatureSensor] = useState<string | undefined>(selectedAlert?.selectedSensor);
    const [sensorOne, setSensorOne] = useState<boolean>(selectedAlert?.selectedSensor === 'One' || selectedAlert?.selectedSensor === 'Both');
    const [sensorTwo, setSensorTwo] = useState<boolean>(selectedAlert?.selectedSensor === 'Two' || selectedAlert?.selectedSensor === 'Both');
    const [selectedAlertPeriod, setSelectedAlertPeriod] = useState<AlertPeriod>(isEdit ? selectedAlert?.alertPeriod : AlertPeriod.ALL);
    const [selectedStartTime, setSelectedStartTime] = useState<Dayjs>(isEdit && selectedAlert ? extractTime(selectedAlert.startTime) : dayjs().startOf('date'));
    const [selectedEndTime, setSelectedEndTime] = useState<Dayjs>(isEdit && selectedAlert ? extractTime(selectedAlert.endTime) : dayjs().startOf('date'));


    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const [recipientFilter, setRecipientFilter] = React.useState<string | undefined>(undefined);
    const {sort} = useContext(FilterSortContext);

    const {assets, getAllAssets, loading: loadingAssets, hasGenerator} = useAssetApi({shouldLoadAll: true});
    const {getCurrentAccountContacts} = useAccountApi();
    const {updateTemperatureAlert, createTemperatureAlert} = useTemperatureAlertsApi()

    const [selectedRows, setSelectedRows] = useState<number[]>(selectedAlert?.vehicleIds || []);
    const [recipients, setRecipients] = useState<AccountContact[]>([]);
    const [selectedRecipientsRows, setSelectedRecipientsRows] = useState<string[]>(selectedAlert?.recipientIds || []);
    const handleMinimumTemperatureChange = (value: string) => {
        if (value === '') {
            setMinimumTemperature(undefined);
        } else {
            const numberValue = parseFloat(value);
            setMinimumTemperature(numberValue);
        }
    };

    function validateTemperatureAlert(minimumTemperature: number | undefined, maximumTemperature: number | undefined, sensorOne: boolean, sensorTwo: boolean): boolean {
        if (minimumTemperature === undefined && maximumTemperature === undefined) {
            alert("At least one of minimum or maximum temperature must be specified");
            return false;
        }
        if (minimumTemperature !== undefined && maximumTemperature !== undefined && minimumTemperature >= maximumTemperature) {
            alert("Minimum temperature must be less than maximum temperature");
            return false;
        }
        if (!sensorOne && !sensorTwo) {
            alert("At least one sensor must be selected");
            return false;
        }
        if (selectedStartTime >= selectedEndTime) {
            alert("Start time must be before end time");
            return false;
        }
        return true;
    }


    const handleMaximumTemperatureChange = (value: string) => {
        const numberValue = parseFloat(value);
        setMaximumTemperature(numberValue);
    };

    const handleTemperatureSensorChange = (sensor: 'ONE' | 'TWO') => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (sensor === 'ONE') {
            setSensorOne(event.target.checked);
        } else {
            setSensorTwo(event.target.checked);
        }

        if (event.target.checked) {
            if (sensorOne && sensorTwo) {
                setTemperatureSensor('BOTH');
            } else if (sensorOne) {
                setTemperatureSensor('ONE');
            } else if (sensorTwo) {
                setTemperatureSensor('TWO');
            }
        } else {
            if (!sensorOne && !sensorTwo) {
                setTemperatureSensor(undefined);
            } else if (sensorOne) {
                setTemperatureSensor('ONE');
            } else if (sensorTwo) {
                setTemperatureSensor('TWO');
            }
        }
    };

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }
    const onRecipientSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setRecipientFilter(undefined);
        } else {
            setRecipientFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }

    const filterRecipients = (recipients: Array<AccountContact>) => {
        let filteredRecipients = recipients

        if (recipientFilter) {
            let lowerCaseFilter = recipientFilter.toLowerCase();
            filteredRecipients = recipients.filter(recipient => {
                if (recipient) {
                    if (recipient.email.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false;
            });
        }

        return filteredRecipients;
    }

    const btnUpdateTemperatureAlert = async () => {
        const startTime = selectedStartTime.format("HH:mm");
        const endTime = selectedEndTime.format("HH:mm");

        if (!validateTemperatureAlert(minimumTemperature, maximumTemperature, sensorOne, sensorTwo)) {
            return;
        }

        await updateTemperatureAlert(new TemperatureAlertRequest({
            id: selectedAlert.id,
            accountId: selectedAlert.accountId,
            minimumTemperature: minimumTemperature,
            maximumTemperature: maximumTemperature,
            selectedSensor: sensorOne && sensorTwo ? 'BOTH' : sensorOne ? 'ONE' : sensorTwo ? 'TWO' : '',
            alertPeriod: selectedAlertPeriod,
            startTime: startTime,
            endTime: endTime,
            alertForAllVehicles: false,
            recipientIds: recipients.filter(recipient => selectedRecipientsRows.includes(recipient.email)).map(recipient => recipient.id),
            vehicleIds: selectedRows,
        }), selectedAlert.id)
            .then(() => {
                navigate('/alerts/temperature-alerts')
            })
            .catch(() => {
                alert(t('alert_update.failed'))
            })
    }

    const btnCreateTemperatureAlert = async () => {
        const startTime = selectedStartTime.format("HH:mm");
        const endTime = selectedEndTime.format("HH:mm");

        if (!validateTemperatureAlert(minimumTemperature, maximumTemperature, sensorOne, sensorTwo)) {
            return;
        }

        await createTemperatureAlert(new TemperatureAlertRequest({
            id: undefined, // New alert, no id
            accountId: undefined, // New alert, no accountId
            minimumTemperature: minimumTemperature,
            maximumTemperature: maximumTemperature,
            selectedSensor: sensorOne && sensorTwo ? 'BOTH' : sensorOne ? 'ONE' : sensorTwo ? 'TWO' : '',
            alertPeriod: selectedAlertPeriod,
            startTime: startTime,
            endTime: endTime,
            alertForAllVehicles: false,
            recipientIds: recipients.filter(recipient => selectedRecipientsRows.includes(recipient.email)).map(recipient => recipient.id),
            vehicleIds: selectedRows,
        }))
            .then(() => {
                navigate('/alerts/temperature-alerts')
            })
            .catch((error) => {
                alert(t('alert_creation.failed'))
            })
    }


    const handleCancel = () => {
        navigate('/alerts/temperature-alerts')
    }

    const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);

    React.useEffect(() => {
        async function getAccountRecipients() {
            setLoadingRecipients(true)
            let contacts: AccountContact[] = await getCurrentAccountContacts();
            if (!contacts) {
                setRecipients([])
            } else {
                let selectedRecipients = contacts
                    .filter(value => {
                        return selectedAlert?.recipients.includes(value.email);
                    })
                    .map(value => value.email);

                setSelectedRecipientsRows(selectedRecipients)
                setRecipients(contacts)
            }
            setLoadingRecipients(false)
        }

        getAccountRecipients()

        async function getAssets() {

            let assetList = await getAllAssets() as Asset[];

            if (assetList) {
                let selectedRowsList = assetList.filter(asset => {
                    return selectedAlert?.vehicles.includes(asset.identificationMark);
                }).map(asset => asset.id);

                setSelectedRows(selectedRowsList || [])
            }
        }

        getAssets()
    }, []);

    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Minimum Temperature */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("temperature_alert.admin.minimum_temperature")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("temperature_alert.admin.enter_minimum_temperature")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextField
                            type="number"
                            label={''}
                            required={false}
                            placeholder={''}
                            value={minimumTemperature !== undefined ? minimumTemperature.toString() : ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMinimumTemperatureChange(event.target.value)}
                            InputProps={{
                                inputProps: { min: -Infinity }
                            }}
                        />
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Maximum Temperature */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("temperature_alert.admin.maximum_temperature")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("temperature_alert.admin.enter_maximum_temperature")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextField
                            type="number"
                            label={''}
                            required={false}
                            placeholder={''}
                            value={maximumTemperature !== undefined ? maximumTemperature.toString() : ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMaximumTemperatureChange(event.target.value)}
                            InputProps={{
                                inputProps: { min: -Infinity }
                            }}
                        />
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Temperature Sensor */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("temperature_alert.admin.temperature_sensor")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("temperature_alert.admin.enter_temperature_sensor")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControlLabel control={<Checkbox
                                    checked={sensorOne}
                                    onChange={handleTemperatureSensorChange('ONE')}
                                    color="primary"
                                />} label={t("temperature_alert.admin.sensor_one")}/>
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Checkbox
                                    checked={sensorTwo}
                                    onChange={handleTemperatureSensorChange('TWO')}
                                    color="primary"
                                />} label={t("temperature_alert.admin.sensor_two")}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("event.asset_selection")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("event.asset_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.asset_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={assetFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectAssetTable
                                multiselect={true}
                                loading={loadingAssets}
                                assets={filterAssets(assets || [])}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Alert Period Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.event_days")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_days")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid>
                            <TemperatureAlertDayTable alertPeriod={selectedAlertPeriod} setAlertPeriod={setSelectedAlertPeriod}/>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Start & End Time Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("event.time_range")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("event.select_start_end_time")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker
                            label={t("event.start_time")}
                            value={selectedStartTime}
                            ampm={false}
                            onChange={(newStartTime) => {
                                if (newStartTime != null) {
                                    setSelectedStartTime(newStartTime);
                                } else {
                                    setSelectedStartTime(dayjs().startOf('date'));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("event.end_time")}
                                    value={selectedEndTime}
                                    ampm={false}
                                    onChange={(newEndTime) => {
                                        if (newEndTime != null) {
                                            setSelectedEndTime(newEndTime)
                                        } else {
                                            setSelectedEndTime(dayjs().endOf('date'))
                                        }
                                    }}/>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Recipients Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("event.recipients")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("event.select_recipients")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.recipient_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={recipientFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onRecipientSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectRecipientTable
                                multiselect={true}
                                loading={loadingRecipients}
                                recipients={filterRecipients(recipients).map(recipient => recipient.email)}
                                selectedRows={selectedRecipientsRows}
                                setSelectedRows={setSelectedRecipientsRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedRows.length === 0 || selectedRecipientsRows.length === 0}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? btnUpdateTemperatureAlert : btnCreateTemperatureAlert}
                            >
                                {isEdit ? t("button.save") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
