import { Asset } from "../../../hooks/assets/dto/Asset";
import AssetMarker from "./AssetMarker";
import {Dispatch, SetStateAction} from "react";
import {divIcon, point} from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import {LabelLayerType} from "../../../components/map/BaseMap";

interface AssetMarkerLayerProps {
    assets: Asset[] | undefined;
    clusteringEnabled: boolean;
    labelsEnabled: boolean;
    selectedAsset: string | undefined;
    setSelectedAsset: Dispatch<SetStateAction<string | undefined>>;
    disableClick?: boolean;
    labelLayer: LabelLayerType;
}

function AssetMarkerLayer(props: Readonly<AssetMarkerLayerProps>) {

    const createClusterCustomIcon = (cluster: any) => {
            const count = cluster.getChildCount();
            return divIcon({
                html: `<div><span>${count}</span></div>`,
                className: `leaflet-marker-icon marker-cluster marker-cluster-large leaflet-zoom-animated leaflet-interactive`,
                iconSize: point(40, 40, true),
            });
        };

        const getLabelText = (asset: Asset) => {
            switch (props.labelLayer) {
                case LabelLayerType.REGISTRATION:
                    return asset.identificationMark ? asset.identificationMark : asset.alias;
                case LabelLayerType.ALIAS:
                    return asset.alias ? asset.alias : asset.identificationMark;
                case LabelLayerType.DRIVER_NAME:
                    return asset.assignee.name ? asset.assignee.name  : asset.identificationMark;
                default:
                    return asset.alias ? asset.alias : asset.identificationMark;
            }
        };

    const assetMarkers = props.assets?.filter(asset => asset.latestEvent !== undefined).map(mapAssetToMarker) || [];

    function mapAssetToMarker(asset: Asset) {
        return (
            <AssetMarker
                key={asset.identificationMark.toString()}
                strokeColor={convertGroupNameToColor(asset.groupName)}
                showLabel={props.labelsEnabled}
                labelText={getLabelText(asset)} // Use the getLabelText function
                asset={asset}
                selectedAsset={props.selectedAsset}
                setSelectedAsset={props.setSelectedAsset}
                disableClick={props.disableClick}
            />
        );
    }

    if (props.clusteringEnabled) {
        return (<MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
        >
            {assetMarkers}
        </MarkerClusterGroup>)
    }

    return (
        <>
            {assetMarkers}
        </>
    )

}

function convertGroupNameToColor(groupName: string): string {
    if (groupName === null || groupName === undefined || groupName === "") {
        return "#000000";
    }

    // Create a hash from the string
    let hash = 0;
    for (let i = 0; i < groupName.length; i++) {
        hash = groupName.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a hex color and ensure it's bright
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        // Ensure the value is within the brighter range by shifting it to the upper range (128 to 255)
        value = Math.floor((value % 128) + 128);
        color += ('00' + value.toString(16)).slice(-2);
    }

    return color;
}

export default AssetMarkerLayer;
