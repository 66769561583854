import {useContext, useEffect, useReducer, useState, useRef} from "react";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {Asset, AssetDistance, AssetType} from "./dto/Asset";
import {
    mapAssetDistance,
    mapToAsset,
    mapToAssetsArray,
    mapToEventsArray,
    mapToJourneyObjects,
    mapToTimelineObjects
} from "./AssetMapper";
import moment from "moment";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";
import {TimelineBlock} from "./dto/TimelineBlock";


const properties = PropertiesLoader();

interface AlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export class AssetUpdateRequest {
    alias: string;
    label: string;
    driver: string;
    type: string;
    vehicleClass: string;
    co2Output: number;
    manHour1: number;
    manHour2: number;
    runningCost: number;
    privateCost: number;
    customWorkingHours: boolean;
    weekdayStart: string;
    weekdayEnd: string;
    saturdayStart: string;
    saturdayEnd: string;
    sundayStart: string;
    sundayEnd: string;
    notes: string;

    constructor(props: {
        alias: string;
        label: string;
        driver: string;
        type: string;
        vehicleClass: string;
        co2Output: number;
        manHour1: number;
        manHour2: number;
        runningCost: number;
        privateCost: number;
        customWorkingHours: boolean;
        weekdayStart: string;
        weekdayEnd: string;
        saturdayStart: string;
        saturdayEnd: string;
        sundayStart: string;
        sundayEnd: string;
        notes: string;
    }) {
        this.alias = props.alias;
        this.label = props.label;
        this.driver = props.driver;
        this.type = props.type;
        this.vehicleClass = props.vehicleClass;
        this.co2Output = props.co2Output;
        this.manHour1 = props.manHour1;
        this.manHour2 = props.manHour2;
        this.runningCost = props.runningCost;
        this.privateCost = props.privateCost;
        this.customWorkingHours = props.customWorkingHours;
        this.weekdayStart = props.weekdayStart;
        this.weekdayEnd = props.weekdayEnd;
        this.saturdayStart = props.saturdayStart;
        this.saturdayEnd = props.saturdayEnd;
        this.sundayStart = props.sundayStart;
        this.sundayEnd = props.sundayEnd;
        this.notes = props.notes
    }
}

export class ChangeRegistrationRequest {
    vehicleId: number;
    registration: string;
    isNewVehicle: boolean;

    constructor(props: {
        vehicleId: number;
        registration: string;
        isNewVehicle: boolean;
    }) {
        this.vehicleId = props.vehicleId;
        this.registration = props.registration;
        this.isNewVehicle = props.isNewVehicle;
    }
}


export function useAssetApi(props: AlertsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk} = usePlatformApiFetch();

    const [assets, setAssets] = useState<Asset[] | null>(null);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [hasGenerator, setHasGenerator] = useState(false);
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    const getAllAssets = async (background?: boolean) => {
        return new Promise((resolve, reject) => {
            if (!background) {
                setLoading(true)
            }
            platformApiFetch("tracking/v1/assets/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToAssetsArray(json))
                .then(assets => {
                    setAssets(assets)
                    setHasGenerator(assets && hasGeneratorAsset(assets))
                    if (!background) {
                        setLoading(false)
                    }
                    resolve(assets)
                })
                .catch((error: Error) => {
                    console.error("Error getting assets: " + error)
                    setError(error)
                    if (!background) {
                        setLoading(false)
                    }
                    reject(error)
                });
        });
    }

    const getAsset = async (assetId: string): Promise<Asset> => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/assets/" + assetId, 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToAsset(json))
                .then(asset => {
                    setLoading(false)
                    resolve(asset)
                })
                .catch((error: Error) => {
                    console.error("Error getting asset: " + error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const getAssetJourneysOnDay = async (assetId: number, date: Date) => {
        return new Promise((resolve, reject) => {
            const journeyDate = moment(date).format(dateFormat)

            platformApiFetch("tracking/v1/assets/" + assetId + "/journeys/" + journeyDate, 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToJourneyObjects(json))
                .then(journeys => resolve(journeys))
                .catch((error: Error) => {
                    console.error("Error getting asset journeys: " + error)
                    reject(error)
                });
        });
    }

    const getAssetEventsBetweenDates = async (assetId: number, startDateTime: Date, endDateTime: Date | undefined, routeReplay: boolean) => {
        return new Promise((resolve, reject) => {
            const journeyStartDateTime = moment(startDateTime).format(dateFormat)

            let endpoint = routeReplay
                ? `tracking/v1/assets/${assetId}/events?startDateTime=${journeyStartDateTime}`
                : `tracking/v1/assets/${assetId}/route-replay?startDateTime=${journeyStartDateTime}`;

            console.log("EDT:",endDateTime)

            if(endDateTime){
                const journeyEndDateTime = moment(endDateTime).format(dateFormat)
                endpoint += `&endDateTime=${journeyEndDateTime}`
            }

            platformApiFetch(endpoint, 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToEventsArray(json))
                .then(events => resolve(events))
                .catch((error: Error) => {
                    console.error("Error getting asset events: " + error);
                    reject(error);
                });
        });
    }

    const getAssetTimelineBlocksForDate = async (assetId: number, startDateTime: Date, background?: boolean): Promise<TimelineBlock[]> => {
        return new Promise((resolve, reject) => {
            if (!background) {
                setLoading(true)
            }
            const timelineStartDateTime = moment(startDateTime).format(dateFormat)

            platformApiFetch("tracking/v1/assets/" + assetId + "/timelines/" + timelineStartDateTime, 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToTimelineObjects(json))
                .then(timelineBlocks => {
                    if (!background) {
                        setLoading(false)
                    }
                    resolve(timelineBlocks)
                })
                .catch((error: Error) => {
                    console.error("Error getting asset timeline: " + error)
                    if (!background) {
                        setLoading(false)
                    }
                    reject(error)
                });
        });
    }

    const updateAsset = async (assetUpdateRequest: AssetUpdateRequest, assetId: string) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/assets/${assetId}`, 'put', auth.token, JSON.stringify(assetUpdateRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(asset => resolve(asset))
                .catch((error: Error) => {
                    console.error("Error updating asset: " + error)
                    reject(error)
                });
        });
    }

    const changeRegistration = async (changeRegistrationRequest: ChangeRegistrationRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/assets/registration`, 'post', auth.token, JSON.stringify(changeRegistrationRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(asset => resolve(asset))
                .catch((error: Error) => {
                    console.error("Error changing registration: " + error)
                    reject(error)
                });
        });
    }

    const getNearestAssets = async(latitude: number, longitude: number, vehicleId?: number): Promise<AssetDistance[]> => {
        return new Promise((resolve, reject) => {
            let endpoint = `tracking/v1/assets/nearest?latitude=${latitude}&longitude=${longitude}`;
            if (vehicleId !== undefined) {
                endpoint += `&vehicleId=${vehicleId}`;
            }
            platformApiFetch(endpoint, 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapAssetDistance(json))
                .then(assetDistances => resolve(assetDistances))
                .catch((error: Error) => {
                    console.error("Error getting nearest assets: " + error);
                    reject(error);
                });
        });
    }

    function hasGeneratorAsset(assets: Asset[]): boolean {
        return assets.some(asset => asset.type === AssetType.GENERATOR);
    }

    function filterGenerator(assets: Asset[]) {
        return assets.filter(asset => asset.type === AssetType.GENERATOR)
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllAssets(false)
                .then(() => {
                    if (props.shouldPoll) {
                        const interval = setInterval(() => getAllAssets(true), props.pollFrequency ?? 30000);
                        return () => clearInterval(interval);
                    }
                });
        }
    }, [auth.token]);

    return {
        loading,
        error,
        assets,
        setAssets,
        hasGenerator,
        filterGenerator,
        getAllAssets,
        getAsset,
        getAssetJourneysOnDay,
        getAssetEventsBetweenDates,
        getAssetTimelineBlocksForDate,
        updateAsset,
        changeRegistration,
        getNearestAssets
    };
}

export const useAssetWebSocket = () => {
    const reducer = (state: Record<string, Asset>, message: Asset) => ({
        ...state,
        [message.id]: { ...state[message.id], ...message }
    });

    const [assets, dispatch] = useReducer(reducer, {});

    const [readyState, setReadyState] = useState(false);
    const auth: IAuthContext = useContext(AuthContext)
    const {impersonateId} = useContext(ImpersonateUserContext)
    const wsRef = useRef<WebSocket | null>(null);

    useEffect(() => {
        let ws: WebSocket | null = null;
        let isManualClose = false;
        let pingInterval: NodeJS.Timeout | null = null;

        const startPing = () => {
            pingInterval = setInterval(() => {
                if (ws!.readyState === WebSocket.OPEN) {
                    ws!.send(JSON.stringify({ ping: 1 }));
                }
            }, 30000);
        }

        const endPing = () => {
            if(pingInterval !== null) {
                clearInterval(pingInterval);
            }
        }

        const connectWebSocket = () => {
            if (isManualClose) {
                return;
            }

            let webSocketUrl = properties.platformApiEndpoint.replace('http', 'ws') + 'sockets/assets/v1?token=' + auth.token
            if (impersonateId) {
                webSocketUrl += `&impersonateId=${impersonateId}`
            }

            ws = new WebSocket(webSocketUrl);
            wsRef.current = ws;

            ws.onopen = () => {
                setReadyState(true);
                startPing();
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                dispatch(mapToAsset(data));
            };

            ws.onclose = () => {
                setReadyState(false);
                endPing();

                // Attempt to reconnect after a delay unless manually closed
                if (!isManualClose) {
                    setTimeout(connectWebSocket, 3000);
                }
            };
        };

        connectWebSocket();

        return () => {
            isManualClose = true;
            endPing();
            if (ws) {
                ws.close();
            }
        };
    }, [auth.token]);



    const sendMessage = (message:string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
        }
    };

    return {
        assets: assets as Record<string, Asset>,
        isConnected: readyState,
        sendMessage
    };
};

