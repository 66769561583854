import PropertiesLoader from "../../properties/PropertiesLoader";
import {EngineHours} from "../assets/dto/Asset";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext} from "react";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

const properties = PropertiesLoader();
export function useEngineHoursApi() {

    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const enableEngineHoursForAsset = async (assetId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`enginehours/v1/${assetId}/enable`, 'post', auth.token)
                .then(checkCreated)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error enabling engine hours: " + error)
                    reject(error)
                });
        });
    }

    const disableEngineHoursForAsset = async (assetId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`enginehours/v1/${assetId}/disable`, 'post', auth.token)
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting engine hours: " + error)
                    reject(error)
                });
        });
    }

    const getAssetEngineHours = async (assetId: number) => {
        return platformApiFetch(`enginehours/v1/${assetId}`, 'get', auth.token)
            .then(checkOk)
            .then(response => response.json())
            .then((data: EngineHours) => data)
            .catch((error) => {
                console.error("Error getting engine hours for asset: " + error);
                return undefined;
            });
    }

    const updateEngineHoursForAsset = async (assetId: number, engineTimeHours: number) => {
        const engineTimeSeconds = engineTimeHours * 3600;
        const engineTimeObject = {engineTimeSeconds: engineTimeSeconds};
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`enginehours/v1/${assetId}/edit`, 'post', auth.token, JSON.stringify(engineTimeObject))
                .then(checkOk)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error editing engine hours: " + error)
                    reject(error)
                });
        });
    }

    return {enableEngineHoursForAsset, disableEngineHoursForAsset, getAssetEngineHours, updateEngineHoursForAsset}
}