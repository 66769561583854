import {Box, ListItemButton, Typography} from '@mui/material';
import {Journey} from '../../../../hooks/assets/dto/Journey';
import React, {useContext} from "react";
import {AssetStatus} from "../../../../hooks/assets/dto/Asset";
import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";
import {getStatusColors} from "../../../../themes/AppTheme";
import {getDateDifference} from "../../../../utils/DateUtils";
import {useTranslation} from "react-i18next";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
    timelineItemClasses
} from '@mui/lab';
import ReactGA from "react-ga4";
import {getColorByAssetStatus} from "../../../../utils/ColorUtils";

interface RouteReplayJourneyCardProps {
    journey: Journey;
    clickedJourney: Function
}

function RouteReplayJourneyCard(props: Readonly<RouteReplayJourneyCardProps>) {

    const {t} = useTranslation();

    const {colorPalette} = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);

    const dateDifference = props.journey.endTime ? getDateDifference(props.journey.startTime, props.journey.endTime) : undefined;
    const handleListItemClick = (
        journey: Journey,
    ) => {
        ReactGA.event("RT2-RouteReplay-SelectJourney");
        props.clickedJourney(journey)
    };

    function buildDistanceString(journey: Journey) {
        if (journey.distance?.value !== undefined) {
            return journey.distance.value + " " + journey.distance.units;
        } else {
            return "-";
        }
    }


    function buildBurationString(dateDifference: Duration | undefined) {
        let returnString = "";
        if(dateDifference === undefined){
            return "-";
        }

        if (dateDifference.days) {
            returnString += dateDifference.days + " " + t('dates.days') + " ";
        }
        if (dateDifference.hours) {
            returnString += dateDifference.hours + " " + t('dates.hours') + " ";
        }
        if (dateDifference.minutes) {
            returnString += dateDifference.minutes + " " + t('dates.minutes') + " ";
        }
        if (dateDifference.seconds) {
            returnString += dateDifference.seconds + " " + t('dates.seconds') + " ";
        }

        return returnString;
    }


    return (
        <ListItemButton sx={{
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "8px"
        }} alignItems="flex-start" onClick={(event) => handleListItemClick(props.journey)}>

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "5px",
                paddingBottom: "5px"
            }}>
                <Timeline sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}>

                    <TimelineItem position="right">
                        <TimelineSeparator>
                            <TimelineDot variant="outlined" sx={{
                                padding: '0px',
                                margin: '0px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                backgroundColor: 'transparent',
                                borderColor: 'transparent'
                            }}>
                                <PlayCircleFilledIcon style={{
                                    fontSize: "21px",
                                    color: getColorByAssetStatus(AssetStatus.MOVING, statusColors)
                                }}/>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent sx={{padding: '5px'}}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative"
                            }}>
                                {!props.journey.inProgress && getJourneyTextRow(props.journey.startAddress, buildBurationString(dateDifference), buildDistanceString(props.journey))}
                                {props.journey.inProgress && getJourneyTextRow(props.journey.startAddress, t('route_replay.in_progress') ?? undefined)}
                                <Typography
                                    sx={{display: "block", width: "10%", paddingRight: "15%"}}
                                    component="span"
                                    variant="cardbody"
                                    color="text.primary"
                                >
                                        <span style={{fontStyle: "italic", color: "gray"}}>
                                            {props.journey.startTime.toLocaleTimeString()}
                                        </span>
                                </Typography>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>

                    {!props.journey.inProgress && <TimelineItem position="right">
                        <TimelineSeparator>
                            <TimelineDot variant="outlined" sx={{
                                padding: '0px',
                                margin: '0px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                backgroundColor: 'transparent',
                                borderColor: 'transparent'
                            }}>
                                <StopCircleIcon style={{
                                    fontSize: "21px",
                                    color: getColorByAssetStatus(AssetStatus.STOPPED, statusColors)
                                }}/>
                            </TimelineDot>

                        </TimelineSeparator>

                        <TimelineContent sx={{padding: '5px'}}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative"
                            }}>
                                {getJourneyTextRow(props.journey.endAddress ?? "")}
                                <Typography
                                    sx={{display: "block", width: "10%", paddingRight: "15%"}}
                                    component="span"
                                    variant="cardbody"
                                    color="text.primary"
                                >
                                        <span style={{fontStyle: "italic", color: "gray"}}>
                                            {props.journey.endTime?.toLocaleTimeString()}
                                        </span>
                                </Typography>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>}
                </Timeline>

            </Box>
        </ListItemButton>
    );
}


function getJourneyTextRow(label: string, label2?: string, label3?: string) {

    return (<Box
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        }}
        width="100%"
        display="flex"
        flexDirection="column"
    >

        <Typography
            component="p"
            variant="cardbody"
            color="primary"
            style={{marginLeft: "5px"}}
        >
            {label}
        </Typography>

        {label2 !== undefined &&
            <Typography
                sx={{display: "block", width: "100%", paddingLeft: "12%"}}
                component="span"
                variant="cardbody"
                color="text.primary"
            >
                <span style={{fontStyle: "italic", color: "gray"}}>
                    {label2}
                </span>
            </Typography>
        }

        {label3 !== undefined &&
            <Typography
                sx={{display: "block", width: "100%", paddingLeft: "12%"}}
                component="span"
                variant="cardbody"
                color="text.primary"
            >
                <span style={{fontStyle: "italic", color: "gray"}}>
                    {label3}
                </span>
            </Typography>
        }
    </Box>)
}


export default RouteReplayJourneyCard;
