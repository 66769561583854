import { GeoJSON, Tooltip } from "react-leaflet";
import { Zone } from "../../hooks/zones/CongestionZones";

interface CongestionZoneLayerProps {
    zones: Zone[]
    congestionZonesEnabled: boolean;
}

function CongestionZoneLayer(props: Readonly<CongestionZoneLayerProps>) {
    const zonePolygons = props.zones.map((zone: Zone) => (
        <GeoJSON key={zone.id} data={zone.polygon}><Tooltip direction={"center"}>{zone.name}</Tooltip></GeoJSON>
    ))
    return (<>
        {props.congestionZonesEnabled ? zonePolygons : (<></>)}
    </>)

}

export default CongestionZoneLayer;
