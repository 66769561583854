import {Box, Tooltip} from '@mui/material';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useTemperatureAlertsApi, TemperatureAlert} from "../../../hooks/alerts/TemperatureAlerts";
import {RowDeleteButton, RowEditButton} from "../../../components/table/core/TableButtons";
import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import {Table, TableCell, TableRow} from "../../../components/table/core/Table";
import FilterBar from "../../../components/filterbar/FilterBar";
import {AlertPeriod} from "../../../hooks/alerts/GeofenceAlerts";
import {formatTime} from "../../../utils/TimeUtils";
import {UserPreferencesContext} from "../../../providers/UserPreferencesProvider";
import {GeneralSettingsContext} from "../../../providers/GeneralSettingsProvider";

interface TemperatureAlertsPageProps {
}

export default function TemperatureAlertsPage(props: TemperatureAlertsPageProps) {

    const {t} = useTranslation();

    const {temperatureAlerts, getAllTemperatureAlerts, loading: alertsLoading} = useTemperatureAlertsApi({shouldLoadAll: true})
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate()

    const loading = alertsLoading;

    function getFilteredAlerts() {
        if (temperatureAlerts === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return temperatureAlerts || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return temperatureAlerts.filter(alert =>
                alert.recipients.some(recipient => recipient.toLowerCase().includes(lowerCaseFilter))
                || alert.vehicles.some(vehicle => vehicle.toLowerCase().includes(lowerCaseFilter))
            );
        }
    }

    const columns = [
        {id: 'asset', content: t('alert_table.asset')},
        {id: 'recipients', content: t('alert_table.recipients')},
        { id:'temperature-sensor', content: t('alert_table.temperature_sensor')},
        { id:'temperature_range', content: t('alert_table.temperature_range')},
        { id:'days', content: t('alert_table.days')},
        { id:'time-range', content: t('alert_table.time_range')},
        {id: 'action', content: t('alert_table.action')}
    ];

    const rows = getFilteredAlerts().map((alert) => {
        return <TemperatureAlertRow key={alert.id} alert={alert} updateTrigger={getAllTemperatureAlerts}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t('search_bar.search_for')}/>

            <Table
                id={"temperature-alerts"}
                label={"Temperature Alerts"}
                columns={columns}
                rows={rows}
                loading={loading}
                createAction={() => {
                    navigate('/alerts/create-temperature-alerts')
                }}
                emptyLabel={t('alert_table.no_alerts') ?? undefined}
            />
        </Box>
    );
};

function TemperatureAlertRow(props: Readonly<{ alert: TemperatureAlert, updateTrigger: () => void }>) {
    const {t} = useTranslation();
    const {twentyFourHourEnabled} = useContext(UserPreferencesContext)
    const {generalSettings} = useContext(GeneralSettingsContext)
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedAlertId, setSelectedAlertId] = React.useState<number | null>(null);
    const navigate = useNavigate()
    const {deleteTemperatureAlert} = useTemperatureAlertsApi()

    function getTemperatureRange(minTemp: number | undefined | null, maxTemp: number | undefined | null, useMetric: boolean): string {
        if (minTemp != null && maxTemp != null) {
            return `${convertTemperature(minTemp, useMetric)} ${t('temperature_alert.temperature_range.to')} ${convertTemperature(maxTemp, useMetric)}`;
        } else if (minTemp != null) {
            return `${convertTemperature(minTemp, useMetric)} ${t('temperature_alert.temperature_range.or_higher')}`;
        } else if (maxTemp != null) {
            return `${convertTemperature(maxTemp, useMetric)} ${t('temperature_alert.temperature_range.or_lower')}`;
        } else {
            return t('temperature_alert.temperature_range.na');
        }
    }

    const getAlertPeriodTranslation = (alertPeriod: AlertPeriod) => {
        if (alertPeriod === AlertPeriod.MONDAY) {
            return t('days.monday');
        } else if (alertPeriod === AlertPeriod.TUESDAY) {
            return t('days.tuesday');
        } else if (alertPeriod === AlertPeriod.WEDNESDAY) {
            return t('days.wednesday');
        } else if (alertPeriod === AlertPeriod.THURSDAY) {
            return t('days.thursday');
        } else if (alertPeriod === AlertPeriod.FRIDAY) {
            return t('days.friday');
        } else if (alertPeriod === AlertPeriod.SATURDAY) {
            return t('days.saturday');
        } else if (alertPeriod === AlertPeriod.SUNDAY) {
            return t('days.sunday');
        } else if (alertPeriod === AlertPeriod.WEEKDAYS) {
            return t('days.weekdays');
        } else if (alertPeriod === AlertPeriod.WEEKENDS) {
            return t('days.weekends');
        } else if (alertPeriod === AlertPeriod.ALL) {
            return t('days.all');
        } else {
            return '';
        }
    }
    const getSensorTranslation = (sensor: string) => {
        if (sensor === 'One') {
            return t('temperature_alert.sensor.one');
        } else if (sensor === 'Two') {
            return t('temperature_alert.sensor.two');
        } else if (sensor === 'Both') {
            return t('temperature_alert.sensor.both');
        } else {
            return '';
        }
    }
    const convertTemperature = (value: number, useMetric: boolean): string => {
        if (useMetric) {
            return `${value}C\u00B0`;
        } else {
            const fahrenheit = (value * 9/5) + 32;
            return `${fahrenheit.toFixed(1)}F\u00B0`;
        }
    };

    async function handleDelete() {
        await deleteTemperatureAlert(selectedAlertId?.toString() as string)
            .catch((error) => {
                alert(t('alert_delete.failed'))
            })

        setShowDeleteDialog(false)
        props.updateTrigger()
    }

    return <TableRow
        id={props.alert.id.toString()}>
        <TableCell>
            {props.alert.vehicles.length > 3 ?
                props.alert.vehicles.length + " " + t('alert_table.vehicles') :
                props.alert.vehicles.map(vehicle => (
                    <div
                        key={props.alert.id.toString() + "_vehicles_" + vehicle}
                        id={props.alert.id.toString() + "_vehicles_" + vehicle}>
                        {vehicle} <br/>
                    </div>
                ))
            }
        </TableCell>
        <TableCell>
            {props.alert.recipients.length > 3 ? (
                    <Tooltip title={props.alert.recipients.join(', ')} arrow>
                        <div>
                            {props.alert.recipients.length + ' ' + t('alert_table.recipients')}
                        </div>
                    </Tooltip>
                ) :
                props.alert.recipients.map(recipient => (
                    <div
                        key={props.alert.id.toString() + "_recipients_" + recipient}
                        id={props.alert.id.toString() + "_recipients_" + recipient}>
                        {recipient} <br/>
                    </div>
                ))
            }
        </TableCell>

        <TableCell>
            {getSensorTranslation(props.alert.selectedSensor)}
        </TableCell>
        <TableCell>
            {getTemperatureRange(props.alert.minimumTemperature, props.alert.maximumTemperature, generalSettings?.useMetric ?? true)}
        </TableCell>
        <TableCell>
            {getAlertPeriodTranslation(props.alert.alertPeriod)}
        </TableCell>
        <TableCell>
            {`${formatTime(props.alert.startTime, twentyFourHourEnabled)} - ${formatTime(props.alert.endTime, twentyFourHourEnabled)}`}
        </TableCell>

        <TableCell>
                <RowEditButton action={() => {
                    const selectedAlert = props.alert;
                    navigate('/alerts/update-temperature-alerts', {state: {selectedAlert}});
                }}/>
            <RowDeleteButton action={() => {
                setSelectedAlertId(props.alert.id);
                setShowDeleteDialog(true);
            }}/>

            <ConfirmationDialog open={showDeleteDialog}
                                title={t('alerts_page.temperature_alerts_page.delete_dialog.title')}
                                description={t('alerts_page.temperature_alerts_page.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}
