import { IconButton, useTheme } from '@mui/material';


interface AppBarButtonProps {
    id: string;
    icon: JSX.Element;
    onClick: Function;
    className?: string;
    ariaControls?: string;
    ariaHasPopup?: boolean;
    ariaExpanded?: boolean;
}

function AppBarButton(props: Readonly<AppBarButtonProps>) {
    const theme = useTheme()
    return (
        <IconButton
            id={props.id}
            size='small'
            disableRipple
            className={props.className}
            aria-controls={props.ariaControls}
            aria-haspopup={props.ariaHasPopup}
            aria-expanded={props.ariaExpanded}
            onClick={(event) => props.onClick(event)}
            sx={{
                backgroundColor: theme.palette.appbar.buttonBackground,
                color: theme.palette.appbar.buttonForeground,
                borderRadius: '5px',
                margin: '5px',
                '&:hover': {
                    backgroundColor: theme.palette.appbar.buttonBackgroundHover,
                },
            }}
        >{props.icon}</IconButton>
    );
}

export default AppBarButton;