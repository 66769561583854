import React, {useContext} from "react";

import {Map} from "leaflet";
import {Box, Button, List} from "@mui/material";
import GeofencesFilterBar from "./GeofencesFilterBar";
import ListSectionHeading from "../../../../components/leftpanel/ListSectionHeading";
import {Geofence} from "../../../../hooks/geofences/dto/Geofence";
import {Polygon} from 'geojson';
import {calculatePolygonBounds} from "../../utils/GeofenceUtils";
import {GeofenceWizardContext} from "../../../../providers/GeofenceWizardProvider";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import {GeofencesListItem} from "./GeofencesListItem";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";

interface GeofencesListPanelProps {
    mapref: React.RefObject<Map>;
    loading: boolean;
    geofences: Geofence[];
}

export function GeofencesListPanel(props: GeofencesListPanelProps) {
    const {t} = useTranslation();
    const {setNewGeofence, loading, setLoading, setId} = useContext(GeofenceWizardContext);
    const [filter, setFilter] = React.useState<string | undefined>(undefined);
    const [categoryFilter, setCategoryFilter] = React.useState<string | undefined>("all");

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setFilter(undefined);
        } else {
            setFilter(search);
        }
    }

    const filterGeofences = (geofences: Array<Geofence>) => {
        let filteredGeofences = geofences

        if (filter !== undefined) {
            const lowerCaseFilter = filter.toLowerCase();
            filteredGeofences = geofences.filter(geofence => ((geofence.name.toLowerCase().includes(lowerCaseFilter) || geofence.category?.name.toLowerCase().includes(lowerCaseFilter))));
        }

        if (categoryFilter === "all") {
            return filteredGeofences;
        } else {
            return filteredGeofences.filter(geofence => geofence.category?.name === categoryFilter);
        }
    }

    const handleListItemClick = (geofence: Geofence) => {
        setLoading(true)
        setId(geofence.id)
        if (props.mapref.current !== null) {
            switch (geofence.feature.geometry.type) {
                case "Point":
                    props.mapref.current.setView([geofence.feature.geometry.coordinates[1], geofence.feature.geometry.coordinates[0]], 15)
                    break;
                case "Polygon":
                    props.mapref.current.fitBounds(calculatePolygonBounds(geofence.feature.geometry as Polygon))
                    break;
                default:
                    break;
            }
        }
    };

    const handleAddGeofenceClick = () => {
        ReactGA.event("RT2-AddGeofence");
        setNewGeofence(true);
    }


    return <Box component="main" sx={{
        flexGrow: 1,
        p: 0,
        height: 'calc(-48px + 100vh)',
        display: 'flex',
        flexDirection: 'column',
    }}>


        <GeofencesFilterBar
            onSearchChange={onSearchChange}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            geofences={props.geofences}
        />
        {(props.loading || loading) && (
            <Box sx={{width: '100%', padding: '10px'}}>
                <LoadingSpinner/>
            </Box>
        )}
        {!(props.loading || loading) && (<>
            <List
                dense={true}
                sx={{
                    flex: 1,
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    overflowY: "scroll",
                }}
            >
                <ListSectionHeading key={"geofences-list-heading"} title={t('geofences_page.list_title') as string}/>
                {filterGeofences(props.geofences).map(geofence => {
                    return <GeofencesListItem key={"geofence-" + geofence.id} geofence={geofence}
                                              onClick={handleListItemClick}/>
                })}
            </List>
            <div style={{display: 'flex', padding: "10px", justifyContent: 'center'}}>
                <Button fullWidth variant="contained" color="secondary"
                        onClick={handleAddGeofenceClick}>{t('geofences_page.add_geofence_button')}</Button>
            </div>
        </>)}
    </Box>
}