import { Box, Radio, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { colors } from '../../themes/AppTheme';

import React from 'react';

interface RadioButtonProps {
    icon?: React.ReactNode;
    mainText: string;
    subText?: string;
    selected: boolean;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
    style?: React.CSSProperties;
}

export default function RadioButton(props: Readonly<RadioButtonProps>) {

    return (
        <Tooltip title={props.tooltip || ''}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                onClick={props.disabled ? undefined : props.onClick}
                marginBottom={1}
                sx={{
                    width: "100%",
                    border: "2px solid",
                    borderColor: props.selected ? colors.ram_orange : colors.ram_light_blue,
                    borderRadius: "5px",
                    opacity: props.disabled ? 0.5 : 1, // Reduce opacity if disabled
                    cursor: props.disabled ? 'not-allowed' : 'pointer', // Change cursor if disabled
                }}>
                {props.icon &&
                    <Box paddingLeft={1} paddingRight={1}>
                        {props.icon}
                    </Box>
                }
                <Box display="flex" flexDirection="column" alignItems="Left"
                    sx={{
                    }}>
                    <Typography
                        sx={{
                            display: "block",
                            width: "100%",
                            margin: "0px",
                            paddingLeft: "10px",
                            paddingBottom: "0px",
                            paddingTop: props.subText ? "10px" : "0px",
                        }}
                        component="div"
                        variant="smallTitle"
                    >
                        {props.mainText}
                    </Typography>
                    {props.subText &&
                        <Typography
                            sx={{
                                display: "block",
                                width: "100%",
                                paddingLeft: "10px",
                                paddingTop: "0px",
                                paddingBottom: "10px",
                            }}
                            component="div"
                            variant="body"
                        >
                            {props.subText}
                        </Typography>
                    }

                </Box>
                <Box flexGrow={1} />
                    <Radio size='small' color='secondary' checked={props.selected} disabled={props.disabled} />     
            </Box>
        </Tooltip>
    );
};





