import {createTheme} from "@mui/material/styles";
import {FontSizeName, getFontSize} from "./FontSizes";

interface StatusColorPalette {
  default: StatusColors;
  protanopia: StatusColors;
  deuteranopia: StatusColors;
  tritanopia: StatusColors;
}

export interface StatusColors {
  moving: string;
  stopped: string;
  stationary: string;
  private: string;
  inactive: string;
}

const statusColors: StatusColorPalette = {
  default: {
    moving: "#009A17",
    stopped: "#FF2400",
    stationary: "#0827F5",
    private: "#2A2A2A",
    inactive: "#000000"
  },
  protanopia: {
    moving: "#007FFF",
    stopped: "#FFA500",
    stationary: "#800080",
    private: "#2A2A2A",
    inactive: "#000000"
  },
  deuteranopia: {
    moving: "#007FFF",
    stopped: "#FFA500",
    stationary: "#800080",
    private: "#2A2A2A",
    inactive: "#000000"
  },
  tritanopia: {
    moving: "#32CD32",
    stopped: "#FFA500",
    stationary: "#FF69B4",
    private: "#2A2A2A",
    inactive: "#000000"
  }
}

export function getStatusColors(colorProfile: string): StatusColors {
  switch (colorProfile) {
    case "default":
      return statusColors.default;
    case "protanopia":
      return statusColors.protanopia;
    case "deuteranopia":
      return statusColors.deuteranopia;
    case "tritanopia":
      return statusColors.tritanopia;
    default:
      return statusColors.default;
  }
}

export const colors = {
  ram_purple: "#322c4f",
  ram_purple_dark: "#28233f",
  ram_smokey: "#605c73",
  ram_light_blue: "#eceff1",
  ram_orange: "#f36f21",
  ram_orange_dark: "#C2591A",
  ram_gray: "#808080",
  ram_green: "#4CBB17",
  black: "#000000",
  white: "#FFFFFF"
}

export function generateAppTheme(largeText: boolean) {

  return createTheme({
    mixins: {
      toolbar: {
        minHeight: 48
      }
    },
    components: {
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: getFontSize(FontSizeName.TITLE, largeText),
          }
        }
      }
    },
    palette: {
      disabled: {
        main: colors.ram_gray,
        contrastText: colors.ram_gray,
        light: colors.ram_gray,
        dark: colors.ram_gray
      },
      radioButton: {
        checked: colors.ram_orange,
        unchecked: colors.ram_purple
      },
      appbar: {
        background: colors.ram_light_blue,
        accent: colors.ram_orange,
        buttonBackground: colors.white,
        buttonBackgroundHover: "#e0e0e0",
        buttonForeground: "#322c4f",
      },
      mapcontrol: {
        background: colors.white,
        backgroundAlt: colors.ram_purple,
        backgroundHover: "#e0e0e0",
        foreground: "#322c4f",
        foregroundAlt: colors.white
      },
      contentPage: {
        background: colors.ram_light_blue,
        text: colors.black,
        backgroundPanel: colors.white,
        listBackground: colors.white,
        listHoverBackground: colors.ram_light_blue,
        listHoverBorder: colors.ram_light_blue,
        listSelectedBackground: colors.ram_light_blue,
        listSelectedBorder: colors.ram_purple,
        listText: colors.ram_purple,
        listHoverText: colors.ram_purple,
        listSelectedText: colors.ram_purple,
        footerDivider: colors.ram_light_blue,
      },

      primary: { main: colors.ram_purple, contrastText: colors.white },
      secondary: { main: colors.ram_orange, contrastText: colors.white },
      tertiary: { main: colors.ram_light_blue },
      contrast: { main: colors.black },
      drawer: {
        background: colors.ram_purple,
        text: colors.ram_light_blue,
        backgroundSelected: colors.ram_light_blue,
        textSelected: colors.ram_purple,
        backgroundHover: colors.ram_smokey,
        textHover: colors.ram_light_blue
      },
      loginwizard: {
        background: colors.white,
        progressPanelBackground: colors.ram_light_blue,
        text: colors.ram_purple,
        hightlight: colors.ram_orange
      },
      timeline: { main: colors.ram_light_blue, contrastText: "#322c4f" },
      statusmoving: { main: "#21994D" },
      statusstopped: { main: "#C30808" },
      statusstationary: { main: "#4164B2" },
    },
    typography: {

      largeHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: getFontSize(FontSizeName.LARGE_HEADER, largeText),
        fontWeight: 'bold',
        color: colors.ram_purple,
        display: "block"
      },
      header: {
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: getFontSize(FontSizeName.HEADER, largeText),
          fontWeight: 'bold',
          color: colors.ram_purple,
          display: "block"
      },
      smallHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: getFontSize(FontSizeName.SMALL_HEADER, largeText),
        fontWeight: 'bold',
        color: colors.ram_purple,
        display: "block"
        },
        title: {
          paddingTop: 5,
          paddingBottom: 5,
          fontSize: getFontSize(FontSizeName.TITLE, largeText),
          fontWeight: 'bold',
          color: colors.ram_purple,
          display: "block"
        },
        smallTitle: {
          paddingTop: 5,
          paddingBottom: 5,
          fontSize: getFontSize(FontSizeName.SMALL_TITLE, largeText),
          fontWeight: 'bold',
          color: colors.ram_purple,
          display: "block"
        },
      subtitle: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: getFontSize(FontSizeName.SMALL_TITLE, largeText),
        fontWeight: 'normal',
        color: colors.ram_gray,
        display: "block"
      },
      body: {
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: getFontSize(FontSizeName.BODY, largeText),
        fontWeight: 'normal',
        color: colors.ram_purple,
        display: "block"
      },
        caption: {
          paddingTop: 2,
          paddingBottom: 2,
          fontSize: getFontSize(FontSizeName.CAPTION, largeText),
          fontWeight: 'normal',
          color: colors.ram_purple,
          display: "block"
        },

      dropdownTitle: {
        paddingTop: 10,
        paddingBottom: 0,
        fontSize: getFontSize(FontSizeName.TITLE, largeText),
        fontWeight: 'bold',
        color: colors.ram_purple,
        display: "block"
      },
      dropdownBody: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: getFontSize(FontSizeName.BODY, largeText),
        fontWeight: 'normal',
        color: colors.ram_purple,
        display: "block"
      },
      dropdownCaption: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: getFontSize(FontSizeName.CAPTION, largeText),
        fontWeight: 'normal',
        color: colors.ram_gray,
        display: "block"
      },
      cardtitle: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: getFontSize(FontSizeName.SMALL_TITLE, largeText),
        color: colors.ram_purple,
        fontWeight: 'bold'
      },
      cardsubtitle: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: getFontSize(FontSizeName.BODY, largeText),
        fontWeight: 'bold'
      },
      cardbody: {
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: getFontSize(FontSizeName.CAPTION, largeText),
        fontWeight: 'normal'
      }
    },
  });
}



declare module '@mui/material/styles' {
  interface TypographyVariants {
    largeHeader: React.CSSProperties;
    header: React.CSSProperties;
    smallHeader: React.CSSProperties;
    title: React.CSSProperties;
    smallTitle: React.CSSProperties;
    subtitle: React.CSSProperties;
    body: React.CSSProperties;
    caption: React.CSSProperties;
    dropdownTitle: React.CSSProperties;
    dropdownBody: React.CSSProperties;
    dropdownCaption: React.CSSProperties;
    cardtitle: React.CSSProperties;
    cardsubtitle: React.CSSProperties;
    cardbody: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    largeHeader?: React.CSSProperties;
    header?: React.CSSProperties;
    smallHeader?: React.CSSProperties;
    title?: React.CSSProperties;
    smallTitle?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body?: React.CSSProperties;
    caption?: React.CSSProperties;
    dropdownTitle?: React.CSSProperties;
    dropdownBody?: React.CSSProperties;
    dropdownCaption?: React.CSSProperties;
    cardtitle?: React.CSSProperties;
    cardsubtitle?: React.CSSProperties;
    cardbody?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    largeHeader: true;
    header: true;
    smallHeader: true;
    title: true;
    smallTitle: true;
    subtitle: true;
    body: true;
    caption: true;
    dropdownTitle: true;
    dropdownBody: true;
    dropdownCaption: true;
    cardtitle: true;
    cardsubtitle: true;
    cardbody: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {    
    disabled: Palette["primary"];
    tertiary: Palette["primary"];
    radioButton: {
      checked: string;
      unchecked: string;
    };
    appbar: {
      background: string;
      accent: string;
      buttonBackground: string;
      buttonBackgroundHover: string;
      buttonForeground: string;
    };
    mapcontrol: {
      background: string;
      backgroundAlt: string;
      backgroundHover: string;
      foreground: string;
      foregroundAlt: string;
    }
    contentPage: {
      background: string;
      text: string;
      backgroundPanel: string;
      listBackground: string;
      listHoverBackground: string;
      listHoverBorder: string;
      listSelectedBackground: string;
      listSelectedBorder: string;
      listText: string;
      listHoverText: string;
      listSelectedText: string;
      footerDivider: string;
    }
    contrast: Palette["primary"];
    drawer: {
      background: string;
      text: string,
      backgroundSelected: string,
      textSelected: string,
      backgroundHover: string,
      textHover: string
    };
    loginwizard: {
      background: string;
      progressPanelBackground: string;
      text: string;
      hightlight: string;
    };
    timeline: Palette["primary"];
    statusmoving: Palette["primary"];
    statusstopped: Palette["primary"];
    statusstationary: Palette["primary"];
  }
  

  interface PaletteOptions {    
    disabled: Palette["primary"];
    tertiary?: PaletteOptions["primary"];
    radioButton?: {
      checked: string;
      unchecked: string;
    };
    appbar?: {
      background: string;
      accent: string;
      buttonBackground: string;
      buttonBackgroundHover: string;
      buttonForeground: string;
    }
    mapcontrol?: {
      background: string;
      backgroundAlt: string;
      backgroundHover: string;
      foreground: string;
      foregroundAlt: string;
    }
    contentPage?: {
      background: string;
      text: string;
      backgroundPanel: string;
      listBackground: string;
      listHoverBackground: string;
      listHoverBorder: string;
      listSelectedBackground: string;
      listSelectedBorder: string;
      listText: string;
      listHoverText: string;
      listSelectedText: string;
      footerDivider: string;
    }
    contrast?: PaletteOptions["primary"];
    drawer?: {
      background: string;
      text: string;
      backgroundSelected: string;
      textSelected: string;
      backgroundHover: string;
      textHover: string;
    };
    loginwizard: {
      background: string;
      progressPanelBackground: string;
      text: string;
      hightlight: string;
    };
    timeline?: PaletteOptions["primary"];
    statusmoving?: PaletteOptions["primary"];
    statusstopped?: PaletteOptions["primary"];
    statusstationary?: PaletteOptions["primary"];
  } 



}
