import { Typography } from '@mui/material';

interface AssetWarnMessageProps {
  text: string;
}

export function AssetWarnMessage(props: Readonly<AssetWarnMessageProps>) {
  return (
    <Typography
      sx={{
        display: "block",
        backgroundColor: "Orange",
        color: "black",
        borderRadius: "5px",
        padding: "5px",
      }}
      component="p"
      variant="cardbody"
    >
      {props.text}
    </Typography>
  );
}