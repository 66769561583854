import { Box, useTheme } from '@mui/material';
import {ReactNode} from "react";

interface ContentPanelProps {
    children?: ReactNode;
}

export default function ContentPanel(props: Readonly<ContentPanelProps>) {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="Left"
            sx={{
                background: theme.palette.contentPage.backgroundPanel,
                height: '100%',
                borderRadius: '5px',
                boxShadow: 3
            }}>
            {props.children}
        </Box>
    );
};
