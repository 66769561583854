import {Grid, Typography} from "@mui/material";
import React from "react";

interface SelectorLabelProps {
    title: string;
    subtitle: string;
}

export function SelectorLabel(props: Readonly<SelectorLabelProps>) {
    return (
        <Grid item xs={12} md={4} lg={2}>
            <Typography
                sx={{ marginLeft: "20px",
                    marginTop: "10px" }}
                variant="title">
                {props.title}
            </Typography>
            <Typography
                sx={{ marginLeft: "20px" }}
                variant="body">
                {props.subtitle}
            </Typography>
        </Grid>
    );
}