import { Box, Switch, Typography } from '@mui/material';

import React from 'react';

interface ToggleInputProps {
    mainText: string;
    subText?: string;
    selected: boolean;
    onToggle: () => void;
}



export default function ToggleInput(props: Readonly<ToggleInputProps>) {

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom={1}
            sx={{
                width: "100%",
            }}>
            <Box display="flex" flexDirection="column" alignItems="Left"
                sx={{
                }}>
                <Typography
                    sx={{
                        display: "block",
                        width: "100%",
                        margin: "0px",
                        paddingBottom: "0px",
                        paddingTop: props.subText ? "10px" : "0px",
                    }}
                    component="div"
                    variant="smallTitle"
                >
                    {props.mainText}
                </Typography>
                {props.subText &&
                    <Typography
                        sx={{
                            display: "block",
                            width: "100%",
                            paddingTop: "0px",
                            paddingBottom: "10px",
                        }}
                        component="div"
                        variant="body"
                    >
                        {props.subText}
                    </Typography>
                }

            </Box>
            <Box flexGrow={1} />
            <Switch color='secondary' checked={props.selected} onChange={props.onToggle} />


        </Box>
    );
};





