import {useContext} from "react"
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";
import PropertiesLoader from "../../properties/PropertiesLoader";

export default function usePlatformApiFetch() {

    const properties = PropertiesLoader();
    const {impersonateId} = useContext(ImpersonateUserContext)

    const platformApiFetch = async (path: string, method: string, token: string, body?: string) => {
        return await fetch(properties.platformApiEndpoint + path, {
            method: method,
            headers: buildHeaders(token, body !== undefined),
            body: body,
        })
    }

    const buildHeaders = (token: string, bodyPresent: boolean) => {
        let headers = new Headers({
            Authorization: "Bearer " + token,
        })

        if (bodyPresent) {
            headers.append("Content-Type", "application/json")
        }

        if (impersonateId) {
            headers.append("x-impersonate-id", impersonateId.toString())
        }

        return headers
    }

    const checkOk = (response: Response) => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    const checkCreated = (response: Response) => {
        if (response.status === 201) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    const checkNoContent = (response: Response) => {
        if (response.status === 204) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    return {platformApiFetch, checkOk, checkCreated, checkNoContent}

}