import {AssetStatus} from "./Asset";

export class TimelineBlock {
    status: AssetStatus;
    startTime: Date;
    endTime: Date;
    startLocation: string;
    endLocation: string;

    constructor(props: {
        status: AssetStatus;
        startTime: Date;
        endTime: Date;
        startLocation: string;
        endLocation: string;
    }) {
        this.status = props.status;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.startLocation = props.startLocation;
        this.endLocation = props.endLocation;
    }
}
