import {Box, Button, Grid, Paper, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";

import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FullView from "../../layouts/FullView";
import TextInput from "../../components/form/TextInput";
import {useAssetGroupApi} from "../../hooks/assetGroups/AssetGroups";
import {AssetGroupRequest} from "../../hooks/assetGroups/dto/AssetGroupRequest";


export default function UpdateAssetGroupPage() {
    return (
        <FullView content={<UpdateAssetGroupPageContent/>}/>
    );
}

function UpdateAssetGroupPageContent() {
    const location = useLocation();
    const selectedAssetGroup = location.state.selectedAssetGroup;

    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();
    const {updateAssetGroup} = useAssetGroupApi();

    const [groupName, setGroupName] = useState<string>(selectedAssetGroup.labelText || '')

    const handleUpdateAssetGroup = async () => {

        await updateAssetGroup(new AssetGroupRequest({
            label: groupName,
        }), selectedAssetGroup.id)
            .then((asset) => {
                navigate('/asset-groups')
            })
            .catch((error) => {
                alert(t('asset_group_page.asset_group_messages.asset_group_update_failed'))
            })
    }

    const handleCancel = () => {
        navigate('/asset-groups')
    }

    return (

        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Group Name */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("asset_group_page.asset_group_table.group")}</Typography>
                        <Typography sx={{marginLeft: "20px"}}
                                    variant="body">{t("asset_group_page.asset_group_table.group_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={false} required={true} label={''} placeholder={''}
                                   value={groupName} onChange={setGroupName}/>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={groupName === ''}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={handleUpdateAssetGroup}
                            >
                                {t("button.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
