import { TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
//Props
import PropertiesLoader from "../../properties/PropertiesLoader";
const properties = PropertiesLoader();

export enum MapLayer {
    GOOGLE_ROAD_MAP = "GOOGLE_ROAD_MAP",
    GOOGLE_SAT_MAP = "GOOGLE_SAT_MAP",
    GOOGLE_HYBRID_MAP = "GOOGLE_HYBRID_MAP",
    GOOGLE_TRAFFIC_MAP = "GOOGLE_TRAFFIC_MAP",
    OSM_DEFAULT = "OSM_DEFAULT",
    CARTODB_VOYAGER = "CARTODB_VOYAGER",
    STADIA_DARK = "STADIA_DARK",
}

export const DEFAULT_MAP_LAYER = MapLayer.GOOGLE_ROAD_MAP;

const customGoogleMapsStyle = [
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];

interface MapTileLayerProps {
    mapLayer: MapLayer
}

function MapTileLayer(props: Readonly<MapTileLayerProps>) {
    return (<>
        {props.mapLayer === MapLayer.GOOGLE_ROAD_MAP ? (<ReactLeafletGoogleLayer apiKey={properties.googleMapsApiKey} type={'roadmap'} styles={customGoogleMapsStyle} />) : null}
        {props.mapLayer === MapLayer.GOOGLE_TRAFFIC_MAP ? (<ReactLeafletGoogleLayer apiKey={properties.googleMapsApiKey} type={'roadmap'} googleMapsAddLayers={[{name: 'TrafficLayer'}]} styles={customGoogleMapsStyle} />) : null}
        {props.mapLayer === MapLayer.GOOGLE_HYBRID_MAP ? (<ReactLeafletGoogleLayer apiKey={properties.googleMapsApiKey} type={'hybrid'} styles={customGoogleMapsStyle} />) : null}
        {props.mapLayer === MapLayer.OSM_DEFAULT ? (<TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />) : null}
        {props.mapLayer === MapLayer.CARTODB_VOYAGER ? (<TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />) : null}
        {props.mapLayer === MapLayer.STADIA_DARK ? (<TileLayer
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        />) : null}
    </>)
}

export default MapTileLayer;