import { MapLayer } from "../../../components/map/MapTileLayer";
import {LabelLayerType} from "../../../components/map/BaseMap";

export class UserPreferences {
    colorPalette: string;
    largeText: boolean;
    openDrawer: boolean;
    languageCode: string;
    clusterEnabled: boolean;
    labelsEnabled: boolean;
    mapType: MapLayer;
    labelType: LabelLayerType;
    twentyFourHourEnabled: boolean;

    constructor(props: {
        colorPalette: string;
        largeText: boolean;
        openDrawer: boolean;
        languageCode: string;
        clusterEnabled: boolean;
        labelsEnabled: boolean;
        mapType: MapLayer;
        labelType: LabelLayerType;
        twentyFourHourEnabled: boolean;
    }) {
        this.colorPalette = props.colorPalette;
        this.largeText = props.largeText;
        this.openDrawer = props.openDrawer;
        this.languageCode = props.languageCode;
        this.clusterEnabled = props.clusterEnabled;
        this.labelsEnabled = props.labelsEnabled;
        this.mapType = props.mapType;
        this.labelType = props.labelType;
        this.twentyFourHourEnabled = props.twentyFourHourEnabled;
    }
}