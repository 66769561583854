import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip, Typography, useTheme } from '@mui/material';
import Control from 'react-leaflet-custom-control';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useTranslation } from 'react-i18next';
import {LayerControlType} from "../MapPage";
import {LabelLayerType} from "../../../components/map/BaseMap";

interface LabelLayerControlProps {
    placement: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
    selectedLayer: LabelLayerType;
    setSelectedLayer: React.Dispatch<React.SetStateAction<LabelLayerType>>;
    open: boolean;
    handleClickOperation: Function;
}

function LabelLayerControl(props: LabelLayerControlProps) {
    const theme = useTheme()
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.handleClickOperation(LayerControlType.LABEL);
    };

    const handleSelectLayer = (layer: LabelLayerType) => {
        props.setSelectedLayer(layer);
    }

    return (
        <>
            <Control position={props.placement} prepend={false}>
                <Tooltip placement="right" title={t('map_controls.select_label_style')}>
                    <IconButton
                        size='small'
                        disableRipple
                        onClick={handleClick}
                        sx={{
                            backgroundColor: theme.palette.mapcontrol.background,
                            color: theme.palette.mapcontrol.foreground,
                            borderRadius: '5px',
                            borderColor: "#000000",
                            borderWidth: '6px',
                            margin: '5px',
                            '&:hover': {
                                backgroundColor: theme.palette.mapcontrol.backgroundHover,
                            },
                        }}
                    ><DirectionsCarIcon /></IconButton>
                </Tooltip>

                <FormControl
                    id="layer-menu"
                    sx={{
                        visibility: props.open ? "visible" : "hidden",
                        width: "200px",
                        left: "50px",
                        top: "0px",
                        borderRadius: "5px",
                        padding: "10px",
                        position: "absolute",
                        backgroundColor: theme.palette.mapcontrol.background,
                    }}>
                    <FormLabel>{t('map_controls.label_style')}</FormLabel>
                    <RadioGroup
                    >
                        <FormControlLabel value="lbl-registration" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>{t('map_controls.label_registration')}</Typography>} checked={props.selectedLayer === LabelLayerType.REGISTRATION} onChange={e => handleSelectLayer(LabelLayerType.REGISTRATION)} />
                        <FormControlLabel value="lbl-driver-name" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>{t('map_controls.label_driver_name')}</Typography>} checked={props.selectedLayer === LabelLayerType.DRIVER_NAME} onChange={e => handleSelectLayer(LabelLayerType.DRIVER_NAME)} />
                        <FormControlLabel value="lbl-alias" control={<Radio size='small' color='secondary' />} label={<Typography variant='cardsubtitle'>{t('map_controls.label_alias')}</Typography>} checked={props.selectedLayer === LabelLayerType.ALIAS} onChange={e => handleSelectLayer(LabelLayerType.ALIAS)} />
                    </RadioGroup>
                </FormControl>
            </Control>
        </>
    );
}

export default LabelLayerControl;
