import React, {useContext} from 'react';
import {Box, Button, Grid, Paper, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAssetApi} from '../../../hooks/assets/Assets';
import {DateTimePicker} from '@mui/x-date-pickers';
import TextInput from '../../../components/form/TextInput';
import {SelectorLabel} from '../../reports/shared/SelectorLabel';
import ServiceAssetSelector from "../shared/ServiceAssetSelector";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {useServiceRecordApi} from "../../../hooks/servicing/serviceRecord/ServiceRecords";
import {ServiceRecordRequest} from "../../../hooks/servicing/serviceRecord/dto/ServiceRecordRequest";
import dayjs, {Dayjs} from "dayjs";
import ServiceAssetTable from "../shared/ServiceAssetTable";

export default function ServiceRecordsEditorPage() {
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {assets, loading: loadingAssets} = useAssetApi({shouldLoadAll: true});
    const {createServiceRecord, updateServiceRecord} = useServiceRecordApi({shouldLoadAll: true});
    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedServiceRecord !== null && location.state.selectedServiceRecord !== undefined;
    const isReadOnly = isState && location.state.readOnly !== null && location.state.readOnly !== undefined && location.state.readOnly;
    const selectedServiceRecord = isEdit ? location.state.selectedServiceRecord : undefined;

    const {sort} = useContext(FilterSortContext);

    const [selectedAssetRows, setSelectedAssetRows] = React.useState<number[]>(isEdit ? [selectedServiceRecord?.vehicleId] : []);
    const [selectedDate, setSelectedDate] = React.useState<Dayjs>(isEdit ? dayjs(selectedServiceRecord?.completedAt) : dayjs());
    const [notes, setNotes] = React.useState<string>(isEdit ? selectedServiceRecord?.notes : '');
    const [engineHours, setEngineHours] = React.useState<string>(isEdit ? selectedServiceRecord?.engineHours : '');
    const [miles, setMiles] = React.useState<string>(isEdit ? selectedServiceRecord?.miles : '');

    function handleCancel() {
        navigate('/servicing/service-records');
    }

    async function handleCreateServiceRecord() {
        await createServiceRecord(new ServiceRecordRequest({
            vehicleId: selectedAssetRows[0],
            notes: notes ?? null,
            engineHours: engineHours ? parseInt(engineHours) : null,
            miles: miles ? parseInt(miles) : null,
            completedAt: selectedDate.toDate(),
        }))
            .then(() => {
                navigate('/servicing/service-records');
            })
            .catch((error) => {
                alert(t('service.service_records.record_create.failed'))
            });
    }

    async function handleUpdateServiceRecord() {
        await updateServiceRecord(new ServiceRecordRequest({
            vehicleId: selectedAssetRows[0],
            notes: notes ?? null,
            engineHours: engineHours ? parseInt(engineHours) : null,
            miles: miles ? parseInt(miles) : null,
            completedAt: selectedDate.toDate(),
        }), selectedServiceRecord?.id, selectedAssetRows[0])
            .then(() => {
                navigate('/servicing/service-records');
            })
            .catch((error) => {
                alert(t('service.service_records.record_update.failed'))
            });
    }

    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>
                {/* Asset Selector */}
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">
                            {t("service.service_records.editor.asset")}
                        </Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">
                            {isReadOnly ? t("service.service_records.editor.view_asset") : t("service.service_records.editor.select_asset")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        {isReadOnly && (
                            <ServiceAssetTable
                                loadingAssets={loadingAssets}
                                assets={assets?.filter(asset => asset.id === selectedAssetRows[0]) || []}
                            />
                        )}
                        {!isReadOnly && (
                            <ServiceAssetSelector
                                loadingAssets={loadingAssets}
                                assets={assets}
                                selectedAssetRows={selectedAssetRows}
                                setSelectedAssetRows={setSelectedAssetRows}
                                label={t("service.service_records.editor.asset_search_box_label")}
                                sort={sort}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* Completed at DateTimePicker */}
                <Grid container>
                    {isReadOnly && (<SelectorLabel
                        title={t("service.service_records.editor.completed_at")}
                        subtitle={t("service.service_records.editor.view_completed_at")}
                    />)}
                    {!isReadOnly && (<SelectorLabel
                        title={t("service.service_records.editor.completed_at")}
                        subtitle={t("service.service_records.editor.select_completed_at")}
                    />)}
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <DateTimePicker value={selectedDate}
                                        ampm={false}
                                        onChange={(value) => value && setSelectedDate(value)}
                                        disableFuture={true}
                                        disabled={isReadOnly}
                        />
                    </Grid>
                </Grid>
                {/* Notes Text Input */}
                <Grid container>
                    <SelectorLabel
                        title={t("service.service_records.editor.notes")}
                        subtitle={isReadOnly ? t("service.service_records.editor.view_notes") : t("service.service_records.editor.enter_notes")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput
                            label={''}
                            required={false}
                            editMode={!isReadOnly}
                            placeholder={''}
                            value={notes}
                            onChange={setNotes}
                        />
                    </Grid>
                </Grid>
                {/* Engine Hours Number Input */}
                <Grid container>
                    {isReadOnly && (<SelectorLabel
                        title={t("service.service_records.editor.engine_hours")}
                        subtitle={t("service.service_records.editor.view_engine_hours")}
                    />)}
                    {!isReadOnly && (<SelectorLabel
                        title={t("service.service_records.editor.engine_hours")}
                        subtitle={t("service.service_records.editor.enter_engine_hours")}
                    />)}
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput
                            label={''}
                            required={false}
                            editMode={!isReadOnly}
                            placeholder={''}
                            value={(engineHours ?? '').toString()}
                            onChange={setEngineHours}
                            isNumber={true}
                        />
                    </Grid>
                </Grid>
                {/* Miles Number Input */}
                <Grid container>
                    <SelectorLabel
                        title={t("service.service_records.editor.miles")}
                        subtitle={isReadOnly ? t("service.service_records.editor.view_miles") : t("service.service_records.editor.enter_miles")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput
                            label={''}
                            required={false}
                            editMode={!isReadOnly}
                            placeholder={''}
                            value={(miles ?? '').toString()}
                            onChange={setMiles}
                            isNumber={true}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end">
                    <Grid item xs={12} md={12} lg={12} padding="20px">
                        <Box
                            top="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {isReadOnly ? t('button.back') : t('button.cancel')}
                            </Button>

                            {!isReadOnly && (
                                <Button
                                    disabled={selectedAssetRows.length === 0 || !(miles || engineHours) || !selectedDate}
                                    variant="contained"
                                    size='small'
                                    color="primary"
                                    onClick={isEdit ? handleUpdateServiceRecord : handleCreateServiceRecord}
                                >
                                    {isEdit ? t("button.save") : t("button.create")}
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
