import { Asset } from "../hooks/assets/dto/Asset";

export enum AssetSortOptions {
    DEFAULT,
    DRIVER_NAME,
    LAST_REPORTED
}

export function filterAssetsBySearch(assets: Array<Asset>, searchInput: string | undefined) {

    if (searchInput) {
        let lowerCaseFilter = searchInput.toLowerCase();
        assets = assets.filter(asset => {
            if (asset) {
                // Check for asset properties and filter accordingly
                if (asset.alias?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
                if (asset.identificationMark?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
                if (asset.assignee?.name.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
                if (asset.groupName?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
                if (asset.type?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                }
            }
            return false; // Return false for assets that don't match the filter
        });
    }

    return assets;
}
