import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Asset } from "../../../hooks/assets/dto/Asset";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';

interface TimelineAddAssetProps {
    assets: Asset[];
    selectedTimelineAssets: number[];
    onAssetSelected: (asset: Asset) => void;
}

export default function TimelineAddAsset(props: Readonly<TimelineAddAssetProps>) {
    const { t } = useTranslation();
    const [selectedAsset, setSelectedAsset] = useState<string | null>("0");

    function handleChange(event: SelectChangeEvent<string | null>, child: ReactNode) {
        const asset = props.assets.find((asset) => asset.id.toString() === event.target.value);
        if (asset) {
            props.onAssetSelected(asset);
        } else {
            console.error("Asset not found");
        }
        setSelectedAsset("0");
    }

    return (
        <FormControl
            sx={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '100%',
                height: '50px',
            }}>
            <Select

                variant="outlined"
                size="small"
                sx={{
                    width: '100%'
                }}
                IconComponent={AddIcon}
                id="timeline-asset-select"

                value={selectedAsset}
                onChange={handleChange}
                disabled={props.assets.length === props.selectedTimelineAssets.length}
            >
                <MenuItem disabled value={0}>
                    {t('timeline.add_asset_button')}
                </MenuItem>
                {props.assets.map((asset) => props.selectedTimelineAssets.includes(asset.id) ? null : (<MenuItem key={asset.id} value={asset.id.toString()}>{asset.alias ? asset.alias : asset.identificationMark}</MenuItem>))}
            </Select>
        </FormControl>
    );
}