import { Asset } from "../../../../hooks/assets/dto/Asset";
import AssetCard from "./AssetCard";
import AssetsListSectionHeading from "./AssetsListSectionHeading";
import React from "react";
import { Map } from "leaflet";


interface AssetsViewProps {
    mapref: React.RefObject<Map>;
    title: string;
    titleColor: string;
    assets: Array<Asset>;
}

export default function AssetsListSection(props: Readonly<AssetsViewProps>) {
    const [open, setOpen] = React.useState(true);

    return (
        <>
            <AssetsListSectionHeading title={props.title} titleColor={props.titleColor} numberOfAssets={props.assets.length} open={open} onClick={() => { setOpen(!open) }} />
            {open ? props.assets.map((asset) => {
                return (<AssetCard key={asset.id} asset={asset} mapref={props.mapref} />)
            }) : <></>}
        </>
    );
}