import {useContext, useEffect, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

export class TemperatureAlert {
    id: number;
    accountId: number;
    minimumTemperature: number | undefined;
    maximumTemperature: number | undefined;
    selectedSensor: string;
    alertPeriod: AlertPeriod;
    startTime: string;
    endTime: string;
    alertForAllVehicles: boolean;
    recipients: string[];
    vehicles: string[];

    constructor(props: {
        id: number;
        accountId: number;
        minimumTemperature: number | undefined;
        maximumTemperature: number | undefined;
        selectedSensor: string;
        alertPeriod: AlertPeriod;
        startTime: string;
        endTime: string;
        alertForAllVehicles: boolean;
        recipients: string[];
        vehicles: string[];
    }) {
        this.id = props.id;
        this.accountId = props.accountId;
        this.minimumTemperature = props.minimumTemperature;
        this.maximumTemperature = props.maximumTemperature;
        this.selectedSensor = props.selectedSensor;
        this.alertPeriod = props.alertPeriod;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.alertForAllVehicles = props.alertForAllVehicles;
        this.recipients = props.recipients;
        this.vehicles = props.vehicles;
    }
}

export class TemperatureAlertRequest {
    id: number | undefined;
    accountId: number | undefined;
    minimumTemperature: number | undefined;
    maximumTemperature: number | undefined;
    selectedSensor: string;
    alertPeriod: string;
    startTime: string;
    endTime: string;
    alertForAllVehicles: boolean;
    recipientIds: number[];
    vehicleIds: number[];

    constructor(props: {
        id: number | undefined;
        accountId: number | undefined;
        minimumTemperature: number | undefined;
        maximumTemperature: number | undefined;
        selectedSensor: string;
        alertPeriod: string;
        startTime: string;
        endTime: string;
        alertForAllVehicles: boolean;
        recipientIds: number[];
        vehicleIds: number[];
    }) {
        this.id = props.id;
        this.accountId = props.accountId;
        this.minimumTemperature = props.minimumTemperature;
        this.maximumTemperature = props.maximumTemperature;
        this.selectedSensor = props.selectedSensor;
        this.alertPeriod = props.alertPeriod;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.alertForAllVehicles = props.alertForAllVehicles;
        this.recipientIds = props.recipientIds;
        this.vehicleIds = props.vehicleIds;
    }
}

export enum AlertPeriod {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
    WEEKDAYS = "WEEKDAYS",
    WEEKENDS = "WEEKENDS",
    ALL = "ALL"
}

interface TemperatureAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useTemperatureAlertsApi(props: TemperatureAlertsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const [temperatureAlerts, setTemperatureAlerts] = useState<TemperatureAlert[] | null>(null);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllTemperatureAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/alerts/temperature-alerts/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setTemperatureAlerts(alerts)
                    resolve(alerts)
                })
                .catch((error: Error) => {
                    console.error("Error getting temperature alerts: " + error)
                    setError(error)
                    reject(error)
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const createTemperatureAlert = async (temperatureAlertRequest: TemperatureAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("tracking/v1/alerts/temperature-alerts", 'post', auth.token, JSON.stringify(temperatureAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error creating temperature alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateTemperatureAlert = async (temperatureAlertRequest: TemperatureAlertRequest, temperatureAlertId: string) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/temperature-alerts/${temperatureAlertId}`, 'put', auth.token, JSON.stringify(temperatureAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error updating temperature alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteTemperatureAlert = async (temperatureAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/temperature-alerts/${temperatureAlertId}`, 'delete', auth.token)
                .then(checkOk)
                .then(alert => resolve())
                .catch((error: Error) => {
                    console.error("Error deleting temperature alerts: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllTemperatureAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllTemperatureAlerts, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {loading, error, temperatureAlerts: temperatureAlerts, getAllTemperatureAlerts, createTemperatureAlert, updateTemperatureAlert, deleteTemperatureAlert}
}
