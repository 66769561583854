import {Box, Typography} from '@mui/material';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import ExploreIcon from '@mui/icons-material/Explore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Popup, useMap} from 'react-leaflet';

import './EventMarkerPopup.css'
import {Speed} from '@mui/icons-material';
import {IconValueText} from '../../../map/components/IconValueText';
import {Asset, AssetEvent} from '../../../../hooks/assets/dto/Asset';
import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";
import {getTranslatedEventName} from "../../../../utils/AssetEventUtils";
import {getAssetName} from "../../../../utils/AssetUtils";
import {languageToLocal} from "../../../../utils/LocalUtils";

interface EventMarkerPopupProps {
    event: AssetEvent;
    asset: Asset;
}

function EventMarkerPopup(props: Readonly<EventMarkerPopupProps>) {
    const {twentyFourHourEnabled, languageCode} = useContext(UserPreferencesContext)

    const map = useMap();
    const {t} = useTranslation();

    function getSpeedContent(event: AssetEvent) {
        if (event.speedLimit) {
            if (event.speed.value > event.speedLimit.value)
                return (<span style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <WarningAmberIcon color={"secondary"} fontSize={'inherit'}/>
                    {props.event.speed.value} {props.event.speed.units} (
                    {props.event.speedLimit.value} {props.event.speedLimit.units}){' '}
                </span>)
            else {
                return `${props.event.speed.value} ${props.event.speed.units} (${props.event.speedLimit.value} ${props.event.speedLimit.units})`;
            }
        } else {
            return `${props.event.speed.value} ${props.event.speed.units}`;
        }
    }

    return (
        <Popup className='asset-popup' closeButton={false}
               eventHandlers={{
                   popupopen: () => {
                       map.setView([props.event.location.rawCoordinates.latitude, props.event.location.rawCoordinates.longitude], map.getZoom(), {animate: true});
                   }

               }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
            }}>

                <Typography
                    sx={{display: "block", width: "100%", paddingBottom: "0px"}}
                    component="div"
                    variant="cardtitle"
                    color="text.primary"
                >
                    {getAssetName(props.asset)}
                </Typography>

                <Typography
                    sx={{display: "block", width: "100%"}}
                    component="div"
                    variant="cardbody"
                    color="text.secondary"
                >
                    {getTranslatedEventName(props.event, t)}
                </Typography>
                <hr style={{
                    borderTop: "2px solid orange",
                    width: "calc(100% + 45px)",
                    margin: "0",
                    marginTop: "6px",
                    marginBottom: "6px",
                    marginLeft: "-20px"
                }}/>

                <IconValueText
                    icon={<Speed color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.speed')}
                    value={getSpeedContent(props.event)}
                />

                <IconValueText
                    icon={<ExploreIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.heading')}
                    value={props.event.headingIndicator.toLowerCase() ? t(`asset_directions.${props.event.headingIndicator.toLowerCase()}`) : "-"}
                />

                <IconValueText
                    icon={<LocationOnIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.address')}
                    value={props.event.location.formattedAddress}
                />

                <IconValueText
                    icon={<AccessTimeIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.event_time')}
                    value={props.event.time.toLocaleString(languageToLocal(languageCode), {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: !twentyFourHourEnabled
                    })}
                />
            </Box>
        </Popup>
    );
}

export default EventMarkerPopup;
