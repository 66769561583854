export interface TestData {
    time: string;
    measurement1: number;
    measurement2: number;
    measurement3: number;
    measurement4: number;
    measurement5: number;
}

export const testData: TestData[] = [
    { time: '2025-01-01 00:00:00', measurement1: 10, measurement2: 20, measurement3: 30, measurement4: 40, measurement5: 50 },
    { time: '2025-01-02 00:01:00', measurement1: 20, measurement2: 30, measurement3: 40, measurement4: 50, measurement5: 60 },
    { time: '2025-01-03 00:12:00', measurement1: 30, measurement2: 40, measurement3: 50, measurement4: 60, measurement5: 70 },
    { time: '2025-01-04 00:13:00', measurement1: 40, measurement2: 50, measurement3: 60, measurement4: 70, measurement5: 80 },
    { time: '2025-01-05 00:23:00', measurement1: 50, measurement2: 60, measurement3: 70, measurement4: 80, measurement5: 90 },
];

export interface MeasurementInfo {
    units: string;
    color: string;
}

export const measurementInfo: Record<string, MeasurementInfo> = {
    measurement1: { units: 'unit1', color: '#8884d8' },
    measurement2: { units: 'unit2', color: '#82ca9d' },
    measurement3: { units: 'unit3', color: '#ffc658' },
    measurement4: { units: 'unit4', color: '#ff7300' },
    measurement5: { units: 'unit5', color: '#387908' },
};