import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { TestData, measurementInfo } from "./testData";
import { Card, CardContent } from '@mui/material';

interface OverTimeGraph {
    data: TestData[];
    useCard?: boolean;
}

const OverTimeGraph: React.FC<OverTimeGraph> = ({ data, useCard = true }) => {
    const graphContent = (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <XAxis dataKey="time" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                {Object.keys(data[0]).filter((key) => key !== 'time').map((key) => (
                    <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        name={`${key} (${measurementInfo[key].units})`}
                        stroke={measurementInfo[key].color}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );

    return useCard ? (
        <Card style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4 8px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
            {graphContent}
        </CardContent>
        </Card>
    ) : (
        graphContent
    );
};

export default OverTimeGraph;