import {AttributionControl, MapContainer, useMapEvents} from "react-leaflet";
import MapTileLayer from "./MapTileLayer";
import MapContextMenu from "../../pages/map/components/MapContextMenu";
import ZoomMapControl from "../../pages/map/components/ZoomMapControl";
import MapButtonControl from "../../pages/map/components/MapButtonControl";
import CropFreeIcon from "@mui/icons-material/CropFree";
import LabelIcon from "@mui/icons-material/Label";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapLayerControl from "../../pages/map/components/MapLayerControl";
import LabelLayerControl from "../../pages/map/components/LabelLayerControl";
import AssetMarkerLayer from "../../pages/map/components/AssetMarkerLayer";
import CongestionZoneLayer from "./CongestionZoneLayer";
import GeofenceLayer from "./GeofenceLayer";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import L, {Map} from "leaflet";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {LayerControlType} from "../../pages/map/MapPage";
import { Asset } from "../../hooks/assets/dto/Asset";
import {useCongestionZonesApi} from "../../hooks/zones/CongestionZones";
import {useGeofencesApi} from "../../hooks/geofences/Geofences";
import {useTranslation} from "react-i18next";
import { useDebounce } from 'use-debounce';
import {Geofence} from "../../hooks/geofences/dto/Geofence";

export enum LabelLayerType {
    REGISTRATION = "REGISTRATION",
    ALIAS = "ALIAS",
    DRIVER_NAME = "DRIVER_NAME",
}

export const DEFAULT_LABEL_LAYER = LabelLayerType.ALIAS;

interface MapControls {
    keepInView?: boolean;
    labels?: boolean;
    clustering?: boolean;
    congestionZones?: boolean;
    geofences?: boolean;
}

interface BaseMapProps {
    mapRef: React.RefObject<Map>;
    controls?: MapControls;
    assets?: Asset[];
    focusGeofence?: Geofence;
}

const mapContainerStyle = {
    width: "100%",
    height: "calc(100vh - 48px)",
    margin: "0 auto",
};

export default function BaseMap(props: Readonly<BaseMapProps>) {
    const {t} = useTranslation();
    const {
        clusterEnabled,
        setClusterEnabled,
        labelsEnabled,
        setLabelsEnabled,
        mapType,
        setMapType,
        labelType,
        setLabelType
    } = useContext(UserPreferencesContext);

    const observerRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        // Create the observer instance
        observerRef.current = new ResizeObserver(() => {
            props.mapRef.current?.invalidateSize();
        });

        // Cleanup function
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    const [congestionZonesEnabled, setCongestionZonesEnabled] = useState(false);
    const [geofenceEnabled, setGeofenceEnabled] = useState(false);
    const [keepAssetsInBounds, setKeepAssetsInBounds] = useState<boolean>(true);
    const [mapLayerControlOpen, setMapLayerControlOpen] = useState(false);
    const [labelTypeControlOpen, setLabelTypeControlOpen] = useState(false);

    const [debouncedAssets] = useDebounce(props.assets, 1000);
    const {zones} = useCongestionZonesApi(false);
    const {geofences} = useGeofencesApi(false);

    const zoomToAllAssets = useCallback(() => {
        if (props.assets === undefined || (props.assets && props.assets.length === 0)) {
            return;
        }

        if(props.assets.length === 1) {
            const asset = props.assets[0];
            if (asset.latestEvent !== undefined) {
                const assetLocation = asset.latestEvent.location.rawCoordinates;
                const assetLatLng = new L.LatLng(assetLocation.latitude, assetLocation.longitude);
                props.mapRef.current?.setView(assetLatLng, 15);
            }
        } else {
            const filteredAssets = props.assets.filter(asset => asset.latestEvent !== undefined);
            if (filteredAssets.length === 0) {
                return;
            }

            const assetBounds = filteredAssets
                .map(asset => asset.latestEvent!.location.rawCoordinates)
                .map(coordinate => [coordinate.latitude, coordinate.longitude]);

            const rawBounds = new L.LatLngBounds(assetBounds as L.LatLngBoundsLiteral);
            const paddedBounds = addPaddingToBounds(rawBounds);
            props.mapRef.current?.fitBounds(paddedBounds);
        }
    }, [props.assets, props.mapRef]);

    useEffect(() => {
        if(keepAssetsInBounds && debouncedAssets && debouncedAssets.length > 0) {
            zoomToAllAssets();
        }
    }, [debouncedAssets, keepAssetsInBounds, zoomToAllAssets]);

    useEffect(() => {
        if(props.focusGeofence) {
            setGeofenceEnabled(true);
            setKeepAssetsInBounds(false);

            if(props.focusGeofence.feature.geometry.type === "Point") {
                const geofenceLocation = new L.LatLng(props.focusGeofence.feature.geometry.coordinates[1], props.focusGeofence.feature.geometry.coordinates[0]);
                props.mapRef.current?.setView(geofenceLocation, 15);
            } else if(props.focusGeofence.feature.geometry.type === "Polygon") {
                const geofenceBounds = props.focusGeofence.feature.geometry.coordinates[0].map((coord: number[]) => [coord[1], coord[0]]);
                const rawBounds = new L.LatLngBounds(geofenceBounds as L.LatLngBoundsLiteral);
                props.mapRef.current?.fitBounds(rawBounds, {padding: [50, 50]});
            }
        }
    }, [props.focusGeofence, props.mapRef]);



    function MapEventHandler() {
        useMapEvents({
            dragstart: () => setKeepAssetsInBounds(false),
            click: (event) => {
                console.log(event.latlng);
                setLabelTypeControlOpen(false);
                setMapLayerControlOpen(false);
            }
        })

        return <></>
    }

    const handleOpenableControlClick = (controlType: LayerControlType) => {
        if (controlType === LayerControlType.MAP) {
            setMapLayerControlOpen(!mapLayerControlOpen);
            setLabelTypeControlOpen(false);
        } else if (controlType === LayerControlType.LABEL) {
            setLabelTypeControlOpen(!labelTypeControlOpen);
            setMapLayerControlOpen(false);
        }
    };

    function toggleKeepInBounds() {
        const newKeepAssetsInBounds = !keepAssetsInBounds;
        setKeepAssetsInBounds(newKeepAssetsInBounds);
        if(newKeepAssetsInBounds) {
            zoomToAllAssets();
        }
    }

    function addPaddingToBounds(bounds: L.LatLngBounds) {
        const padding = 0.130;
        return new L.LatLngBounds(
            [bounds.getSouth() - padding, bounds.getWest() - padding],
            [bounds.getNorth() + padding, bounds.getEast() + padding]
        );
    }



    return (<div
        style={mapContainerStyle}
    >

        <MapContainer
            ref={props.mapRef}
            attributionControl={false}
            style={mapContainerStyle}
            center={[35.0,-40.0]}
            zoom={2}
            scrollWheelZoom={true}
            zoomControl={false}
            id="leaflet-container"
            whenReady={() => {
                const container = document.getElementById("leaflet-container");
                if (container) {
                    observerRef.current?.observe(container);
                }
            }}
        >
            <AttributionControl position="bottomright" prefix=""/>

            {/* Event handler for things like pan and zoom. This is what disconnects the keep assets in view if a user pans / zooms */}
            <MapEventHandler/>

            {/* Map Controls */}
            <ZoomMapControl onZoomInClick={() => {
                props.mapRef.current?.zoomIn()
            }} onZoomOutClick={() => {
                props.mapRef.current?.zoomOut()
            }}/>

            {/* Map Tile Layer - Selected by the MapLayerControl */}
            <MapTileLayer mapLayer={mapType}/>

            {/* Context Menu - Currently only used for street view */}
            <MapContextMenu/>

            {props.controls?.keepInView && (
                <MapButtonControl placement="topleft"
                                  tooltip={t('map_controls.show_all_assets')}
                                  toggled={keepAssetsInBounds}
                                  tooltipPlacement="right"
                                  icon={<CropFreeIcon/>}
                                  onClick={() => toggleKeepInBounds()}
                />
            )}

            {props.controls?.labels && (<MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_labels')} toggled={labelsEnabled}
                              tooltipPlacement="right"
                              icon={<LabelIcon/>} onClick={() => setLabelsEnabled(!labelsEnabled)}/>)}

            {props.controls?.clustering && (<MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_clustering')} toggled={clusterEnabled}
                              tooltipPlacement="right" icon={<GroupWorkIcon/>}
                              onClick={() => setClusterEnabled(!clusterEnabled)}/>)}

            {props.controls?.congestionZones && (<MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_congestion_zones')}
                              toggled={congestionZonesEnabled}
                              tooltipPlacement="right" icon={<HexagonOutlinedIcon/>}
                              onClick={() => setCongestionZonesEnabled(!congestionZonesEnabled)}/>)}

            {props.controls?.geofences && (<MapButtonControl placement="topleft" tooltip={t('map_controls.toggle_geofences')} toggled={geofenceEnabled}
                              tooltipPlacement="right" icon={<LocationOnOutlinedIcon/>}
                              onClick={() => setGeofenceEnabled(!geofenceEnabled)}/>)}

            <MapLayerControl placement="topleft" selectedLayer={mapType} setSelectedLayer={setMapType}
                             open={mapLayerControlOpen}
                             handleClickOperation={handleOpenableControlClick}/>

            {props.controls?.labels && (<LabelLayerControl placement="topleft" selectedLayer={labelType} setSelectedLayer={setLabelType}
                               open={labelTypeControlOpen}
                               handleClickOperation={handleOpenableControlClick}/>)}


            {/* Asset Markers */}
            {props.assets && props.assets.length > 0 &&
                (<AssetMarkerLayer
                    assets={props.assets}
                    clusteringEnabled={clusterEnabled}
                    labelsEnabled={labelsEnabled}
                    selectedAsset={undefined}
                    setSelectedAsset={() => {
                    }}
                    labelLayer={labelType}
                />)
            }

            {/* Congestion Zones */}
            {zones && zones.length > 0 &&
                (<CongestionZoneLayer zones={zones || []}
                                      congestionZonesEnabled={congestionZonesEnabled}/>)}
            {/* Geofences */}
            {geofences && geofences.length > 0 &&
                (<GeofenceLayer geofences={geofences} labelsEnabled={labelsEnabled}
                                geofenceEnabled={geofenceEnabled}/>)}

        </MapContainer>
    </div>);

}


