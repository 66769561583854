import {useContext, useEffect, useState} from "react";
import {AssetGroup} from "./dto/AssetGroup";
import {AssetGroupRequest} from "./dto/AssetGroupRequest";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

interface AssetGroupApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useAssetGroupApi(props: AssetGroupApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk} = usePlatformApiFetch();

    const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllAssetGroups = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/labels", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroups => {
                    setAssetGroups(assetGroups)
                    setLoading(false)
                    resolve(assetGroups)
                })
                .catch((error:Error) => {
                    console.error("Error getting asset group: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createAssetGroup = async (assetGroupRequest: AssetGroupRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/labels`, 'post', auth.token, JSON.stringify(assetGroupRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroup => resolve(assetGroup))
                .catch((error:Error) => {
                    console.error("Error creating asset group: " + error)
                    reject(error)
                });
        });
    }

    const updateAssetGroup = async (assetGroupUpdateRequest: AssetGroupRequest, assetGroupId: string) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/labels/${assetGroupId}`, 'put', auth.token, JSON.stringify(assetGroupUpdateRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(assetGroup => resolve(assetGroup))
                .catch((error:Error) => {
                    console.error("Error updating asset group: " + error)
                    reject(error)
                });
        });
    }

    const deleteAssetGroup = async (assetGroupId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`tracking/v1/labels/${assetGroupId}`, 'delete', auth.token)
                .then(checkOk)
                .then(() => resolve())
                .catch((error:Error) => {
                    console.error("Error deleting asset group: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllAssetGroups();
            if (props.shouldPoll) {
                const interval = setInterval(getAllAssetGroups, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {
        loading, error, assetGroups: assetGroups, getAllAssetGroups: getAllAssetGroups,
        createAssetGroup, updateAssetGroup: updateAssetGroup, deleteAssetGroup: deleteAssetGroup
    }
}
