import { Box, Typography } from '@mui/material';

interface AssetCardIconTextProps {
  icon: JSX.Element;
  value: string;
  prefixValue?: string;
}

export function AssetCardIconText(props: Readonly<AssetCardIconTextProps>) {
  return (
    <Box display="flex" flexDirection="row" >
      <Box sx={{ marginRight: "10px" }} >
        <Typography
          sx={{ display: "block", width: "100%" }}
          component="span"
          variant="cardbody"
          color="text.primary"
        >
          {props.icon}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ display: "block", width: "100%" }}
          component="span"
          variant="cardbody"
          color="text.primary"
        >
            {props.prefixValue && <b>{props.prefixValue}</b>}
          <span>{props.value}</span>
        </Typography>
      </Box>
    </Box>

  );
}