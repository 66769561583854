import * as React from "react";
import { Grid, useTheme } from "@mui/material";
import OverTimeGraph from "../../components/graph/OverTimeGraph";
import { testData } from "../../components/graph/testData";

const ComponentTestPage: React.FC = () => {
    const theme = useTheme();

    return (
        <div
            id="component-test-page"
            style={{
                width: '100%',
                height: '100%',
                minHeight: 'calc(100vh - 48px)',
                backgroundColor: theme.palette.contentPage.background,
                padding: '10px'
            }}>
            <Grid container padding="10px" spacing={2.5}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <OverTimeGraph data={testData} useCard={true}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ComponentTestPage;