import { SsoUser } from "./dto/SsoUser";
import { SsoUserUpdate } from "./dto/SsoUserUpdate";
import { UserPreferences } from "./dto/UserPreferences";
import { MapLayer } from "../../components/map/MapTileLayer";
import {DEFAULT_LABEL_LAYER, LabelLayerType} from "../../components/map/BaseMap";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext} from "react";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

export default function useUserApi() {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk} = usePlatformApiFetch();

    const getUserProfile = async (): Promise<SsoUser> => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/users/me', "get", auth.token)
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => data as SsoUser)
                .then((ssoUser) => resolve(ssoUser))
                .catch((error: Error) => reject(error));
        });
    }

    const updateUserProfile = async (updateValues: SsoUserUpdate) => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/users/me', "put", auth.token, JSON.stringify(updateValues))
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => data as SsoUser)
                .then((ssoUser) => resolve(ssoUser))
                .catch((error: Error) => reject(error));
        });
    }

    const getUserPreferences = async () => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/users/me/preferences', "get", auth.token)
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => new UserPreferences({
                    colorPalette: data.colorPalette,
                    largeText: data.largeText,
                    openDrawer: data.openDrawer,
                    languageCode: data.languageCode,
                    clusterEnabled: data.clusterEnabled,
                    labelsEnabled: data.labelsEnabled,
                    mapType: getMapTypeFromResponseData(data.mapType),
                    labelType: getLabelTypeFromResponseData(data.labelType),
                    twentyFourHourEnabled: data.twentyFourHourEnabled
                }))
                .then((preferences) => resolve(preferences))
                .catch((error) => resolve(undefined));

        });
    }

    const setUserPreferences = async (userPreferences: UserPreferences) => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/users/me/preferences', "post", auth.token, JSON.stringify(userPreferences))
                .then(checkOk)
                .then((response) => response.json())
                .then((data) => new UserPreferences({
                    colorPalette: data.colorPalette,
                    largeText: data.largeText,
                    openDrawer: data.openDrawer,
                    languageCode: data.languageCode,
                    clusterEnabled: data.clusterEnabled,
                    labelsEnabled: data.labelsEnabled,
                    mapType: getMapTypeFromResponseData(data.mapType),
                    labelType: getLabelTypeFromResponseData(data.labelType),
                    twentyFourHourEnabled: data.twentyFourHourEnabled
                }))
                .then((preferences) => resolve(preferences))
                .catch((error: Error) => reject(error));
        });
    }

    const getActivationKey = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            platformApiFetch('account/v1/users/me/activation-key', "get", auth.token)
                .then(checkOk)
                .then((response) => response.text())
                .then((activationKey) => resolve(activationKey))
                .catch((error: Error) => reject(error));
        });
    }

    return { getUserProfile, updateUserProfile, getUserPreferences, setUserPreferences, getActivationKey };
}

function getMapTypeFromResponseData(mapType: any): MapLayer {
    switch (mapType) {
        case 'GOOGLE_ROAD_MAP':
            return MapLayer.GOOGLE_ROAD_MAP;
        case 'GOOGLE_SAT_MAP':
            return MapLayer.GOOGLE_SAT_MAP;
        case 'GOOGLE_HYBRID_MAP':
            return MapLayer.GOOGLE_HYBRID_MAP;
        case 'GOOGLE_TRAFFIC_MAP':
            return MapLayer.GOOGLE_TRAFFIC_MAP;
        case 'OSM_DEFAULT':
            return MapLayer.OSM_DEFAULT;
        default:
            return MapLayer.GOOGLE_ROAD_MAP;
    }
}

function getLabelTypeFromResponseData(labelType: any): LabelLayerType {
    switch (labelType) {
        case 'REGISTRATION':
            return LabelLayerType.REGISTRATION;
        case 'ALIAS':
            return LabelLayerType.ALIAS;
        case 'DRIVER_NAME':
            return LabelLayerType.DRIVER_NAME;
        default:
            return DEFAULT_LABEL_LAYER;
    }
}