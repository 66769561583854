import {Box, Grid, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {useTranslation} from "react-i18next";
import {SelectorLabel} from "./SelectorLabel";
import {useUserLocationApi} from "../../../hooks/userLocations/UserLocations";
import {UserLocation} from "../../../hooks/userLocations/dto/UserLocation";
import SelectUserLocationTable from "../../../components/table/shared/SelectUserLocationTable";

interface UserLocationSelectorProps {
    singleReport: boolean;
    selectedUserLocations: number[];
    setSelectedUserLocations: (selectedUserLocations: number[]) => void;
}

export function UserLocationTableSelector(props: Readonly<UserLocationSelectorProps>) {

    const {t} = useTranslation();
    const {userLocations, loading} = useUserLocationApi({shouldLoadAll: true});
    const [userLocationFilter, setUserLocationFilter] = React.useState<string | undefined>(undefined);

    function onSearchChange(search: string) {
        if (search === undefined || search === "") {
            setUserLocationFilter(undefined);
        } else {
            setUserLocationFilter(search);
        }
    }

    const filterUserLocations = (userLocations: Array<UserLocation>) => {
        let filteredUserLocations = userLocations

        if (userLocationFilter) {
            let lowerCaseFilter = userLocationFilter.toLowerCase();
            filteredUserLocations = userLocations.filter(userLocation => {
                if (userLocation) {
                    if (userLocation?.name?.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false;
            });
        }

        return filteredUserLocations;
    }

    return <Grid container>
        <SelectorLabel
            title={t("report.user_location")}
            subtitle={t("report.select_user_location")}
        />
        <Grid item xs={12} md={8} lg={10} padding="20px">
            <Box sx={{
                paddingBottom: '10px'
            }}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={t("report.user_location_search_box_label")}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    value={userLocationFilter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onSearchChange(event.target.value);
                    }}
                />
            </Box>
            <Grid>
                <SelectUserLocationTable multiselect={!props.singleReport} loading={loading}
                                         userLocations={filterUserLocations(userLocations || [])}
                                         selectedRows={props.selectedUserLocations}
                                         setSelectedRows={props.setSelectedUserLocations}/>
            </Grid>

        </Grid>
    </Grid>
}
