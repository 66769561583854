export class Journey {
    id: string;
    distance?: Distance;
    startAddress: string;
    endAddress?: string;
    startTime: Date;
    endTime?: Date;
    inProgress: boolean;

    constructor(props: {
        id: string;
        distance?: Distance;
        startAddress: string;
        endAddress?: string;
        startTime: Date;
        endTime?: Date;
        inProgress: boolean;
    }) {
        this.id = props.id;
        this.distance = props.distance;
        this.startAddress = props.startAddress;
        this.endAddress = props.endAddress;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.inProgress = props.inProgress
    }
}

export class Distance {
    value: number;
    units: DistanceUnits;

    constructor(props: { value: number; units: DistanceUnits }) {
        this.value = props.value;
        this.units = props.units;
    }
}

export enum DistanceUnits {
    KM = "Km",
    MILES = "Miles"
}