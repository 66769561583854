import SecondaryNavigationPanel from "../../components/secondary_navigation_panel/SecondaryNavigationPanel";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import propertiesLoader from "../../properties/PropertiesLoader";

const properties = propertiesLoader();


export enum ReportPage {
    VEHICLES,
    SCHEDULED_VEHICLES,
    GENERATORS
}

interface ReportsSecondaryNavProps {
    selectedReportPage: ReportPage;
    setSelectedReportPage: (selectedReportPage: ReportPage) => void;
}

export function ReportsSecondaryNav(props: Readonly<ReportsSecondaryNavProps>) {
    const {t} = useTranslation();
    const {pathname} = useLocation()

    useEffect(() => {
        if (props.selectedReportPage !== ReportPage.VEHICLES && (pathname === '/reports/vehicle-reports' || pathname === '/reports')) {
            props.setSelectedReportPage(ReportPage.VEHICLES);
        } else if (props.selectedReportPage !== ReportPage.SCHEDULED_VEHICLES && pathname === '/reports/scheduled-reports') {
            props.setSelectedReportPage(ReportPage.SCHEDULED_VEHICLES);
        } else if (props.selectedReportPage !== ReportPage.GENERATORS && (pathname === '/reports/generator-reports')) {
            props.setSelectedReportPage(ReportPage.GENERATORS);
        }
    }, [pathname, props.selectedReportPage, props]);

    const secondaryNavItems = [
        {
            text: t('report.vehicle_reports'),
            selected: props.selectedReportPage === ReportPage.VEHICLES || (pathname === '/reports/vehicle-reports' || pathname === '/reports'),
            to: 'vehicle-reports',
            onClick: () => props.setSelectedReportPage(ReportPage.VEHICLES)
        },
        {
            text: t('report.scheduled_vehicle_reports'),
            selected: props.selectedReportPage === ReportPage.SCHEDULED_VEHICLES || pathname === '/reports/scheduled-reports',
            to: 'scheduled-reports',
            onClick: () => props.setSelectedReportPage(ReportPage.SCHEDULED_VEHICLES)
        }
    ]

    if (properties.featureFlags.generatorReport) {
        secondaryNavItems.push({
            text: t('report.generator_reports'),
            selected: props.selectedReportPage === ReportPage.GENERATORS || (pathname === '/reports/generator-reports'),
            to: 'generator-reports',
            onClick: () => props.setSelectedReportPage(ReportPage.GENERATORS)
        })
    }

    return <SecondaryNavigationPanel title={t('drawer.reports')} items={secondaryNavItems}/>
}
