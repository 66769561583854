import {Box, Button, Divider, Grid, Paper, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import useAccountApi, {AccountContact} from "../../../hooks/account/Account";
import TextInput from "../../../components/form/TextInput";
import {EngineHoursAlertRequest, useEngineHoursAlertsApi} from "../../../hooks/alerts/EngineHourAlerts";
import EngineHourAlertTypeSelection from "./components/EngineHourAlertTypeSelection";
import {EngineHourAlertType} from "./components/EngineHourAlertTypes";
import FullView from "../../../layouts/FullView";
import AlertAssetSelector from "./components/AlertAssetSelector";
import AlertRecipientSelector from "./components/AlertRecipientSelector";


export default function EngineHoursAlertsEditorPage() {
    return (
        <FullView content={<EngineHoursAlertsEditorPageContent/>}/>
    );
}
function EngineHoursAlertsEditorPageContent() {
    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();

    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedAlert !== null && location.state.selectedAlert !== undefined;
    const selectedAlert = isEdit ? location.state.selectedAlert : undefined;

    const [triggerValue, setTriggerValue] = useState<number>(isEdit ? selectedAlert?.triggerValue : '')
    const [selectedType, setSelectedType] = useState<EngineHourAlertType>(isEdit ? selectedAlert?.type : '')
    const {sort} = useContext(FilterSortContext);

    const {assets, loading: loadingAssets} = useAssetApi({shouldLoadAll:true});
    const {accountContacts} = useAccountApi();
    const {createEngineHoursAlert, updateEngineHoursAlert} = useEngineHoursAlertsApi()

    const mapRegistrationsToIds = (registrations: string[], assets: any[]): number[] => {
        return registrations.map(registration => {
            const asset = assets.find(asset => asset.identificationMark === registration);
            return asset ? asset.id : null;
        }).filter(id => id !== null);
    };

    const [selectedAssetRows, setSelectedAssetRows] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (isEdit && assets) {
            const mappedIds = mapRegistrationsToIds(selectedAlert?.vehicles, assets);
            setSelectedAssetRows(mappedIds);
        }
    }, [assets]);

    const [selectedRecipientsRows, setSelectedRecipientsRows] = React.useState<string[]>(isEdit ? selectedAlert?.recipients : []);
    const handleTriggerValueChange = (value: string) => {
        if (value === '') {
            setTriggerValue(0);
        } else {
            const numericValue = parseFloat(value);
            setTriggerValue(numericValue);
        }
    };

    const getRecipientIds = (selectedRecipientsRows: string[], accountContacts: AccountContact[]): number[] => {
        return selectedRecipientsRows.map(email => {
            const contact = accountContacts.find(contact => contact.email === email);
            return contact ? contact.id : null;
        }).filter(id => id !== null) as number[];
    };

    const handleCreateEngineHourAlert = async () => {
        if (triggerValue <= 0) {
            alert("Threshold value must be greater then 0");
            return;
        }
        const recipientIds = getRecipientIds(selectedRecipientsRows, accountContacts ?? []);

        await createEngineHoursAlert(new EngineHoursAlertRequest({
            type: selectedType,
            triggerValue: triggerValue,
            recipients: recipientIds,
            vehicles: selectedAssetRows,
        }))
            .then(() => {
                navigate('/alerts/engine-hour-alerts')
            })
            .catch((error) => {
                alert(t('alert_creation.failed'))
            })
    }

    const handleUpdateEngineHourAlert = async () => {
        if (triggerValue <= 0) {
            alert("Threshold value must be greater then 0");
            return;
        }
        const recipientIds = getRecipientIds(selectedRecipientsRows, accountContacts ?? []);

        await updateEngineHoursAlert(new EngineHoursAlertRequest({
            type: selectedType,
            triggerValue: triggerValue,
            recipients: recipientIds,
            vehicles: selectedAssetRows,
        }), selectedAlert?.id)
            .then(() => {
                navigate('/alerts/engine-hour-alerts')
            })
            .catch((error) => {
                alert(t('alert_update.failed'))
            })
    }

    const handleCancel = () => {
        navigate('/alerts/engine-hour-alerts')
    }

    const [loadingRecipients] = useState<boolean>(false);
    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Threshold */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "20px"}}
                                    variant="title">{t("engine_hour_alert.admin.threshold.title")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("engine_hour_alert.admin.threshold.description")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true}
                                   label={''} required={true} placeholder={''}
                                   value={triggerValue.toString()} onChange={handleTriggerValueChange}/>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Type Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("engine_hour_alert.admin.type.title")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("engine_hour_alert.admin.type.description")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px" >
                        <EngineHourAlertTypeSelection selectedType={selectedType} setSelectedType={setSelectedType} />
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>


                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("event.asset_selection")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.asset_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <AlertAssetSelector
                            isEdit={isEdit}
                            multiselect={true}
                            loadingAssets={loadingAssets}
                            assets={assets}
                            selectedAssetRows={selectedAssetRows}
                            setSelectedAssetRows={setSelectedAssetRows}
                            label={t("service.service_records.editor.asset_search_box_label")}
                            sort={sort}
                            alert={selectedAlert}
                        />
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Recipients Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("event.recipients")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("event.select_recipients")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <AlertRecipientSelector
                            isEdit={isEdit}
                            multiselect={true}
                            loadingRecipients={loadingRecipients}
                            recipients={accountContacts}
                            selectedRecipientsRows={selectedRecipientsRows}
                            setSelectedRecipientsRows={setSelectedRecipientsRows}
                            label={t("service.service_records.editor.asset_search_box_label")}
                            alert={selectedAlert}
                        />

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedAssetRows.length === 0 || selectedRecipientsRows.length === 0 || triggerValue <= 0}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? handleUpdateEngineHourAlert : handleCreateEngineHourAlert}
                            >
                                {isEdit ? t("button.save") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
