import { Asset } from "../../../../hooks/assets/dto/Asset";
import AssetCard from "./AssetCard";
import React from "react";
import { Map } from "leaflet";


interface AssetsViewProps {
    mapref: React.RefObject<Map>;
    assets: Array<Asset>;
}

export default function AssetsList(props: Readonly<AssetsViewProps>) {
    return (
        <>
            {props.assets.map((asset) => {
                return (<AssetCard key={asset.id} asset={asset} mapref={props.mapref} />)
            })}
        </>
    );
}