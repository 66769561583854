import { Box } from "@mui/material";
import {ReactNode} from "react";

import "./MapView.css";

//Map Components
import {AttributionControl, MapContainer} from "react-leaflet";
import { Map } from "leaflet";

import { resizeMap } from "../utils/MapUtils";

interface MapViewProps {
    leftPanel: ReactNode;
    mapLayers: ReactNode;
    mapref: React.RefObject<Map>;
    controls?: ReactNode; // Optional controls prop
    timeline?: ReactNode; // Optional timeline prop
}

export default function MapView(this: any, props: MapViewProps) {

    const mapContainer = (
        <div
            className={"w-full h-full"}
        >
            <MapContainer
                ref={props.mapref}
                attributionControl={false}
                style={{
                    width: "100%",
                    height: "calc(100vh - 148px)",
                    margin: "0 auto",
                }}
                center={[53.801277, -1.548567]}
                zoom={7}
                scrollWheelZoom={true}
                zoomControl={false}
                id="leaflet-container"
                whenReady={() => { resizeMap(props.mapref) }}
            >
                {props.mapLayers}
                <AttributionControl position="bottomright" prefix="" />
                {props.controls}
            </MapContainer>
            {props.timeline}
        </div>
    );

    return (
        <>
            <Box sx={{ minWidth: "350px", padding: "0" }}>
                {props.leftPanel}
            </Box>
            {mapContainer}
        </>
    );
}