import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    description: string;
    confirmText: string;
    cancelText?: string | null;
    onConfirm: () => void;
    onCancel: () => void;
}

export default function ConfirmationDialog(props: Readonly<ConfirmationDialogProps>) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiBackdrop-root': {
                 backgroundColor: 'rgba(0, 0, 0, 0.2)'
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               {props.cancelText && <Button onClick={props.onCancel}>{props.cancelText}</Button>}
                <Button onClick={props.onConfirm} autoFocus>
                    {props.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}