import {useContext, useEffect, useState} from "react";
import {Geofence} from "../geofences/dto/Geofence";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";


export class GeofenceAlert {
    id: number;
    alertPeriod: AlertPeriod;
    accountId: number;
    startTime: string;
    endTime: string;
    alertOnEntry: boolean;
    alertOnExit: boolean;
    geofenceDto: Geofence;
    recipients: string[];
    alertForAllVehicles: boolean;
    vehicles: string[];


    constructor(props: {
        id: number;
        alertPeriod: AlertPeriod;
        accountId: number;
        startTime: string;
        endTime: string;
        alertOnEntry: boolean;
        alertOnExit: boolean;
        geofenceDto: Geofence;
        recipients: string[];
        alertForAllVehicles: boolean;
        vehicles: string[];
    }) {
        this.id = props.id;
        this.alertPeriod = props.alertPeriod;
        this.accountId = props.accountId;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.alertOnEntry = props.alertOnEntry;
        this.alertOnExit = props.alertOnExit;
        this.geofenceDto = props.geofenceDto;
        this.recipients = props.recipients;
        this.alertForAllVehicles = props.alertForAllVehicles;
        this.vehicles = props.vehicles;
    }
}

export class GeofenceAlertRequest {
    id: number;
    geofenceId: number;
    alertPeriod: AlertPeriod;
    startTime: string;
    endTime: string;
    alertOnEntry: boolean;
    alertOnExit: boolean;
    isAlertForAllVehicles: boolean;
    recipientIds: number[];
    vehicleIds: number[];


    constructor(props: {
        id: number;
        geofenceId: number;
        alertPeriod: AlertPeriod;
        startTime: string;
        endTime: string;
        alertOnEntry: boolean;
        alertOnExit: boolean;
        isAlertForAllVehicles: boolean;
        recipientIds: number[];
        vehicleIds: number[];
    }) {
        this.id = props.id;
        this.geofenceId = props.geofenceId;
        this.alertPeriod = props.alertPeriod;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.alertOnEntry = props.alertOnEntry;
        this.alertOnExit = props.alertOnExit;
        this.isAlertForAllVehicles = props.isAlertForAllVehicles;
        this.recipientIds = props.recipientIds;
        this.vehicleIds = props.vehicleIds;
    }
}

export enum AlertPeriod {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
    WEEKDAYS = "WEEKDAYS",
    WEEKENDS = "WEEKENDS",
    ALL = "ALL"
}

interface GeofenceAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useGeofenceAlertsApi(props: GeofenceAlertsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const [geofenceAlerts, setGeofenceAlerts] = useState<GeofenceAlert[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllGeofenceAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/alerts/geofence-alerts/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setGeofenceAlerts(alerts)
                    setLoading(false)
                    resolve(alerts)
                })
                .catch((error) => {
                    console.error("Error getting geofence alerts: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createGeofenceAlert = async (geofenceAlertRequest: GeofenceAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("tracking/v1/alerts/geofence-alerts", 'post', auth.token, JSON.stringify(geofenceAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating geofence alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateGeofenceAlert = async (geofenceAlertRequest: GeofenceAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/geofence-alerts/${geofenceAlertRequest.id}`, 'put', auth.token, JSON.stringify(geofenceAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error updating geofence alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteGeofenceAlert = async (geofenceAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/geofence-alerts/${geofenceAlertId}`, 'delete', auth.token)
                .then(checkOk)
                .then(alert => resolve())
                .catch((error) => {
                    console.error("Error deleting geofence alerts: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllGeofenceAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllGeofenceAlerts, props.pollFrequency || 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {loading, error, geofenceAlerts, getAllGeofenceAlerts, createGeofenceAlert, updateGeofenceAlert, deleteGeofenceAlert}
}
