import {useContext, useEffect, useState} from "react";
import usePlatformApiFetch from "../shared/PlatformApiFetch";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

export class DistanceAlert {
    id: number;
    assetId: number;
    ind: number;
    metric: number;
    imperial: number;
    alertInterval: number;
    notes: string;
    email: string;
    odometerThreshold: number;

    constructor(props: {
        id: number;
        assetId: number;
        ind: number;
        metric: number;
        imperial: number;
        alertInterval: number;
        notes: string;
        email: string;
        odometerThreshold: number;
    }) {
        this.id = props.id;
        this.assetId = props.assetId;
        this.ind = props.ind;
        this.metric = props.metric;
        this.imperial = props.imperial;
        this.alertInterval = props.alertInterval;
        this.notes = props.notes;
        this.email = props.email;
        this.odometerThreshold = props.odometerThreshold;
    }
}

export class DistanceAlertRequest {
    assetId: number;
    alertInterval: number;
    notes: string;
    email: string;

    constructor(props: {
        assetId: number;
        alertInterval: number;
        notes: string;
        email: string;
    }) {
        this.assetId = props.assetId;
        this.alertInterval = props.alertInterval;
        this.notes = props.notes;
        this.email = props.email;
    }
}

interface DistanceAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useDistanceAlertsApi(props: DistanceAlertsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const [distanceAlerts, setDistanceAlerts] = useState<DistanceAlert[] | null>(null);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllDistanceAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/alerts/distance-alerts/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setDistanceAlerts(alerts)
                    setLoading(false)
                    resolve(alerts)
                })
                .catch((error: Error) => {
                    console.error("Error getting distance alerts: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createDistanceAlert = async (distanceAlertRequest: DistanceAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("tracking/v1/alerts/distance-alerts/", 'post',auth.token, JSON.stringify(distanceAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error creating distance alert: " + error)
                    reject(error)
                });
        });
    }

    const updateDistanceAlert = async (distanceAlertRequest: DistanceAlertRequest, distanceAlertId: number) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/distance-alerts/${distanceAlertId}`, 'put', auth.token, JSON.stringify(distanceAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error updating distance alert: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllDistanceAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllDistanceAlerts, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {loading, error, distanceAlerts, getAllDistanceAlerts, createDistanceAlert, updateDistanceAlert}
}
