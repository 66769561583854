import { useTranslation } from "react-i18next";
import SelectStringTable from "../../../components/table/shared/SelectStringTable";

interface AlertDayTableProps<T> {
    period: T;
    setPeriod: (selectedPeriod: T) => void;
    periods: T[];
    getPeriodText: (period: T) => string;
}

export default function AlertDayTable<T>({ period, setPeriod, periods, getPeriodText }: AlertDayTableProps<T>) {
    const { t } = useTranslation();

    const handleRowSelect = (selectedPeriod: T) => {
        setPeriod(selectedPeriod);
    };

    return (
        <SelectStringTable
            loading={false}
            header={t("event.day")}
            values={periods.map(getPeriodText)}
            selectedRows={[getPeriodText(period)]}
            setSelectedRows={(selectedRows: string[]) => handleRowSelect(periods.find(p => getPeriodText(p) === selectedRows[0])!)}
        />
    );
}