import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, useTheme} from "@mui/material";
import React, {useState} from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from "@mui/material/IconButton";

interface UrlDialogProps {
    open: boolean;
    title: string;
    description: string;
    copyText: string;
    cancelText: string;
    link: string
    onCopy: (link: string) => void;
    onCancel: () => void;
}

export default function UrlDialog(props: Readonly<UrlDialogProps>) {
    const theme = useTheme()

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        props.onCopy(props.link);

        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                    <TextField
                        disabled
                        fullWidth
                        size={'small'}
                        label={isCopied ? 'Copied!' : 'Live Link'}
                        value={props.link}
                        style={{
                            height: '100%', borderRadius: '4px 0 0 4px', paddingRight: '0'
                        }}
                    />
                    <IconButton
                        disableRipple
                        onClick={handleCopy}
                        aria-label="copy"
                        style={{
                            height: '100%',
                            backgroundColor: theme.palette.mapcontrol.backgroundHover,
                            borderRadius: '0 4px 4px 0',
                            marginLeft: '-5px'
                        }}
                    >
                        <FileCopyIcon/>
                    </IconButton>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onCancel}>{props.cancelText}</Button>
                <Button onClick={handleCopy} autoFocus>{props.copyText}</Button>
            </DialogActions>
        </Dialog>
    );
}
