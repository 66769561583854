import {Paper} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import React from "react";

interface SearchInputProps {
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function SearchInput(props: Readonly<SearchInputProps>) {

    return (
        <div onClick={props.onClick}>
            <Paper
                component="form"
                elevation={0}
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 400,
                    height: '34px',
                    minHeight: '34px',
                    marginLeft: '5px',
                    marginRight: '5px'
                }}
            >
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder={props.placeholder}
                    inputProps={{'aria-label': 'search google maps'}}
                    value={props.value}
                    onChange={props.onChange}
                />
            </Paper>
        </div>
    )
}