import {useContext, useEffect, useState} from "react";
import {ScheduledReport} from "./dto/ScheduledReport";
import {ScheduledReportRequest} from "./dto/ScheduledReportRequest";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

interface ScheduledReportsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useScheduledReportsApi(props: ScheduledReportsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getScheduledReports = async () => {
        return new Promise((resolve, reject) => {
            platformApiFetch("scheduled-reports/v1/scheduled-reports", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(scheduledReport => {
                    setScheduledReports(scheduledReport)
                    setLoading(false)
                    resolve(scheduledReport)
                })
                .catch((error: Error) => {
                    console.error("Failed to get scheduled reports : " + error)
                    reject(error)
                });
        })
    }

    const deleteScheduledReport = async (scheduledReportId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch("scheduled-reports/v1/scheduled-reports/" + scheduledReportId, 'delete', auth.token)
                .then(checkOk)
                .then(() => {
                    resolve()
                })
                .catch((error: Error) => {
                    console.error("Error deleting scheduled report : " + error)
                    setError(error)
                    reject(error)
                });
        });
    }

    const createScheduledReport = async (scheduledReportRequest: ScheduledReportRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("scheduled-reports/v1/scheduled-reports", 'post', auth.token, JSON.stringify(scheduledReportRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error creating geofence alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateScheduledReport = async (scheduledReportRequest: ScheduledReportRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`scheduled-reports/v1/scheduled-reports/${scheduledReportRequest.id}`, 'put', auth.token, JSON.stringify(scheduledReportRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error: Error) => {
                    console.error("Error creating geofence alerts: " + error)
                    reject(error)
                });
        });
    }
    useEffect(() => {
        if (props.shouldLoadAll) {
            getScheduledReports();
            if (props.shouldPoll) {
                const interval = setInterval(getScheduledReports, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {loading, error, scheduledReports, getScheduledReports, deleteScheduledReport, createScheduledReport, updateScheduledReport}
}
