import {useContext} from "react";
import {AssetStatus, AssetType} from "../../hooks/assets/dto/Asset";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {colors, getStatusColors} from "../../themes/AppTheme";


export function SvgMarkerIcon(strokeColor: string, assetType: AssetType, assetStatus: AssetStatus, heading?: number, isWarningEvent?: boolean) {
    const {colorPalette} = useContext(UserPreferencesContext);
    const statusColors = getStatusColors(colorPalette);

    let fillColor = "gray";
    let rotation = 0;

    let centerIcon = null;


    if (assetType === AssetType.TRAILER) {
        fillColor = "#CACACA";
        centerIcon = (<g transform="translate(35,35) scale(0.2)">
            <path
                d="M268.494,113.482c-8.284,0-15,6.716-15,15v32.153H223V27.042c0-8.284-6.716-15-15-15H15c-8.284,0-15,6.716-15,15v182.11 c0,8.284,6.716,15,15,15h17.118c0.378,26.144,21.751,47.299,47.982,47.299s47.604-21.155,47.982-47.299H208c8.284,0,15-6.716,15-15 v-18.518h45.494c8.284,0,15-6.716,15-15v-47.153C283.494,120.198,276.778,113.482,268.494,113.482z M80.101,241.452 c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S90.025,241.452,80.101,241.452z M193,194.153h-74.918 c-8.786-11.363-22.539-18.701-37.981-18.701s-29.195,7.338-37.981,18.701H30V42.042h163V194.153z"
                fill="white"></path>
        </g>)
    } else if (isWarningEvent) {
        fillColor = colors.ram_orange;
        centerIcon = (
            <svg width="100" height="100">
                <polygon points="60 30 40 80 80 80" fill="white" strokeLinecap="round" strokeLinejoin="round"
                         strokeWidth="10px"/>
                <text x="60%" y="65%" textAnchor="middle" dominantBaseline="middle" fontSize="40" fill="red">!</text>
            </svg>);
    } else {
        switch (assetStatus) {
            case AssetStatus.MOVING:
                rotation = heading ?? 0;
                fillColor = statusColors.moving;
                centerIcon = (
                    <polygon points="60 30 40 80 60 70 80 80" fill="white" strokeLinecap="round" strokeLinejoin="round"
                             strokeWidth="10px"/>);
                break;
            case AssetStatus.CHARGING:
                fillColor = "#DDB52C";
                centerIcon = (
                    <polygon points="65 30 45 65 60 65 55 90 75 55 60 55 65 30" fill="white" strokeLinecap="round"
                             strokeLinejoin="round"
                             strokeWidth="10px"/>);
                break;
            case AssetStatus.STOPPED:
                fillColor = statusColors.stopped;
                break;
            case AssetStatus.STATIONARY:
                fillColor = statusColors.stationary;
                break;
        }
    }

    return (
        <svg width="40" height="40">
            <g transform={"rotate(" + rotation + " 20 20) scale(0.33)"}>
                <circle cx="60" cy="60" r="50" stroke={strokeColor} strokeWidth="4" fill="white"/>
                <circle cx="60" cy="60" r="40" stroke="none" fill={fillColor}/>
                {centerIcon}
            </g>
        </svg>)
}

