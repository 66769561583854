import {Box, FormControl, Grid, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import React, {useContext, useEffect} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {FilterSortContext} from "../../providers/FilterSortProvider";
import {AssetSortOptions} from "../../utils/AssetArraySorting";
import {useTranslation} from "react-i18next";
import {Asset} from "../../hooks/assets/dto/Asset";
import {useDebounce} from "use-debounce";

interface AssetsFilterBarProps {
    onSearchChange: (search: string) => void;
    assets: Asset[];
}

export default function AssetsFilterBar(props: Readonly<AssetsFilterBarProps>) {
    const {t} = useTranslation();
    const {
        sort, setSort,
        groupFilter, setGroupFilter,
        typeFilter, setTypeFilter
    } = useContext(FilterSortContext);

    const [searchText, setSearchText] = React.useState<string>('');

    const [debouncedSearchText] = useDebounce(searchText, 500);
    useEffect(() => {
        props.onSearchChange(debouncedSearchText);
    }, [debouncedSearchText]);

    function handleSortChange(event: SelectChangeEvent<any>) {
        setSort(event.target.value as AssetSortOptions);
    }

    function handleGroupChange(event: SelectChangeEvent<any>) {
        setGroupFilter(event.target.value as string);
    }

    function handleTypeChange(event: SelectChangeEvent<any>) {
        setTypeFilter(event.target.value as string);
    }

    function removeDuplicates(array: any[]) {
        return array.filter((item,
                             index) => array.indexOf(item) === index);
    }

    function sortEntities(a: any, b: any) {
        // Handle null, undefined, and empty string values
        if (a == null || a === undefined || a === '') {
            return -1; // 'a' comes before 'b'
        } else if (b == null || b === undefined || b === '') {
            return 1; // 'b' comes before 'a'
        } else {
            return a.localeCompare(b);
        }
    }

    return (
        <>
            <Box sx={{
                padding: '10px',
                paddingTop: '20px',
                paddingBottom: '5px'
            }}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label={t('asset_filter_bar.search_placeholder')}
                    variant="outlined"
                    color="info"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchText(event.target.value);
                    }}
                />
            </Box>
            <Box
                sx={{
                    padding: '10px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                }}>
                <Grid container>
                    <Grid item xs={12} md={4} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                    }}>
                        <FormControl>
                            <Select
                                id="asset-sort-select"
                                size="small"
                                value={sort ?? AssetSortOptions.DEFAULT}
                                color="info"
                                style={{height: 25, fontSize: '12px', marginRight: "10px"}}
                                onChange={handleSortChange}>
                                <MenuItem key={"sort-default"} style={{height: 25, fontSize: '12px'}}
                                          value={AssetSortOptions.DEFAULT}>{t('asset_filter_bar.sort_options.default')}</MenuItem>
                                <MenuItem key={"sort-drivername"} style={{height: 25, fontSize: '12px'}}
                                          value={AssetSortOptions.DRIVER_NAME}>{t('asset_filter_bar.sort_options.assignee')}</MenuItem>
                                <MenuItem key={"sort-lastupdated"} style={{height: 25, fontSize: '12px'}}
                                          value={AssetSortOptions.LAST_REPORTED}>{t('asset_filter_bar.sort_options.last_reported')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                    }}>
                        <FormControl>
                            <Select
                                id="asset-group-filter-select"
                                size="small"
                                value={groupFilter}
                                color="info"
                                style={{height: 25, fontSize: '12px', marginRight: "10px"}}
                                onChange={handleGroupChange}
                            >
                                <MenuItem key="filter-group-all" style={{height: 25, fontSize: '12px'}}
                                          value={"all"}>{t('asset_filter_bar.filter_options.group.all')}</MenuItem>
                                {removeDuplicates(props.assets.map((asset) => asset.groupName))
                                    .sort((a, b) => sortEntities(a, b))
                                    .map((group) => {
                                        if (group === '' || group === undefined || group === null) {
                                            return (<MenuItem key={"filter-group-ungrouped"} style={{height: 25, fontSize: '12px'}}
                                                              value={group}>{t('asset_filter_bar.filter_options.group.none')}</MenuItem>)
                                        }
                                        return (<MenuItem key={"filter-group-" + group} style={{height: 25, fontSize: '12px'}}
                                                          value={group}>{t('asset_filter_bar.filter_options.group.group')}{group}</MenuItem>)
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                    }}>
                        <FormControl>
                            <Select
                                id="asset-type-filter-select"
                                size="small"
                                value={typeFilter}
                                color="info"
                                style={{height: 25, fontSize: '12px', marginRight: "12px"}}
                                onChange={handleTypeChange}
                            >
                                <MenuItem key="filter-type-all" style={{height: 25, fontSize: '12px'}}
                                          value={"all"}>{t('asset_filter_bar.filter_options.type.all')}</MenuItem>
                                {removeDuplicates(props.assets.map((asset) => asset.type))
                                    .sort((a, b) => sortEntities(a, b))
                                    .map((type) => {
                                        if (type === '' || type === undefined || type === null) {
                                            return (<MenuItem key={"filter-type-ungrouped"} style={{height: 25, fontSize: '12px'}}
                                                              value={type}>{t('asset_filter_bar.filter_options.type.none')}</MenuItem>)
                                        }
                                        return (<MenuItem key={"filter-type-" + type} style={{height: 25, fontSize: '12px'}}
                                                          value={type}>{t('asset_filter_bar.filter_options.type.type')}{t('asset_filter_bar.filter_options.type.' + type.toLowerCase())}</MenuItem>)
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
